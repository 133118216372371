import {
  Button,
  ButtonVariant,
  Dialog,
  Menu,
  Link,
  MenuItem,
  Text,
  Tooltip,
} from "@merge-api/merge-javascript-shared";
import { ChevronLeft, Code, MoreHorizontal } from "lucide-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SelectiveSyncFilterSchema } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { ComponentState } from "../../../../../components/versioned-components/types";
import {
  navigateToIntegrationBuilderSelectiveSyncFilterBuilder,
  navigateToPublishModule,
} from "../../../../../router/RouterUtils";
import IntegrationBuilderHeader from "../../../shared/IntegrationBuilderHeader";
import useSelectiveSyncFilterBuilderContext from "../../context/useSelectiveSyncFilterBuilderContext";
import { isPublishedComponent, isStagedComponent } from "../../utils";
import APIRequestTesterWithSelectiveSyncFilters from "./api-tester/APIRequestTesterWithSelectiveSyncFilters";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";

type Props = {
  componentState: ComponentState | undefined;
  onDelete: () => void;
  onRestore: () => void;
  selectiveSyncFilterSchema?: SelectiveSyncFilterSchema | undefined;
};

const SelectiveSyncFilterSchemaFormHeader = ({
  componentState,
  onDelete,
  onRestore,
  selectiveSyncFilterSchema,
}: Props) => {
  const {
    isRightPanelOpen,
    setIsRightPanelOpen,
    setRightPanelChildren,
  } = useIntegrationBuilderContext();
  const { integrationID } = useSelectiveSyncFilterBuilderContext();
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const markedForDeletion = componentState == ComponentState.MARKED_FOR_DELETION;

  const DELETE_DRAFT_COMPONENT_BODY = (
    <Text variant="md">
      This action will delete this Selective Sync filter and cannot be undone.{" "}
    </Text>
  );
  const DELETE_PUBLISHED_COMPONENT_BODY = (
    <Text variant="md">
      This will delete this Selective Sync filter and any existing draft. This action will not be
      applied until the next integration version is published. <br /> You can undo this action by
      unstaging the component in{" "}
      <Link onClick={() => navigateToPublishModule(history, integrationID, true)}>Publishing</Link>.
    </Text>
  );

  const RESTORE_PUBLISHED_COMPONENT_BODY = (
    <Text variant="md">
      This will unstage this Selective Sync's deletion for the next integration version.
    </Text>
  );

  // The button props will be different depending on if we are editing an existing selective sync filter
  // or if we're creating a new one
  const headerButtonsProps = !!selectiveSyncFilterSchema?.id
    ? {
        button: (
          <Menu
            ButtonProps={{
              children: <MoreHorizontal size={16} />,
              variant: ButtonVariant.IconShadowHidden,
            }}
          >
            {isStagedComponent(
              componentState ?? ComponentState.NEW_COMPONENT_WITH_STAGED_VERSION
            ) ? (
              <Tooltip
                title="Unstage filter to delete it"
                className="flex items-center justify-center"
              >
                <Text className="text-red-30 py-1 px-2 bg-gray-10">Delete Filter</Text>
              </Tooltip>
            ) : markedForDeletion ? (
              <MenuItem onClick={() => setIsDeletionModalOpen(true)}>
                <Text className="text-red-60">Restore Filter</Text>
              </MenuItem>
            ) : (
              <MenuItem onClick={() => setIsDeletionModalOpen(true)}>
                <Text className="text-red-60">Delete Filter</Text>
              </MenuItem>
            )}
          </Menu>
        ),
        secondaryButton: !markedForDeletion && (
          <Button
            variant={ButtonVariant.SecondaryCharcoal}
            size="md"
            leftIcon={<Code size={16} />}
            onClick={() => setIsRightPanelOpen(true)}
            disabled={
              !selectiveSyncFilterSchema?.user_facing_filter_details?.filter_type_id
                ?.common_model_class_id
            }
          >
            API tester
          </Button>
        ),
      }
    : {
        button: (
          <Button
            variant={ButtonVariant.SecondaryCharcoal}
            size="md"
            leftIcon={<Code size={16} />}
            onClick={() => setIsRightPanelOpen(true)}
            disabled={
              !selectiveSyncFilterSchema?.user_facing_filter_details?.filter_type_id
                ?.common_model_class_id
            }
          >
            API tester
          </Button>
        ),
      };

  // Controls API request tester opening in right panel
  useEffect(() => {
    if (isRightPanelOpen) {
      setRightPanelChildren(
        <APIRequestTesterWithSelectiveSyncFilters
          selectiveSyncFilterSchema={selectiveSyncFilterSchema}
          integrationID={integrationID}
        />
      );
    } else {
      setRightPanelChildren(undefined);
    }
  }, [isRightPanelOpen]);

  // Controls API request tester closing if exit page
  useEffect(() => {
    return () => {
      setIsRightPanelOpen(false);
      setRightPanelChildren(undefined);
    };
  }, []);

  return (
    <div className="flex flex-row justify-between w-full">
      <Dialog
        title={`${markedForDeletion ? "Restore" : "Delete"} Selective Sync filter`}
        open={isDeletionModalOpen}
        onClose={() => setIsDeletionModalOpen(false)}
        primaryButtonVariant={ButtonVariant.DangerFilled}
        primaryButtonText={markedForDeletion ? "Restore filter" : "Delete filter"}
        onSecondaryButtonClick={() => setIsDeletionModalOpen(false)}
        onPrimaryButtonClick={() => {
          if (markedForDeletion) onRestore();
          else onDelete();
          setIsDeletionModalOpen(false);
        }}
        children={
          markedForDeletion
            ? RESTORE_PUBLISHED_COMPONENT_BODY
            : isPublishedComponent(componentState as ComponentState)
            ? DELETE_PUBLISHED_COMPONENT_BODY
            : DELETE_DRAFT_COMPONENT_BODY
        }
      />
      <div className="w-full">
        <Link
          onClick={() =>
            navigateToIntegrationBuilderSelectiveSyncFilterBuilder(history, integrationID)
          }
        >
          {" "}
          <ChevronLeft size={12} /> <Text variant="title-sm">Selective Sync filters</Text>
        </Link>
        <IntegrationBuilderHeader
          title="Edit Filter"
          componentState={componentState}
          {...headerButtonsProps}
        />
      </div>
    </div>
  );
};

export default SelectiveSyncFilterSchemaFormHeader;

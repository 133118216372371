import {
  Button,
  ButtonVariant,
  Card,
  Checkbox,
  Dialog,
  Text,
} from "@merge-api/merge-javascript-shared";
import { ValidatedStagedComponent } from "../types";
import { useCallback, useState } from "react";
import useUnstageBlueprintVersions from "../hooks/useUnstageBlueprintVersions";
import { usePublishModuleContext } from "../context/PublishModuleContext";

interface Props {
  blueprints: ValidatedStagedComponent[];
}

const UnstageBlueprintsDialog = ({ blueprints }: Props) => {
  const [open, setOpen] = useState(false);
  const { unstageBlueprintVersions } = useUnstageBlueprintVersions();
  const { refetchPublishModuleInfo, setIsLoadingStagedComponents } = usePublishModuleContext();

  const [selectedBlueprintVersionIDs, setSelectedBlueprintVersionIDs] = useState<Set<string>>(
    new Set()
  );

  const onClose = () => {
    setOpen(false);
    setSelectedBlueprintVersionIDs(new Set());
  };

  const handleUnstage = useCallback(() => {
    unstageBlueprintVersions(
      Array.from(selectedBlueprintVersionIDs),
      refetchPublishModuleInfo,
      setIsLoadingStagedComponents
    );
    onClose();
  }, [selectedBlueprintVersionIDs, unstageBlueprintVersions, refetchPublishModuleInfo, onClose]);

  const handleOnChange = (checked: boolean, blueprint: ValidatedStagedComponent) => {
    const updatedSet = new Set(selectedBlueprintVersionIDs);
    if (checked) {
      updatedSet.add(blueprint.component_version_id);
    } else {
      updatedSet.delete(blueprint.component_version_id);
    }
    setSelectedBlueprintVersionIDs(updatedSet);
  };

  return (
    <div>
      <Button size="sm" variant={ButtonVariant.TextBlack} onClick={() => setOpen(true)}>
        Unstage Blueprints
      </Button>

      <Dialog
        open={open}
        title="Unstage Blueprints"
        onClose={onClose}
        onPrimaryButtonClick={handleUnstage}
        primaryButtonText="Unstage"
        primaryButtonDisabled={selectedBlueprintVersionIDs.size === 0}
        primaryButtonVariant={ButtonVariant.DangerFilled}
        secondaryButtonText="Cancel"
        variant="md"
      >
        <Card variant="outline" className="max-h-[280px] overflow-auto p-3 space-y-3">
          {blueprints.map((blueprint) => (
            <div
              className="flex flex-row items-center space-x-3 cursor-pointer"
              onClick={() =>
                handleOnChange(
                  !selectedBlueprintVersionIDs.has(blueprint.component_version_id),
                  blueprint
                )
              }
            >
              <Checkbox
                className="flex-shrink-0"
                checked={selectedBlueprintVersionIDs.has(blueprint.component_version_id)}
                onChange={(checked) => handleOnChange(checked, blueprint)}
              />
              <Text>{blueprint.name}</Text>
            </div>
          ))}
        </Card>
      </Dialog>
    </div>
  );
};

export default UnstageBlueprintsDialog;

import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import { useState } from "react";
import { Blueprint } from "../../../models/Blueprints";
import { deleteBlueprintVersion } from "../utils/BlueprintEditorAPIClient";
import { showSuccessToast } from "../../shared/Toasts";
import { showErrorMessages } from "../utils/BlueprintEditorUtils";
import { navigateToBlueprintEditor, navigateToBlueprintsSubtab } from "../../../router/RouterUtils";
import { useHistory } from "react-router-dom";

interface BlueprintVersionDeletionDialogProps {
  blueprint: Blueprint;
  isOpen: boolean;
  onClose: () => void;
  nextBlueprintVersionID: string | null | undefined;
}

const BlueprintVersionDeletionDialog = ({
  blueprint,
  isOpen,
  onClose,
  nextBlueprintVersionID,
}: BlueprintVersionDeletionDialogProps) => {
  // state
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  // hooks
  const history = useHistory();

  // functions
  const handleDelete = () => {
    setIsLoadingDelete(true);

    deleteBlueprintVersion({
      versionID: blueprint.version.id,
      onSuccess: () => {
        onClose();
        setIsLoadingDelete(false);
        if (nextBlueprintVersionID) {
          navigateToBlueprintEditor(history, blueprint.integration.id, nextBlueprintVersionID);
        } else {
          navigateToBlueprintsSubtab(history, blueprint.integration.id);
        }
        showSuccessToast("Blueprint version successfully deleted");
      },
      onError: (err: Response | undefined) => {
        showErrorMessages(err, "Failed to delete blueprint version");
        setIsLoadingDelete(false);
        onClose();
      },
    });
  };

  return (
    <Dialog
      title="Are you sure?"
      primaryButtonVariant={ButtonVariant.DangerFilled}
      primaryButtonText="Delete"
      onPrimaryButtonClick={handleDelete}
      primaryButtonLoading={isLoadingDelete}
      open={isOpen}
      onClose={onClose}
      variant="sm"
    >
      Are you sure you want to delete this draft? This cannot be undone.
    </Dialog>
  );
};
export default BlueprintVersionDeletionDialog;

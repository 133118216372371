import { useState, useEffect } from "react";
import { useParams, Route, Switch, Redirect, match } from "react-router-dom";
import { fetchWithAuth } from "../../api-client/api_client";
import { Integration } from "../../models/Entities";
import { Col, Row, Accordion } from "react-bootstrap";
import ScrapersTable from "./scrapers/ScrapersTable";
import IntegrationPageWrapper from "./IntegrationPageWrapper";
import EmptyStateWrapper from "../shared/layout/EmptyStateWrapper";
import APIEndpointsOverview from "./api-endpoints/APIEndpointsTableOverview";
import { PUBLISH_REQUESTS_TAB_PATH } from "../../router/RouterUtils";
import UIDTemplateManagementOverview from "./uid/UIDTemplateManagementOverview";
import IntegrationPublishRequestTab from "./publish-requests/IntegrationPublishRequestTab";
import UIDTemplateEditView from "./uid/UIDTemplateEditView";
import MappingTestsTab from "./mapping-tests/MappingTestsTab";
import AuthConfigTab from "./auth-config/AuthConfigTab";
import ReportTemplatesTab from "./report-templates/ReportTemplatesTab";
import useDocumentTitle from "../shared/useDocumentTitle";
import IntegrationChangelogTab from "./integration-changelog/components/IntegrationChangelogTab";
import BlueprintsSearch from "./blueprints-search/BlueprintsSearch";

type Props = {
  match: match;
};

const IntegrationOverview = ({ match }: Props) => {
  const params = useParams<{ integrationID: string; subtab: string }>();
  const [integration, setIntegration] = useState<undefined | Integration>();
  const { integrationID } = params;

  useDocumentTitle(integration?.name, [integration]);

  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}`,
      method: "GET",
      onResponse: (data: Integration) => setIntegration(data),
    });
  }, [integrationID]);

  return (
    <Accordion className="py-0 px-3 pt-16">
      <Row>
        {integration ? (
          <>
            <Col
              style={{
                flex: 1,
                maxWidth: "100%",
                padding: "0 32px",
              }}
            >
              <IntegrationPageWrapper integration={integration}>
                <Switch>
                  <Route
                    path={PUBLISH_REQUESTS_TAB_PATH}
                    children={() => (
                      <IntegrationPublishRequestTab integrationID={integrationID} match={match} />
                    )}
                  ></Route>
                  <Route
                    path={`/integrations/:integrationID/blueprints/:operationType`}
                    render={() => <BlueprintsSearch integration={integration} />}
                  />
                  <Route
                    path={`/integrations/:integrationID/api-endpoints/:methodType`}
                    render={() => <APIEndpointsOverview integrationMeta={integration} />}
                  />
                  <Route
                    path={`/integrations/:integrationID/auth-configs/`}
                    render={() => <AuthConfigTab integrationMeta={integration} />}
                  />
                  <Route
                    path={`/integrations/:integrationID/report-templates/`}
                    render={() => <ReportTemplatesTab integrationMeta={integration} />}
                  />
                  <Route
                    exact
                    path={`/integrations/:integrationID/scrapers`}
                    component={() => <ScrapersTable integrationMeta={integration} />}
                  />
                  <Route
                    path={`/integrations/:integrationID/test-suites/`}
                    render={() => <MappingTestsTab integrationMeta={integration} />}
                  />
                  <Route
                    path={"/integrations/:integrationID/changelog"}
                    render={() => <IntegrationChangelogTab integrationMeta={integration} />}
                  />
                  <Route
                    path={`/integrations/:integrationID/uid/template/:templateID`}
                    render={() => <UIDTemplateEditView />}
                  />
                  <Route
                    path={`/integrations/:integrationID/uid/:category`}
                    render={() => <UIDTemplateManagementOverview integrationMeta={integration} />}
                  />
                  <Redirect to={`/integrations/:integrationID/blueprints/fetch`} />
                </Switch>
              </IntegrationPageWrapper>
            </Col>
          </>
        ) : (
          <Col>
            <EmptyStateWrapper isTable isSpinner />
          </Col>
        )}
      </Row>
    </Accordion>
  );
};

export default IntegrationOverview;

import { useState, useEffect } from "react";
import HeaderBar from "../../portal/HeaderBar";
import { Col, Row } from "react-bootstrap";
import { fetchWithAuth } from "../../../api-client/api_client";
import { useParams, useHistory } from "react-router-dom";
import { Integration } from "../../../models/Entities";
import InputFormField from "../../blueprint-editor/right-panel/InputFormField";
import DropdownFormField from "../../blueprint-editor/right-panel/DropdownFormField";
import { AuthConfigMeta } from "../../../models/AuthConfig";
import { AuthType } from "../../../models/Entities";
import AuthConfigBasicAuthTokenExchangeForm from "./AuthConfigBasicAuthTokenExchangeForm";
import AuthConfigBasicAuthForm from "./AuthConfigBasicAuthForm";
import styled from "styled-components";
import AuthConfigOAuthForm from "./AuthConfigOAuthForm";
import { Spinner, Text, Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { ArrowLeft } from "lucide-react";
import { navigateToAuthConfigsTable } from "../../../router/RouterUtils";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  width: 60%;
  justify-content: center;
`;

type Props = {};

type RouteParams = {
  authConfigID?: string;
  integrationID: string;
};

function AuthConfigEditForm(_: Props) {
  const [integrationName, setIntegrationName] = useState<undefined | string>();
  const [integrationCategories, setIntegrationCategories] = useState<Array<string> | undefined>();
  const { integrationID, authConfigID } = useParams<RouteParams>();
  const [authName, setAuthName] = useState<undefined | string>();
  const [authType, setAuthType] = useState<AuthType>();
  const [authConfig, setAuthConfig] = useState<AuthConfigMeta>();
  const isEditingExistingConfig = !!authConfigID;
  const [hasPermission, setHasPermission] = useState(true);
  const [isLoadingPageData, setIsLoadingPageData] = useState(true);

  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}`,
      method: "GET",
      onResponse: (data: Integration) => {
        setIntegrationName(data.name);
        setIntegrationCategories(data.categories);
        setIsLoadingPageData(false);
      },
      onError: (error?: Response) => {
        if (error?.status === 403) {
          setHasPermission(false);
          setIsLoadingPageData(false);
        }
      },
    });
    if (isEditingExistingConfig) {
      fetchWithAuth({
        path: `/integrations/${integrationID}/auth-configs/${authConfigID}`,
        method: "GET",
        onResponse: (data: AuthConfigMeta) => {
          setAuthConfig(data);
          setAuthName(data.name);
          setAuthType(data.auth_type);
          setIsLoadingPageData(false);
        },
        onError: (error?: Response) => {
          if (error?.status === 403) {
            setHasPermission(false);
            setIsLoadingPageData(false);
          }
        },
      });
    }
  }, []);

  // FETCH different forms for each type
  const history = useHistory();

  return isLoadingPageData ? (
    <div className="flex items-center justify-center">
      <Spinner />
    </div>
  ) : hasPermission ? (
    <div className="ml-6 pt-6">
      <Row>
        <Col>
          <Button
            size="md"
            variant={ButtonVariant.TextBlue}
            leftIcon={<ArrowLeft size={16} />}
            onClick={() => {
              navigateToAuthConfigsTable(history, integrationID);
            }}
          >
            Back to auth configs
          </Button>
          <HeaderBar
            pretitle={"Blueprint Dashboard"}
            title={
              (isEditingExistingConfig ? "Edit " + authType : "Add new ") +
              " Authentication Configuration for " +
              integrationName
            }
          />
        </Col>
      </Row>

      <InputContainer>
        {!isEditingExistingConfig && (
          <DropdownFormField
            currentValue={authType}
            onChange={(e) => {
              setAuthType(e.target.value);
            }}
            placeholder=""
            title="Authentication Type*"
            subtitle={"Select what type of authentication configuration you'd like to create."}
            choices={Object.values(AuthType).map((subtype: AuthType) => {
              return { name: subtype, id: subtype };
            })}
          />
        )}
        <Row>
          <Col>
            <InputFormField
              currentValue={authName}
              onChange={(name) => setAuthName(name)}
              placeholder=""
              title={"Name"}
              subtitle={"A name for the Authentication Configuration."}
            />
          </Col>
        </Row>

        {authType == AuthType.BASIC_AUTH && (
          <AuthConfigBasicAuthForm
            authConfig={authConfig}
            integrationID={integrationID}
            integrationCategory={
              integrationCategories?.length == 1 ? integrationCategories[0] : undefined
            }
            authName={authName}
            isEditingExistingConfig={isEditingExistingConfig}
          />
        )}

        {authType == AuthType.BASIC_AUTH_WITH_TOKEN_EXCHANGE && (
          <AuthConfigBasicAuthTokenExchangeForm
            authConfig={authConfig}
            integrationID={integrationID}
            integrationCategory={
              integrationCategories?.length == 1 ? integrationCategories[0] : undefined
            }
            authName={authName}
            isEditingExistingConfig={isEditingExistingConfig}
          />
        )}

        {(authType == AuthType.OAUTH1 || authType == AuthType.OAUTH2) && (
          <AuthConfigOAuthForm
            authConfig={authConfig}
            integrationID={integrationID}
            integrationCategory={
              integrationCategories?.length == 1 ? integrationCategories[0] : undefined
            }
            authName={authName}
            isEditingExistingConfig={isEditingExistingConfig}
          />
        )}
      </InputContainer>
    </div>
  ) : (
    <div className="flex items-center justify-center">
      <Text variant="h1">You do not have permission to access this page.</Text>
    </div>
  );
}

export default AuthConfigEditForm;

import { Card, Text, Link } from "@merge-api/merge-javascript-shared";
import { ArrowUpRight } from "lucide-react";

interface PublishInformationContainerProps {
  asanaTicketURL?: string;
  publishDescription?: string;
  blueprintName?: string;
}

const PublishInformationContainer = ({
  asanaTicketURL,
  publishDescription,
  blueprintName,
}: PublishInformationContainerProps) => {
  return (
    <Card variant="outline" className="py-2 px-3 w-full mb-4">
      <div className="flex flex-row justify-between items-center">
        <div>
          <Text
            as="div"
            variant="sm"
            className="break-words whitespace-pre-wrap truncate font-semibold"
          >
            {blueprintName ?? "No blueprint name"}
          </Text>
          <Text
            as="div"
            variant="sm"
            className="break-words whitespace-pre-wrap truncate font-normal"
          >
            {publishDescription ?? "No publish description provided by user"}
          </Text>
        </div>
        <Link
          href={asanaTicketURL}
          variant="sm"
          target="_blank"
          className="inline-flex flex-row items-center font-semibold text-blue-40 hover:text-blue-60"
        >
          Ticket <ArrowUpRight className="ml-1.5 flex items-center" size={12} />
        </Link>
      </div>
    </Card>
  );
};
export default PublishInformationContainer;

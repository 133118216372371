import { Text } from "@merge-api/merge-javascript-shared";
import { Container } from "react-bootstrap";
import MergeTable from "../shared/MergeTable";
import CanaryTestDashboardRow from "./CanaryTestDashboardRow";
import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../api-client/api_client";
import { IntegrationCanaryTestDashboardList } from "./types";
import { showErrorToast } from "../shared/Toasts";
import TableSkeletonLoader from "../shared/TableSkeletonLoader";
import IntegrationBuilderEmptyState from "../integration-builder/shared/IntegrationBuilderEmptyState";
import { CloudMoon } from "lucide-react";

const CanaryTestingDashboard = () => {
  const [dashboardData, setDashboardData] = useState<IntegrationCanaryTestDashboardList>({
    dashboard_rows: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/integrations/canary-test-dashboard`,
      method: "GET",
      onResponse: (data: IntegrationCanaryTestDashboardList) => {
        setDashboardData(data);
        setIsLoading(false);
      },
      onError: () => {
        showErrorToast("Error fetching canary test dashboard data");
        setDashboardData({ dashboard_rows: [] });
        setIsLoading(false);
      },
    });
  }, []);

  return (
    <Container fluid className="pb-8 px-8 pt-16">
      <Text variant="h2" className="mt-10 mb-8">
        Canary tests
      </Text>
      <div className="my-6 bg-gray-20 h-[0.5px]" />
      <MergeTable
        hover={false}
        hasMorePaddingOnFirstElement
        header={
          <>
            <th scope="col">Integration</th>
            <th scope="col">Status</th>
            <th scope="col">Initiated</th>
            <th scope="col">User</th>
          </>
        }
        content={
          isLoading ? (
            <TableSkeletonLoader cols={4} rows={8} size="large" />
          ) : dashboardData.dashboard_rows.length === 0 ? (
            <IntegrationBuilderEmptyState
              title="No canary tests"
              subtitle="Go take a break!"
              numberOfColumns={4}
              withIcon={true}
              icon={CloudMoon}
            />
          ) : (
            dashboardData.dashboard_rows.map((row) => (
              <CanaryTestDashboardRow key={row.integration_id} row={row} />
            ))
          )
        }
      />
    </Container>
  );
};

export default CanaryTestingDashboard;

import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CurlyBraces, Trash } from "lucide-react";
import {
  Archive,
  CornerUpLeft,
  CornerUpRight,
  GitPullRequest,
  GitPullRequestClosed,
  List,
  Save,
  XOctagon,
  ExternalLink,
} from "lucide-react";
import { GlobalHotKeys } from "react-hotkeys";
import {
  Blueprint,
  BlueprintGhostStepType,
  BlueprintStatus,
  BlueprintStep,
  BlueprintVersion,
  BlueprintVersionPublishState,
  BlueprintVersionStaleParametersInfo,
  IntegrationEditorAction,
} from "../../../models/Blueprints";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import IntegrationEditorTopControlPanel from "../../mapping-tests/top-control-panel/IntegrationEditorTopControlPanel";
import PreExitUnsavedChangesModal from "../../shared/unsaved-changes/PreExitUnsavedChangesModal";
import useBlueprintContext from "../context/useBlueprintContext";
import VersionControlSaveModal from "./VersionControlSaveModal";
import {
  stageBlueprintVersion,
  unstageBlueprintVersion,
  unpublishAllBlueprintVersions,
  changeBlueprintStatus,
  fetchStaleParametersByBlueprintVersionID,
} from "../utils/BlueprintEditorAPIClient";
import { fetchBlueprintVersions } from "../utils/BlueprintEditorAPIClient";
import { PUBLISH_STATES } from "../../../constants";
import {
  isBlueprintStep,
  getNumberOfPathsForStep,
  showErrorMessages,
} from "../../blueprint-editor/utils/BlueprintEditorUtils";
import ChangelogPublishModal from "../../integrations/changelog/ChangelogPublishModal";
import { navigateToPublishModule } from "../../../router/RouterUtils";
import BlueprintVersionHistoryModal from "./BlueprintVersionHistoryModal";
import BlueprintVersionDeletionDialog from "../dialogs/BlueprintVersionDeletionDialog";

const BlueprintEditorTopControlPanel = ({ hasUnsavedChanges }: { hasUnsavedChanges: boolean }) => {
  const history = useHistory();
  const [isShowingVersionControlSaveModal, setIsShowingVersionControlSaveModal] = useState(false);
  const [isShowingVersionControlHistory, setIsShowingVersionControlHistory] = useState(false);
  const [isShowingChangelogPublishModal, setIsShowingChangelogPublishModal] = useState(false);
  const [publishIntent, setPublishIntent] = useState<string>(PUBLISH_STATES.PUBLISHED);
  const [asanaTicketField, setAsanaTicketField] = useState<string>("");
  const [changelogCommentField, setChangelogCommentField] = useState<string>("");
  const [resetTimestampsOnPublish, setResetTimestampsOnPublish] = useState<boolean>(false);
  const [hasPublished, setHasPublished] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    undoActions,
    blueprint,
    setBlueprint,
    setOriginalBlueprint,
    selectedStep,
    setSelectedStep,
    copiedStep,
    deleteStep,
    deleteSteps,
    setCopiedStep,
    addCopiedStep,
    doesBlueprintHaveUnsavedChanges,
    stepRelationMap,
    selectedSteps,
    setSelectedSteps,
    copiedSteps,
    addCopiedSteps,
    addCopiedStepsFromClipboard,
    setCopiedSteps,
    setBackendStaleParameters,
    isIntegrationInCanaryTesting,
  } = useBlueprintContext();
  const [isShowingBlueprintEditorOnExitModal, setIsShowingBlueprintEditorOnExitModal] = useState<
    boolean
  >();
  const [lastSavedAt, setLastSavedAt] = useState<Date | null>(null);
  const [isPollingBackendStaleParams, setIsPollingBackendStaleParams] = useState<boolean>(false);
  const [
    isShowingConfirmDeleteBlueprintVersionDialog,
    setIsShowingConfirmDeleteBlueprintVersionDialog,
  ] = useState(false);
  const [nextBlueprintVersionID, setNextBlueprintVersionID] = useState<string | null>();

  const isSavingDisabled = blueprint.steps.length === 0;

  useEffect(() => {
    if (isPollingBackendStaleParams && lastSavedAt) {
      const endTime = Date.now() + 30000; // Stop polling after 30 seconds
      let intervalId: NodeJS.Timeout;

      const poll = () => {
        if (Date.now() >= endTime) {
          setIsPollingBackendStaleParams(false);
          clearInterval(intervalId); // Stop polling after 30 seconds
          return;
        }

        fetchStaleParametersByBlueprintVersionID({
          blueprintVersionID: blueprint.version.id,
          onSuccess: (response: BlueprintVersionStaleParametersInfo) => {
            if (
              response?.status === "FINISHED_WITH_SUCCESS" &&
              new Date(response?.modified_at) > lastSavedAt
            ) {
              setBackendStaleParameters(response);
              clearInterval(intervalId); // Stop polling if conditions are met
              setIsPollingBackendStaleParams(false);
            }
          },
          onError: () => {
            showErrorToast("Something went wrong fetching latest stale parameters");
            clearInterval(intervalId); // Optionally stop polling on error
            setIsPollingBackendStaleParams(false);
          },
        });
      };

      intervalId = setInterval(poll, 500);

      return () => clearInterval(intervalId); // Cleanup interval on component unmount or when isPolling becomes false
    }
    return () => {};
  }, [isPollingBackendStaleParams, blueprint.version.id]);

  const { undo, redo, canUndo, canRedo } = undoActions;

  const handlers = {
    UNDO: undo,
    REDO: (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      redo();
    },
    SAVE: (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (!isSavingDisabled) {
        setIsShowingVersionControlSaveModal(true);
      }
    },
    COPY: () => {
      if (typeof window.getSelection != "undefined" && !window.getSelection()?.toString()) {
        if (selectedSteps) {
          setCopiedSteps(selectedSteps.slice());
          navigator.clipboard.writeText(JSON.stringify(selectedSteps.slice()));
          showSuccessToast(selectedSteps.length + " Steps copied.");
        } else if (isBlueprintStep(selectedStep)) {
          setCopiedStep(selectedStep as BlueprintStep);
          navigator.clipboard.writeText(JSON.stringify(selectedStep));
          showSuccessToast("Step copied.");
        }
      }
    },
    CUT: () => {
      if (typeof window.getSelection != "undefined" && !window.getSelection()?.toString()) {
        if (selectedSteps) {
          setCopiedSteps(selectedSteps.slice());
          deleteSteps(selectedSteps as BlueprintStep[]);
          showSuccessToast(selectedSteps.length + " Steps cut.");
        } else if (isBlueprintStep(selectedStep)) {
          setCopiedStep(selectedStep as BlueprintStep);
          deleteStep(selectedStep.id);
          showSuccessToast("Step cut.");
        }
      }
    },
    PASTE: () => {
      if (selectedStep && selectedStep.template === "ghost") {
        const { pathKey, relatedStepID, newStepRelation } = selectedStep as BlueprintGhostStepType;
        if (copiedSteps) {
          addCopiedSteps(copiedSteps as BlueprintStep[], newStepRelation, relatedStepID, pathKey);
        } else if (copiedStep) {
          addCopiedStep(copiedStep, newStepRelation, relatedStepID, pathKey);
        } else if (navigator.clipboard.readText() !== null) {
          addCopiedStepsFromClipboard(newStepRelation, relatedStepID, pathKey);
        }
      }
    },
    PASTE_CLIPBOARD: () => {
      if (selectedStep && selectedStep.template === "ghost") {
        const { pathKey, relatedStepID, newStepRelation } = selectedStep as BlueprintGhostStepType;
        if (navigator.clipboard.readText() !== null) {
          addCopiedStepsFromClipboard(newStepRelation, relatedStepID, pathKey);
        }
      }
    },
    UP: () => {
      if (isBlueprintStep(selectedStep)) {
        const stepRelations = stepRelationMap.get(selectedStep.id);
        if (stepRelations?.predecessor) {
          setSelectedStep(stepRelations.predecessor);
        } else if (stepRelations?.parent) {
          setSelectedStep(stepRelations.parent);
        }
      }
    },
    DOWN: () => {
      if (isBlueprintStep(selectedStep)) {
        const stepRelations = stepRelationMap.get(selectedStep.id);
        if (stepRelations?.successor) {
          setSelectedStep(stepRelations.successor);
        }
      }
    },
    LEFT: () => {
      if (isBlueprintStep(selectedStep)) {
        const stepRelations = stepRelationMap.get(selectedStep.id);
        if (selectedStep.paths != null && getNumberOfPathsForStep(selectedStep) > 1) {
          const pathArray = Object.entries(selectedStep.paths);
          const [_, leftmostPath] = pathArray[0];
          if (leftmostPath.length > 0) {
            setSelectedStep(leftmostPath[0]);
          }
        } else if (stepRelations?.parent && stepRelations.parentPath !== "true") {
          setSelectedStep(stepRelations.parent);
        }
      }
    },
    RIGHT: () => {
      if (isBlueprintStep(selectedStep)) {
        const stepRelations = stepRelationMap.get(selectedStep.id);
        if (selectedStep.paths != null) {
          const pathArray = Object.entries(selectedStep.paths);
          const [_, rightmostPath] = pathArray[pathArray.length - 1];
          if (rightmostPath.length > 0) {
            setSelectedStep(rightmostPath[0]);
          }
        } else if (stepRelations?.parent && stepRelations.parentPath === "true") {
          setSelectedStep(stepRelations.parent);
        }
      }
    },
    SHIFT_UP: () => {
      if (isBlueprintStep(selectedStep)) {
        if (selectedSteps && selectedSteps.length > 1 && selectedSteps[0].id === selectedStep.id) {
          const newSelectedSteps = selectedSteps.slice();
          newSelectedSteps.pop();
          setSelectedSteps(newSelectedSteps);
        } else {
          const newSelectedSteps = selectedSteps?.slice() ?? [selectedStep];
          var topStep = selectedStep;
          if (
            selectedSteps &&
            selectedSteps.length > 0 &&
            selectedSteps[0].id !== selectedStep.id
          ) {
            topStep = selectedSteps[0] as BlueprintStep;
          }
          const stepRelations = stepRelationMap.get(topStep.id);
          if (stepRelations?.predecessor) {
            document.getElementById(stepRelations.predecessor.id)?.focus();
            newSelectedSteps.unshift(stepRelations.predecessor);
            setSelectedSteps(newSelectedSteps);
          }
        }
      }
    },
    SHIFT_DOWN: () => {
      if (isBlueprintStep(selectedStep)) {
        if (
          selectedSteps &&
          selectedSteps.length > 1 &&
          selectedSteps[selectedSteps.length - 1].id === selectedStep.id
        ) {
          const newSelectedSteps = selectedSteps.slice();
          newSelectedSteps.shift();
          setSelectedSteps(newSelectedSteps);
        } else {
          const newSelectedSteps = selectedSteps?.slice() ?? [selectedStep];
          var bottomStep = selectedStep;
          if (
            selectedSteps &&
            selectedSteps.length > 0 &&
            selectedSteps[selectedSteps.length - 1].id !== selectedStep.id
          ) {
            bottomStep = selectedSteps[selectedSteps.length - 1] as BlueprintStep;
          }
          const stepRelations = stepRelationMap.get(bottomStep.id);
          if (stepRelations?.successor) {
            newSelectedSteps.push(stepRelations.successor);
            document.getElementById(stepRelations.successor.id)?.focus();
            setSelectedSteps(newSelectedSteps);
          }
        }
      }
    },
  };

  const isStaged = blueprint.version.publish_state === BlueprintVersionPublishState.Staged;
  const isDraft = blueprint.version.publish_state === BlueprintVersionPublishState.Draft;
  const isPublished = blueprint.version.publish_state === BlueprintVersionPublishState.Published;
  const isUnpublished =
    blueprint.version.publish_state === BlueprintVersionPublishState.Unpublished;

  // checks if there is a published version, and sets the next blueprint version to navigate to in the case this version is deleted
  useEffect(() => {
    if (!blueprint.id) return;

    fetchBlueprintVersions({
      blueprintID: blueprint.id,
      onSuccess: (versions: BlueprintVersion[]) => {
        const publishedVersion = versions.find(
          (version) => version.publish_state === BlueprintVersionPublishState.Published
        );

        // If there is a published version, set it accordingly
        setHasPublished(!!publishedVersion);
        setIsArchived(blueprint.status === BlueprintStatus.Archived);

        if (publishedVersion) {
          setNextBlueprintVersionID(publishedVersion.id);
          return;
        }

        const stagedVersion = versions.find(
          (version) => version.publish_state === BlueprintVersionPublishState.Staged
        );

        if (stagedVersion) {
          setNextBlueprintVersionID(stagedVersion.id);
          return;
        }

        // Filter out the current version only when selecting the latest draft, if there is one that exists
        const latestDraftVersion = versions
          .filter(
            (version) =>
              version.publish_state === BlueprintVersionPublishState.Draft &&
              version.id !== blueprint.version.id
          )
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0];

        setNextBlueprintVersionID(latestDraftVersion?.id ?? null);
      },
    });
  }, [blueprint.id, blueprint.version.id]);

  const onArchive = useCallback(() => {
    if (doesBlueprintHaveUnsavedChanges) {
      setIsShowingBlueprintEditorOnExitModal(true);
    } else {
      changeBlueprintStatus({
        versionID: blueprint.version.id,
        status: BlueprintStatus.Archived,
        onSuccess: (blueprint: Blueprint) => {
          setBlueprint(blueprint);
          setOriginalBlueprint(blueprint);
          setIsArchived(true);
          showSuccessToast("Successfully archived blueprint!");
        },
        onError: () => {
          showErrorToast("Failed to archive blueprint.");
        },
      });
    }
  }, [doesBlueprintHaveUnsavedChanges, blueprint.version.id, setBlueprint, setOriginalBlueprint]);

  const onUnarchive = useCallback(() => {
    if (doesBlueprintHaveUnsavedChanges) {
      setIsShowingBlueprintEditorOnExitModal(true);
    } else {
      changeBlueprintStatus({
        versionID: blueprint.version.id,
        status: BlueprintStatus.Active,
        onSuccess: (blueprint: Blueprint) => {
          setBlueprint(blueprint);
          setOriginalBlueprint(blueprint);
          setIsArchived(false);
          showSuccessToast("Successfully unarchived blueprint!");
        },
        onError: () => {
          showErrorToast("Failed to unarchive blueprint.");
        },
      });
    }
  }, [doesBlueprintHaveUnsavedChanges, blueprint.version.id, setBlueprint, setOriginalBlueprint]);

  const onStageAndSaveUnsavedChanges = useCallback(() => {
    if (blueprint.status === BlueprintStatus.Archived) {
      showErrorToast("Can't stage an archived blueprint.");
    } else if (doesBlueprintHaveUnsavedChanges) {
      setIsShowingBlueprintEditorOnExitModal(true);
    } else {
      stageBlueprintVersion({
        versionID: blueprint.version.id,
        onSuccess: (blueprint: Blueprint) => {
          setBlueprint(blueprint);
          setOriginalBlueprint(blueprint);
          showSuccessToast("Staged! Mapping tests initiated. Monitor in Publish Module.");
        },
        onError: (err: Response | undefined) => {
          showErrorMessages(err, "Failed to stage this version.");
        },
      });
    }
  }, [doesBlueprintHaveUnsavedChanges, blueprint.version.id, setBlueprint, setOriginalBlueprint]);

  const onUnstageAndSaveUnsavedChanges = useCallback(() => {
    if (doesBlueprintHaveUnsavedChanges) {
      setIsShowingBlueprintEditorOnExitModal(true);
    } else {
      unstageBlueprintVersion({
        versionID: blueprint.version.id,
        onSuccess: (blueprint: Blueprint) => {
          setBlueprint(blueprint);
          setOriginalBlueprint(blueprint);
          showSuccessToast("Unstaged! Mapping tests initiated. Monitor in Publish Module.");
        },
        onError: (err: Response | undefined) => {
          showErrorMessages(err, "Failed to un-stage this version.");
        },
      });
    }
  }, [doesBlueprintHaveUnsavedChanges, blueprint.version.id, setBlueprint, setOriginalBlueprint]);

  const onDeleteBlueprintVersion = useCallback(() => {
    setIsShowingConfirmDeleteBlueprintVersionDialog(true);
  }, [isDraft]);

  const onRedirectPublishAndSaveUnsavedChanges = useCallback(() => {
    if (blueprint.status === BlueprintStatus.Archived) {
      showErrorToast("Can't publish an archived blueprint.");
    } else if (doesBlueprintHaveUnsavedChanges) {
      setIsShowingBlueprintEditorOnExitModal(true);
    } else {
      navigateToPublishModule(history, blueprint["integration"]["id"], true);
    }
  }, [
    doesBlueprintHaveUnsavedChanges,
    setBlueprint,
    setOriginalBlueprint,
    blueprint.status,
    blueprint.version.id,
    asanaTicketField,
    changelogCommentField,
    resetTimestampsOnPublish,
  ]);

  const onUnpublish = useCallback(() => {
    setIsLoading(true);
    unpublishAllBlueprintVersions({
      blueprintID: blueprint.id,
      description: changelogCommentField,
      ticket: asanaTicketField,
      onSuccess: (blueprint: Blueprint) => {
        setHasPublished(false);
        setBlueprint(blueprint);
        setOriginalBlueprint(blueprint);
        setIsLoading(false);
        showSuccessToast("Successfully unpublished this blueprint!");
        setIsShowingChangelogPublishModal(false);
      },
      onError: () => {
        setIsLoading(false);
        showErrorToast("Failed to unpublish this blueprint");
      },
    });
  }, [blueprint.id, setBlueprint, setOriginalBlueprint, asanaTicketField, changelogCommentField]);

  const convertJSONToPythonDict = (jsonObject: any) =>
    JSON.stringify(
      jsonObject,
      function (_, value) {
        return value === null ? "___null___" : value;
      },
      2
    )
      .replace(/"___null___"/g, "None")
      .replace(/: true/g, ": True")
      .replace(/: false/g, ": False");

  const copyBlueprintJSONToClipboard = useCallback(() => {
    navigator.clipboard.writeText(convertJSONToPythonDict(blueprint));
    showSuccessToast("Blueprint JSON Successfully copied to clipboard");
  }, [blueprint]);

  return (
    <>
      <GlobalHotKeys allowChanges={true} handlers={handlers} />
      <IntegrationEditorTopControlPanel
        actions={[
          {
            Icon: CurlyBraces,
            isDisabled: false,
            onClick: copyBlueprintJSONToClipboard,
            text: IntegrationEditorAction.COPY_BLUEPRINT_JSON,
          },
          {
            Icon: CornerUpLeft,
            isDisabled: !canUndo,
            onClick: undo,
            text: IntegrationEditorAction.UNDO,
          },
          {
            Icon: CornerUpRight,
            isDisabled: !canRedo,
            onClick: redo,
            text: IntegrationEditorAction.REDO,
          },
          {
            Icon: List,
            isDisabled: false,
            onClick: () => setIsShowingVersionControlHistory(true),
            text: IntegrationEditorAction.VERSION_HISTORY,
          },
        ]}
        publishedStateActions={[
          {
            Icon: Save,
            isDisabled: isSavingDisabled || !hasUnsavedChanges,
            onClick: () => setIsShowingVersionControlSaveModal(true),
            text: IntegrationEditorAction.SAVE_DRAFT,
            disabledTooltipText: "Make changes before saving a new draft",
          },
          {
            Icon: GitPullRequest,
            isHidden: !isDraft && !isUnpublished,
            isDisabled: isArchived || hasUnsavedChanges || isIntegrationInCanaryTesting,
            onClick: onStageAndSaveUnsavedChanges,
            text: IntegrationEditorAction.STAGE,
            disabledTooltipText: (() => {
              const messages = [
                isArchived ? "Unarchive the blueprint before staging" : "",
                hasUnsavedChanges ? "Save or discard unsaved changes before staging" : "",
                isIntegrationInCanaryTesting ? "Staging is blocked during canary testing" : "",
              ].filter(Boolean);
              console.log(messages);
              return messages.length > 1
                ? messages.map((msg, index) => (index === 0 ? msg + "." : msg + ".")).join(" ")
                : messages[0] || undefined;
            })(),
          },
          {
            Icon: GitPullRequestClosed,
            isHidden: !isStaged,
            isDisabled: isArchived,
            onClick: onUnstageAndSaveUnsavedChanges,
            text: IntegrationEditorAction.UNSTAGE,
            disabledTooltipText: "Unarchive the blueprint before unstaging",
          },
          {
            Icon: ExternalLink,
            isHidden: !isStaged,
            isDisabled: isArchived,
            onClick: onRedirectPublishAndSaveUnsavedChanges,
            text: IntegrationEditorAction.PUBLISH,
            disabledTooltipText: "Unarchive the blueprint before publishing",
          },
          {
            Icon: XOctagon,
            isHidden: !isPublished,
            onClick: () => {
              setPublishIntent(PUBLISH_STATES.UNPUBLISHED);
              setIsShowingChangelogPublishModal(true);
            },
            text: IntegrationEditorAction.UNPUBLISH,
          },
          {
            Icon: Archive,
            isDisabled: hasPublished,
            isHidden: isArchived,
            onClick: onArchive,
            text: IntegrationEditorAction.ARCHIVE,
            disabledTooltipText: "Unpublish the blueprint before archiving",
          },
          {
            Icon: Archive,
            isHidden: !isArchived,
            onClick: onUnarchive,
            text: IntegrationEditorAction.UNARCHIVE,
          },
          {
            Icon: Trash,
            isDisabled: !isDraft,
            onClick: onDeleteBlueprintVersion,
            text: IntegrationEditorAction.DELETE,
            disabledTooltipText: "Only draft blueprint versions can be deleted",
          },
        ]}
        integration={blueprint.integration}
        path={["Blueprints", blueprint?.human_name ?? blueprint.name]}
        isArchived={isArchived}
      />
      <BlueprintVersionDeletionDialog
        blueprint={blueprint}
        isOpen={isShowingConfirmDeleteBlueprintVersionDialog}
        onClose={() => setIsShowingConfirmDeleteBlueprintVersionDialog(false)}
        nextBlueprintVersionID={nextBlueprintVersionID}
      />
      <BlueprintVersionHistoryModal
        isVisible={isShowingVersionControlHistory}
        onHide={() => setIsShowingVersionControlHistory(false)}
      />
      <ChangelogPublishModal
        isShown={isShowingChangelogPublishModal}
        asanaTicketField={asanaTicketField}
        setAsanaTicketField={setAsanaTicketField}
        changelogCommentField={changelogCommentField}
        setChangelogCommentField={setChangelogCommentField}
        resetTimestampsOnPublish={resetTimestampsOnPublish}
        setResetTimestampsOnPublish={setResetTimestampsOnPublish}
        onHide={() => setIsShowingChangelogPublishModal(false)}
        publishIntent={publishIntent}
        publishAction={
          publishIntent === PUBLISH_STATES.PUBLISHED
            ? onRedirectPublishAndSaveUnsavedChanges
            : onUnpublish
        }
        isLoading={isLoading}
      />
      <VersionControlSaveModal
        show={isShowingVersionControlSaveModal}
        onHide={() => {
          setIsShowingVersionControlSaveModal(false);
        }}
        setIsPolling={setIsPollingBackendStaleParams}
        setLastSavedAt={setLastSavedAt}
      />
      <PreExitUnsavedChangesModal
        show={isShowingBlueprintEditorOnExitModal}
        onHide={() => {
          setIsShowingBlueprintEditorOnExitModal(false);
        }}
      />
    </>
  );
};

export default BlueprintEditorTopControlPanel;

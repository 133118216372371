import { AdminAlert, PaginationType } from "../../../models/Entities";
import { usePaginatedRequest } from "../../../hooks/usePaginatedRequest";
import queryString from "query-string";
import {
  ALERT_CATEGORIES,
  AlertSearchQueryParams,
  SearchMetadata,
  SEARCH_METADATA_CATEGORIES,
} from "../dashboard/components/utils/constants";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { fetchWithAuth } from "../../../api-client/api_client";
import { showErrorToast } from "../../../components/shared/Toasts";
import { constructAlertCountQueryParams } from "../dashboard/components/utils/helpers";

interface useLoadPaginatedAlertsProps {
  alertCategory: ALERT_CATEGORIES;
  queryParams: AlertSearchQueryParams;
  alertID?: string;
}

const useLoadPaginatedAlerts = ({
  alertCategory,
  queryParams,
  alertID,
}: useLoadPaginatedAlertsProps) => {
  queryParams.alert_level = alertCategory;
  if (alertID) {
    queryParams.id = alertID;
  }

  const paramsPath = queryString.stringify(queryParams);

  const { results: alerts, hasNext, onNext, isLoading } = usePaginatedRequest<AdminAlert>({
    paramsPath,
    rootPath: "analytics/alert-dashboard/search",
    isInfiniteScroll: true,
    paginationType: PaginationType.OFFSET,
  });

  return {
    alerts,
    hasNext,
    onNext,
    isLoading,
  };
};

const useLoadAlertCounts = (
  setAlertCounts: Dispatch<SetStateAction<Record<ALERT_CATEGORIES, number>>>,
  showResolved: boolean,
  showSilenced: boolean
) => {
  const baseQueryParams = useMemo(
    () => constructAlertCountQueryParams(showResolved, showSilenced),
    [showResolved, showSilenced]
  );

  useEffect(() => {
    Object.values(ALERT_CATEGORIES).forEach((alertCategory) => {
      const queryParams = { ...baseQueryParams, alert_level: alertCategory };
      const paramsPath = queryString.stringify(queryParams);

      fetchWithAuth({
        path: `analytics/alert-dashboard/count?${paramsPath}`,
        method: "GET",
        onResponse: (data) => {
          setAlertCounts((prevCounts) => ({
            ...prevCounts,
            [alertCategory]: data.count || 0,
          }));
        },
        onError: (error) => {
          showErrorToast(`Failed to fetch alert count: ${error}`);
        },
      });
    });
  }, [baseQueryParams]); // Only rerun when showResolved / showSilenced change
};

const useLoadSearchMetadata = (
  setSearchMetadata: Dispatch<SetStateAction<Record<SEARCH_METADATA_CATEGORIES, SearchMetadata[]>>>
) => {
  useEffect(() => {
    Object.values(SEARCH_METADATA_CATEGORIES).forEach((category) => {
      const queryParams = { category };
      const paramsPath = queryString.stringify(queryParams);

      fetchWithAuth({
        path: `analytics/alert-dashboard/search-metadata?${paramsPath}`,
        method: "GET",
        onResponse: (data) => {
          setSearchMetadata((prevMetadata) => ({
            ...prevMetadata,
            [category]: data,
          }));
        },
        onError: (error) => {
          showErrorToast(`Failed to fetch search metadata: ${error}`);
        },
      });
    });
  }, []); // No need to depend on alertCategory since we fetch all categories
};

export { useLoadAlertCounts, useLoadPaginatedAlerts, useLoadSearchMetadata };

import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import { usePublishModuleContext } from "../context/PublishModuleContext";

type Props = {
  open: boolean;
  onClose: () => void;
};

const CanaryTestingPublishModal = ({ open, onClose }: Props) => {
  const { setIsPublishModalOpen } = usePublishModuleContext();

  return (
    <Dialog
      title="Are you sure?"
      open={open}
      onClose={onClose}
      primaryButtonVariant={ButtonVariant.DangerFilled}
      primaryButtonText="Publish"
      onPrimaryButtonClick={() => {
        setIsPublishModalOpen(true);
        onClose();
      }}
    >
      Publishing without canary testing will roll out changes to all Linked Accounts immediately.
    </Dialog>
  );
};

export default CanaryTestingPublishModal;

import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Button } from "@merge-api/merge-javascript-shared";
import {
  BlueprintMergeObjectsStep,
  BlueprintParameterValue,
  BlueprintParameterValueType,
} from "../../../models/Blueprints";
import useBlueprintContext from "../context/useBlueprintContext";
import TypeaheadFormField from "./TypeaheadFormField";
import MergeObjectsTypeaheadFormField from "./CancellableTypeaheadFormField";

const OBJECT_PARAMETERS = ["base_object", "overriding_object"];

const BlueprintEditorRightPanelMergeObjectsForm = () => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintMergeObjectsStep;
  const [expandKeyList, setExpandKeyList] = useState<boolean>(true);
  const [currentKeys, setCurrentKeys] = useState<Array<BlueprintParameterValue | null>>([]);
  useEffect(() => {
    const keys = step.parameter_values.keys ?? {};
    if (keys.value_type === BlueprintParameterValueType.customArray) {
      const array_values = keys.array_values ?? [];
      setCurrentKeys(array_values);
    } else {
      setCurrentKeys([]);
    }
  }, [expandKeyList]);

  /**
   * Note the difference between how we're tracking keys in the UI vs what
   * we store in the blueprint's parameter_values list. In the UI we allow
   * for null entries to be sprinkled in, but when updating the blueprint's
   * parameter_values array we filter out all the null entries to only
   * keep concrete parameter values. This is safe since the ordering of
   * the keys is totally arbitrary. However this means that when refreshing
   * the UI this list may change because it will expand into a list of only
   * the non-null entries, whereas before refreshing there may have been
   * null entries in between.
   */
  const updateKeys = (newKeys: Array<BlueprintParameterValue | null>) => {
    setCurrentKeys(newKeys);
    updateStepParameterValue(step, "keys", {
      value_type: BlueprintParameterValueType.customArray,
      array_values: newKeys.filter((param) => !!param),
    });
  };

  const addKey = () => {
    updateKeys([...currentKeys, null]);
  };

  const deleteKey = (index: number) => {
    updateKeys([...currentKeys.slice(0, index), ...currentKeys.slice(index + 1)]);
  };

  const updateKey = (index: number, newParameterValue: BlueprintParameterValue | null) => {
    updateKeys([
      ...currentKeys.slice(0, index),
      newParameterValue,
      ...currentKeys.slice(index + 1),
    ]);
  };

  return (
    <>
      {OBJECT_PARAMETERS.map((parameterKey) => {
        return (
          <TypeaheadFormField
            key={parameterKey}
            title={parameterKey}
            valueKey={parameterKey}
            parameterType={"object"}
          />
        );
      })}
      <div className="ml-1.5 mb-1.5">
        <div>
          <Form.Check
            type="checkbox"
            label="Expand key list"
            defaultChecked={expandKeyList}
            className="mb-3"
            onClick={() => setExpandKeyList(!expandKeyList)}
          />
        </div>
        <TypeaheadFormField
          key={"keys"}
          title={"Keys"}
          valueKey={"keys"}
          parameterType={"array"}
          disabled={expandKeyList}
        />
        {expandKeyList && (
          <>
            <form>
              {currentKeys.map((key, index) => (
                <div key={index}>
                  <MergeObjectsTypeaheadFormField
                    onChange={(newValue: BlueprintParameterValue | null) => {
                      updateKey(index, newValue);
                    }}
                    currentParameterValue={key}
                    title={`Key ${index + 1}`}
                    onDelete={() => deleteKey(index)}
                  />
                  <hr />
                </div>
              ))}
              <Button className="mt-0 btn-block w-full" onClick={addKey}>
                Add New Key
              </Button>
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default BlueprintEditorRightPanelMergeObjectsForm;

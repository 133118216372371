import { Text } from "@merge-api/merge-javascript-shared";
import GradientIcon from "../../../../../components/shared/icons/GradientIcon";
import { Frown } from "lucide-react";

const UnpublishedBlueprintEmptyState = () => {
  return (
    <div className="flex flex-col text-center items-center justify-center align-middle py-8 px-6">
      <GradientIcon
        Icon={Frown}
        size={24}
        endColor="#2DB9EE"
        startColor="#0042E5"
        className="mb-4"
      />
      <Text className="text-gray-70" variant="h5">
        No published blueprint version to compare Common Model diffs
      </Text>
    </div>
  );
};

export default UnpublishedBlueprintEmptyState;

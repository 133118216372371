import { ChangelogEvent, ChangelogEventType } from "../../utils/types";
import DateCell from "./components/DateCell";
import UserCell from "./components/UserCell";
import EventCell from "./components/EventCell";
import PublishInformationContainer from "./components/PublishInformationContainer";
import { Accordion, Text } from "@merge-api/merge-javascript-shared";
import useLoadCanaryTestResults from "../../../publish-requests/hooks/useLoadCanaryTestResults";
import { useContext, useEffect } from "react";
import CanaryTestResultsTables from "../../../../shared/CanaryTestResultsTables";
import { AccordionContext, Accordion as AccordionReact, useAccordionToggle } from "react-bootstrap";
import ChevronCell from "./components/ChevronCell";

type CombinedChangelogTableRowProps = {
  changelogEvents: ChangelogEvent[];
};

const CombinedChangelogTableRow = ({ changelogEvents }: CombinedChangelogTableRowProps) => {
  //Shared props across all events are destructured below
  const { created_at, event_type, canary_test_id } = changelogEvents[0];

  const { canaryTestResults, fetchCanaryTestResultsByID } = useLoadCanaryTestResults();

  useEffect(() => {
    fetchCanaryTestResultsByID(canary_test_id!);
  }, [canary_test_id]);

  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(canary_test_id!, () => {
    // no need to manually update the context here, useAccordionToggle will handle it
  });

  return (
    <>
      <tr onClick={toggleOnClick} className="cursor-pointer: true">
        <UserCell name={canaryTestResults?.user_name} email={canaryTestResults?.user_email} />
        <td>
          <Text>Multiple models</Text>
        </td>
        <EventCell eventType={event_type} />
        <DateCell date={created_at} />
        <ChevronCell isRotatedDown={currentEventKey === canary_test_id} isVisible={true} />
      </tr>
      <tr className="border-none">
        <td colSpan={5} className="p-0 border-none">
          <AccordionReact.Collapse eventKey={canary_test_id!}>
            <div className="px-5">
              {(event_type === ChangelogEventType.PUBLISHED ||
                event_type === ChangelogEventType.CANARY_TEST_ROLLBACK ||
                event_type === ChangelogEventType.CANARY_TEST_AUTO_ROLLBACK) && (
                <Accordion
                  title={
                    <Text className="p-3" variant="h6">
                      Canary test results
                    </Text>
                  }
                  className="mb-4"
                  variant="outline"
                  chevronOrientation="right"
                  titleClassName="pr-3"
                >
                  <div className="px-3 border-top">
                    <CanaryTestResultsTables canaryTestResults={canaryTestResults} />
                  </div>
                </Accordion>
              )}
              {changelogEvents.map(({ asana_ticket_url, blueprint_name, publish_description }) => (
                <PublishInformationContainer
                  asanaTicketURL={asana_ticket_url}
                  blueprintName={blueprint_name}
                  publishDescription={publish_description}
                />
              ))}
            </div>
          </AccordionReact.Collapse>
        </td>
      </tr>
    </>
  );
};

export default CombinedChangelogTableRow;

import { useContext, useEffect, useState } from "react";
import { APIProtocols, APIRequestTypes } from "../../../../../models/APIEndpointModels";
import JSONSchemaConverterFormField from "../../../../integrations/api-endpoints/JSONSchemaConverterFormField";
import AccordionCard from "../../../shared/AccordionCard";
import CheckboxHeader from "../../../shared/CheckboxHeader";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";
import { API_ENDPOINT_REQUEST_TYPE_OPTIONS_DATA } from "../../constants";
import APIEndpointContext from "../../context/APIEndpointContext";
import { HTTPMethod } from "../../../../../models/HTTPMethods";

const AdvancedRequestConfigurationSetupOptions = () => {
  const {
    protocol,
    method,
    requestType,
    setRequestType,
    fileSchema,
    setFileSchema,
    urlEncodedQueryParamsFormat,
    setUrlEncodedQueryParamsFormat,
    urlEncodedQueryParamSchema,
    setUrlEncodedQueryParamSchema,
    shouldFilterEmptyValuesFromBodyData,
    setShouldFilterEmptyValuesFromBodyData,
    setShouldNotEncodeSpecialCharacters,
    shouldNotEncodeSpecialCharacters,
    shouldAcceptMergeWithJsonRequestBody,
    setShouldAcceptMergeWithJsonRequestBody,
    shouldAcceptRawJsonRequestBody,
    setShouldAcceptRawJsonRequestBody,
    shouldOverrideIntegrationConvertStringsToNumbers,
    setShouldOverrideIntegrationConvertStringsToNumbers,
    shouldOverrideIntegrationConvertBodyDataToString,
    setShouldOverrideIntegrationConvertBodyDataToString,
    soapDoesRequestUseBodyTemplatingEngine,
    setSoapDoesRequestUseBodyTemplatingEngine,
    soapShouldXmlRequestEscapeCharacters,
    setSoapShouldXmlRequestEscapeCharacters,
  } = useContext(APIEndpointContext);

  const handleButtonClick = (value: APIRequestTypes) => {
    setRequestType(value);
  };

  const requestTypeOptions = API_ENDPOINT_REQUEST_TYPE_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: requestType == value,
    onClick: handleButtonClick,
  }));

  // File schema text state and effect to update the json schema
  const [fileSchemaText, setFileSchemaText] = useState<string>(JSON.stringify(fileSchema, null, 2));
  useEffect(() => {
    if (fileSchemaText) {
      try {
        const parsedSchema = JSON.parse(fileSchemaText);
        setFileSchema(parsedSchema);
      } catch {
        // Do nothing
      }
    } else {
      setFileSchema({});
    }
  }, [fileSchemaText]);
  useEffect(() => {
    setFileSchemaText(JSON.stringify(fileSchema, null, 2));
  }, [fileSchema]);

  // URL encoded query param format text state and effect to update the json schema
  const [urlEncodedQueryParamsFormatText, setUrlEncodedQueryParamsFormatText] = useState<string>(
    JSON.stringify(urlEncodedQueryParamsFormat ?? {}, null, 2)
  );
  useEffect(() => {
    if (urlEncodedQueryParamsFormatText) {
      try {
        const parsedSchema = JSON.parse(urlEncodedQueryParamsFormatText);
        setUrlEncodedQueryParamsFormat(parsedSchema);
      } catch {
        // Do nothing
      }
    } else {
      setUrlEncodedQueryParamsFormat({});
    }
  }, [urlEncodedQueryParamsFormatText]);
  useEffect(() => {
    setUrlEncodedQueryParamsFormatText(JSON.stringify(urlEncodedQueryParamsFormat ?? {}, null, 2));
  }, [urlEncodedQueryParamsFormat]);

  // URL encoded query param schema text state and effect to update the json schema
  const [urlEncodedQueryParamSchemaText, setUrlEncodedQueryParamSchemaText] = useState<string>(
    JSON.stringify(urlEncodedQueryParamSchema, null, 2)
  );
  useEffect(() => {
    if (urlEncodedQueryParamSchemaText) {
      try {
        const parsedSchema = JSON.parse(urlEncodedQueryParamSchemaText);
        setUrlEncodedQueryParamSchema(parsedSchema);
      } catch {
        // Do nothing
      }
    } else {
      setUrlEncodedQueryParamSchema({});
    }
  }, [urlEncodedQueryParamSchemaText]);
  useEffect(() => {
    setUrlEncodedQueryParamSchemaText(JSON.stringify(urlEncodedQueryParamSchema, null, 2));
  }, [urlEncodedQueryParamSchema]);

  return (
    <AccordionCard
      dataTestID="accordion-advanced-request-configuration-options"
      chevronSize={12}
      title="Advanced"
      variant="outline"
      nested
      titleClassName="px-5 py-3"
      badgeContents={protocol == APIProtocols.SOAP ? 10 : 9}
    >
      <MultiSelectHeader
        dataTestID="field-api-endpoint-request-type-override"
        title="Request type override"
        subtitle="Request type is automatically set, but this field allows you to override it. Please be very cautious when overriding this value. This should be rare."
        options={requestTypeOptions}
        className="mb-6"
      />
      <JSONSchemaConverterFormField
        dataTestID="field-api-endpoint-file-schema"
        currentValue={fileSchemaText}
        setValue={(schema) => setFileSchemaText(schema)}
        title="File schema"
        subtitle="Schema for the file data. This should only be used when files are being sent in the request body."
        numRows={5}
        className="my-6"
      />
      <JSONSchemaConverterFormField
        dataTestID="field-api-endpoint-url-encoded-query-params-format"
        currentValue={urlEncodedQueryParamsFormatText}
        setValue={(schema) => setUrlEncodedQueryParamsFormatText(schema)}
        title="URL encoded query params format"
        subtitle="A JSON dictionary representing query param format. Our backend will translate keys into query params and its values into URL-encoded values. Supported params: {MODIFIED_AFTER_VALUE}, {PAGINATION_VALUE}. Mostly applicable for the iCIMS integration."
        numRows={5}
        className="my-6"
      />
      <JSONSchemaConverterFormField
        dataTestID="field-api-endpoint-url-encoded-query-param-schema"
        currentValue={urlEncodedQueryParamSchemaText}
        setValue={(schema) => setUrlEncodedQueryParamSchemaText(schema)}
        title="URL encoded query param schema"
        subtitle="A JSON schema representing query param format. Keys defined by the JSON schema will mappable in the Blueprint, and mapped values will be URL-encoded. Mostly applicable for the iCIMS integration."
        numRows={5}
        className="my-6"
      />
      <CheckboxHeader
        dataTestID="field-api-endpoint-should-filter-empty-values-from-body-data"
        className="mt-2"
        title="Should filter empty values from body data"
        onChange={setShouldFilterEmptyValuesFromBodyData}
        checked={shouldFilterEmptyValuesFromBodyData}
      />
      <CheckboxHeader
        className="mt-2"
        title="Should not encode special characters"
        onChange={setShouldNotEncodeSpecialCharacters}
        checked={shouldNotEncodeSpecialCharacters}
      />
      <CheckboxHeader
        dataTestID="field-api-endpoint-should-accept-merge-with-json-request-body"
        className="mt-2"
        title="Should accept merge with json request body"
        onChange={setShouldAcceptMergeWithJsonRequestBody}
        checked={shouldAcceptMergeWithJsonRequestBody}
      />
      <CheckboxHeader
        dataTestID="field-api-endpoint-should-accept-raw-json-request-body"
        className="mt-2"
        title="Should accept raw json request body"
        onChange={setShouldAcceptRawJsonRequestBody}
        checked={shouldAcceptRawJsonRequestBody}
      />
      <CheckboxHeader
        dataTestID="field-api-endpoint-should-override-integration-convert-strings-to-numbers"
        className="mt-2"
        title="Should override integration convert strings to numbers"
        onChange={setShouldOverrideIntegrationConvertStringsToNumbers}
        checked={shouldOverrideIntegrationConvertStringsToNumbers}
      />
      <CheckboxHeader
        dataTestID="field-api-endpoint-should-override-integration-convert-body-data-to-strings"
        className="mt-2"
        title="Should override integration convert body data to strings"
        onChange={setShouldOverrideIntegrationConvertBodyDataToString}
        checked={shouldOverrideIntegrationConvertBodyDataToString}
      />
      {protocol == APIProtocols.SOAP && (
        <>
          <CheckboxHeader
            dataTestID="field-api-endpoint-soap-does-request-use-body-templating-engine"
            className="mt-2"
            title="(SOAP) Does request use body templating engine"
            onChange={setSoapDoesRequestUseBodyTemplatingEngine}
            checked={soapDoesRequestUseBodyTemplatingEngine}
            helpText="Select this to enable advanced XML request body generation using the Genshi templating engine, which is useful for creating complex SOAP requests where certain fields or sections may be conditionally included"
          />
          {method == HTTPMethod.POST && (
            <CheckboxHeader
              dataTestID="field-api-endpoint-request-xml-character-escape-override"
              title="(SOAP) Escape XML characters"
              helpText="Select if we should escape XML character for SOAP POST requests. Should be un-selected if you are manually creating XML text in a Blueprint."
              onChange={setSoapShouldXmlRequestEscapeCharacters}
              checked={soapShouldXmlRequestEscapeCharacters}
              className="mt-2"
            />
          )}
        </>
      )}
    </AccordionCard>
  );
};

export default AdvancedRequestConfigurationSetupOptions;

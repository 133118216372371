import Cookies from "universal-cookie";
import { User } from "../models/Entities";
import { SEC_IN_YEAR, SEC_IN_WEEK } from "../constants";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { CSRF_COOKIE_NAME } from "../hooks/useFetchCSRFToken";
import { History, navigateHelper, LOGIN_PATH } from "../router/RouterUtils";
import { PydanticJsonSchemas } from "../components/integrations/versioned-components/types";

export interface UserSuccessData {
  token: string;
  user: User;
  created: boolean;
}
export interface FormErrorData {
  [key: string]: string[];
}

export interface MultipleFormErrorData {
  [index: number]: FormErrorData;
}

export interface PaginatedAPIResponse<T> {
  next: string | null;
  previous: string | null;
  results: T[];
}

export const apiURLForPath = (path: string): string => {
  let baseURL = "";
  switch (process.env.REACT_APP_MERGE_ENV) {
    case "PRODUCTION":
      baseURL = "https://api.merge.dev/api";
      break;
    case "EU-TENANT":
      baseURL = "https://api-eu.merge.dev/api";
      break;
    case "AP-TENANT":
      baseURL = "https://api-ap.merge.dev/api";
      break;
    case "DEVELOP":
      baseURL = "https://api-develop.merge.dev/api";
      break;
    case "LOCAL":
    default:
      baseURL = "http://localhost:8000/api";
  }

  if (path.charAt(0) !== "/") {
    return `${baseURL}/${path}`;
  }
  return baseURL + path;
};

export const fetchWithoutAuth = async ({
  path,
  method = "GET",
  headers = {},
  body,
  onResponse,
  onError,
}: {
  path: string;
  method: string;
  headers?: { [key: string]: string };
  body?: any;
  onResponse: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  const cookies = new Cookies();
  const csrfToken = cookies.get(CSRF_COOKIE_NAME);

  const updatedHeaders = {
    ...headers,
    "content-type": "application/json;charset=UTF-8",
    "x-csrftoken": csrfToken,
  };

  const url = apiURLForPath(path);

  fetch(url, {
    method,
    headers: updatedHeaders,
    body: body ? JSON.stringify(body) : null,
    credentials: "include",
  })
    .then((response: any) => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .then((data: any) => {
      onResponse(data);
    })
    .catch((error: any) => {
      if (onError) {
        onError(error && "json" in error ? error : undefined);
      }
    });
};

export const fetchWithAuth = async ({
  path,
  method = "GET",
  headers = {},
  body,
  onResponse,
  onError,
  expectJSON = true,
}: {
  path: string;
  method: string;
  headers?: { [key: string]: string };
  body?: any;
  expectJSON?: boolean;
  onResponse: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  let updatedHeaders: { [key: string]: string } | Headers = headers || {};
  let updatedBody: any = body && method !== "GET" ? JSON.stringify(body) : null;
  const cookies = new Cookies();
  const authToken = cookies.get("authentication_token");
  const csrfToken = cookies.get(CSRF_COOKIE_NAME);

  let hasFile = false;
  for (const key in body) {
    if (body[key] instanceof Blob) {
      hasFile = true;
    }
  }

  if (hasFile) {
    updatedHeaders = new Headers();
    for (const key in headers) {
      updatedHeaders.append(key, headers[key]);
    }

    updatedBody = null;
    if (body) {
      updatedBody = new FormData();
      for (const key in body) {
        updatedBody.append(key, body[key]);
      }
    }
    if (authToken) {
      updatedHeaders.append("Authorization", `Token ${authToken}`);
    }
    if (csrfToken) {
      updatedHeaders.append("X-Csrftoken", csrfToken);
    }
  } else {
    if (authToken) {
      updatedHeaders.Authorization = `Token ${authToken}`;
    }
    if (csrfToken) {
      updatedHeaders["X-Csrftoken"] = csrfToken;
    }
    updatedHeaders["Content-Type"] = "application/json;charset=UTF-8";
  }

  let url: string;
  try {
    // Check if we already have a valid absolute URL.
    // This can happen with pagination response links.
    new URL(path);
    url = path;
  } catch {
    url = apiURLForPath(path);
  }

  fetch(url, {
    method,
    headers: updatedHeaders,
    body: updatedBody,
    credentials: "include",
  })
    .then(async (response: any) => {
      if (response.ok) {
        if (response.status === 204) {
          // No content
          return {};
        }
        if (expectJSON) {
          return response.json();
        } else {
          return response;
        }
      }

      if (response.status === 403) {
        const data = await response.json();
        if (["Invalid token.", "Token has expired."].includes(data.detail)) {
          removeAuthTokenAndUserType();
          window.location.href = "/login";
          return;
        }
      }

      throw response;
    })
    .then((data: any) => onResponse(data))
    .catch((error: Response | undefined) => {
      console.error(error);

      if (onError) {
        onError(error && "json" in error ? error : undefined);
      }
    });
};

const getAuthToken = () => {
  const cookies = new Cookies();
  return cookies.get("authentication_token");
};

export const hasAuthToken = () => {
  return getAuthToken() != null;
};

export const getCookieDomainForEnv = () =>
  process.env.REACT_APP_MERGE_ENV === "PRODUCTION" && !process.env.REACT_APP_MERGE_PROD_FROM_LOCAL
    ? ".merge.dev"
    : undefined;

export const setAuthTokenAndUserType = (authToken: string, userType: string) => {
  const cookies = new Cookies();
  cookies.set("authentication_token", authToken, {
    maxAge: SEC_IN_WEEK,
    domain: getCookieDomainForEnv(),
    path: "/",
  });
  cookies.set("user_type", userType, { maxAge: SEC_IN_YEAR });
};

export const removeAuthTokenAndUserType = () => {
  const cookies = new Cookies();
  const cookieDomain = getCookieDomainForEnv();
  cookies.remove("authentication_token", { path: "/", domain: cookieDomain });
  cookies.remove("user_type", { domain: cookieDomain });
};

export const onLogout = (history: History, { onError }: { onError: () => void }) =>
  fetchWithAuth({
    path: "/users/logout",
    method: "POST",
    onResponse: () => {
      removeAuthTokenAndUserType();
      navigateHelper(history, LOGIN_PATH, false);
    },
    onError: () => {
      removeAuthTokenAndUserType();
      onError();
    },
  });

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const fetchPydanticJsonSchemas = async (apiKey: string): Promise<PydanticJsonSchemas> => {
  const url = apiURLForPath("integrations/versioning/pydantic-model-json-schemas");

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data as PydanticJsonSchemas;
  } catch (error) {
    console.error("Error fetching JSON schemas:", error);
    throw error;
  }
};

export const fetchPydanticExpandedJsonSchemas = async (
  apiKey: string
): Promise<PydanticJsonSchemas> => {
  const url = apiURLForPath("integrations/versioning/pydantic-model-expanded-json-schemas");

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data as PydanticJsonSchemas;
  } catch (error) {
    console.error("Error fetching JSON schemas:", error);
    throw error;
  }
};

export const fetchCurrentUser = (setUser: (response: any) => void) => {
  fetchWithAuth({
    path: "/users/me",
    method: "GET",
    onResponse: (data: User) => {
      const cookies = new Cookies();
      cookies.set("user_type", data.type, {
        maxAge: SEC_IN_YEAR,
        secure: true,
      });
      setUser(data);
    },
  });
};

export const API_DOMAIN = (() => {
  switch (process.env.REACT_APP_MERGE_ENV) {
    case "PRODUCTION":
      const base_url = process.env.REACT_APP_BASE_API_URL;
      return base_url;
    case "SANDBOX":
      return "https://api-sandbox.merge.dev";
    case "DEVELOP":
      return "https://api-develop.merge.dev";
    case "LOCAL":
    default:
      return "http://localhost:8000";
  }
})();

export const getTenantConfigForMergeLink = (): {} | { tenantConfig: { apiBaseURL: string } } => {
  return {
    tenantConfig: {
      apiBaseURL: API_DOMAIN,
    },
  };
};

import { CloudMoon, Frown } from "lucide-react";
import IntegrationBuilderEmptyState from "../../../integration-builder/shared/IntegrationBuilderEmptyState";

interface AlertDashboardEmptyTableProps {
  columnCount: number; // To span across columns
  usingSearchFilters: boolean;
}

const AlertDashboardEmptyTable = ({
  columnCount,
  usingSearchFilters,
}: AlertDashboardEmptyTableProps) => {
  const content = usingSearchFilters
    ? {
        icon: Frown,
        heading: "No results found",
        subtext: "Clear your filters or adjust them to find results",
      }
    : {
        icon: CloudMoon,
        heading: "No alerts",
        subtext: "Go take a break!",
      };

  return (
    <IntegrationBuilderEmptyState
      title={content.heading}
      subtitle={content.subtext}
      numberOfColumns={columnCount}
      withIcon={true}
      icon={content.icon}
    />
  );
};

export default AlertDashboardEmptyTable;

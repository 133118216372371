import { useEffect, useState } from "react";
import { APICategory } from "../../../../../models/Entities";
import { ALL_CATEGORIES } from "../../../../integrations/dashboard/hooks/useLoadPaginatedIntegrations";
import EmptyStateWrapper from "../../../../shared/layout/EmptyStateWrapper";
import MergeTable from "../../../../shared/MergeTable";
import useAutomatedModifiedAtFilters from "../../hooks/useAutomatedModifiedAtFilters";
import { AutomatedModifiedAtFilterDetails } from "../../types";
import AutomatedModifiedAtFiltersTableContent from "./AutomatedModifiedAtFiltersTableContent";
import AutomatedModifiedAtFiltersTableHeader from "./AutomatedModifiedAtFiltersTableHeader";
import AMFAvailabilityDialog from "./AMFAvailabilityDialog";

interface AutomatedModifiedAtFiltersTableProps {
  integrationID: string;
}

const TABLE_HEADER_COLUMNS = [
  "Model",
  "Category",
  "Blueprints",
  "LinkedAccountConditions",
  "Existing filters",
];

const AutomatedModifiedAtFiltersTable = ({
  integrationID,
}: AutomatedModifiedAtFiltersTableProps) => {
  const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
  const [isEnableDialogOpen, setIsEnableDialogOpen] = useState(false);
  const [category, setCategory] = useState<APICategory | "all">(ALL_CATEGORIES);
  const [amfModelsDetailsToDisplay, setAmfModelsDetailsToDisplay] = useState<
    AutomatedModifiedAtFilterDetails[]
  >([]);

  const {
    isLoadingFilters,
    loadAllAutomatedModifiedAtFilters,
    amfModelsDetails,
    enableAllFilters,
    disableAllFilters,
    areAllFiltersEnabled,
    setAreAllFiltersEnabled,
  } = useAutomatedModifiedAtFilters(integrationID);

  useEffect(() => {
    loadAllAutomatedModifiedAtFilters();
  }, []);

  useEffect(() => {
    if (category === "all") {
      setAmfModelsDetailsToDisplay(amfModelsDetails);
      return;
    }
    setAmfModelsDetailsToDisplay(
      amfModelsDetails.filter((amfModelDetails) => amfModelDetails.amf_model.category === category)
    );
  }, [category, amfModelsDetails]);

  if (isLoadingFilters) {
    return <EmptyStateWrapper isSpinner />;
  }

  return (
    <div>
      {amfModelsDetails.length > 0 && (
        <AutomatedModifiedAtFiltersTableHeader
          className="mb-6"
          setCategory={setCategory}
          category={category}
          areAllFiltersEnabled={areAllFiltersEnabled}
          setIsDisableDialogOpen={setIsDisableDialogOpen}
          setIsEnableDialogOpen={setIsEnableDialogOpen}
        />
      )}
      <MergeTable
        hover={false}
        hasMarginBottom={false}
        hasMorePaddingOnFirstElement
        className="mb-6"
        header={
          <>
            {TABLE_HEADER_COLUMNS.map((header) => (
              <th key={header} scope="col">
                {header}
              </th>
            ))}
          </>
        }
        content={
          <AutomatedModifiedAtFiltersTableContent
            integrationID={integrationID}
            filteredAmfDetails={amfModelsDetailsToDisplay}
            amfDetails={amfModelsDetails}
            numTableColumns={TABLE_HEADER_COLUMNS.length}
          />
        }
      />
      <AMFAvailabilityDialog
        isOpen={isDisableDialogOpen}
        setIsOpen={setIsDisableDialogOpen}
        onPrimaryButtonClick={disableAllFilters}
        setAreFiltersEnabled={setAreAllFiltersEnabled}
        isDisableDialog
      />
      <AMFAvailabilityDialog
        isOpen={isEnableDialogOpen}
        setIsOpen={setIsEnableDialogOpen}
        onPrimaryButtonClick={enableAllFilters}
        setAreFiltersEnabled={setAreAllFiltersEnabled}
        isDisableDialog={false}
      />
    </div>
  );
};

export default AutomatedModifiedAtFiltersTable;

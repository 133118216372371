import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import { useState } from "react";
import {
  StagedComponent,
  BlueprintAdvancedConfigurationsType,
  PublishModalScreenType,
  PublishIntegrationVersionRequestBodyType,
} from "../types";
import PublishModalConfirmationScreen from "./PublishModalConfirmationScreen";
import PublishModalContextScreen from "./PublishModalContextScreen";
import PublishModalConfigurationScreen from "./PublishModalConfigurationScreen";
import { ChevronLeft, ChevronRight } from "lucide-react";

type Props = {
  open: boolean;
  onPublish: ({
    ticket,
    description,
    blueprint_advanced_configurations,
  }: PublishIntegrationVersionRequestBodyType) => void;
  onClose: () => void;
  stagedChanges: Record<string, StagedComponent[]> | undefined;
  integrationID: string;
  overrideTicket?: string;
  overrideComment?: string;
};

const PublishModulePublishModal = ({
  open,
  onPublish,
  onClose,
  stagedChanges,
  integrationID,
  overrideTicket,
  overrideComment,
}: Props) => {
  const [screen, setScreen] = useState<PublishModalScreenType>(PublishModalScreenType.CONTEXT);
  const [ticket, setTicket] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  const [blueprintAdvancedConfigurations, setBlueprintAdvancedConfigurations] = useState<
    Record<string, BlueprintAdvancedConfigurationsType> | undefined
  >();
  const [
    copyOfBlueprintAdvancedConfigurations,
    setCopyOfBlueprintAdvancedConfigurations,
  ] = useState<Record<string, BlueprintAdvancedConfigurationsType> | undefined>();

  return (
    <Dialog
      title="Publish staged versions"
      variant="lg"
      open={open}
      onClose={onClose}
      primaryButtonVariant={
        screen === PublishModalScreenType.CONFIRMATION
          ? ButtonVariant.PrimaryCharcoal
          : ButtonVariant.SecondaryCharcoal
      }
      primaryButtonText={
        screen === PublishModalScreenType.CONFIRMATION
          ? "Publish"
          : screen === PublishModalScreenType.CONFIGURATION
          ? "Save"
          : "Next"
      }
      onPrimaryButtonClick={
        screen === PublishModalScreenType.CONFIRMATION
          ? () => {
              const publishBody: PublishIntegrationVersionRequestBodyType = {
                ticket: ticket,
                description: description,
                blueprint_advanced_configurations: blueprintAdvancedConfigurations,
              };

              if (overrideTicket) publishBody.override_ticket = overrideTicket;
              if (overrideComment) publishBody.override_comment = overrideComment;

              onPublish(publishBody);
            }
          : screen === PublishModalScreenType.CONFIGURATION
          ? () => {
              setBlueprintAdvancedConfigurations(copyOfBlueprintAdvancedConfigurations);
              setScreen(PublishModalScreenType.CONFIRMATION);
            }
          : () => setScreen(PublishModalScreenType.CONFIRMATION)
      }
      secondaryButtonVariant={
        screen === PublishModalScreenType.CONFIRMATION || screen === PublishModalScreenType.CONTEXT
          ? ButtonVariant.SecondaryCharcoal
          : ButtonVariant.TextBlack
      }
      secondaryButtonText={
        screen === PublishModalScreenType.CONFIRMATION
          ? "Previous"
          : screen === PublishModalScreenType.CONFIGURATION
          ? "Back"
          : "Cancel"
      }
      onSecondaryButtonClick={
        screen === PublishModalScreenType.CONFIRMATION
          ? () => setScreen(PublishModalScreenType.CONTEXT)
          : screen === PublishModalScreenType.CONFIGURATION
          ? () => setScreen(PublishModalScreenType.CONFIRMATION)
          : onClose
      }
      secondaryButtonLeftIcon={
        screen === PublishModalScreenType.CONTEXT ? undefined : <ChevronLeft size={16} />
      }
      primaryButtonRightIcon={
        screen === PublishModalScreenType.CONTEXT ? <ChevronRight size={16} /> : undefined
      }
    >
      <div className="h-80">
        {screen === PublishModalScreenType.CONTEXT ? (
          <PublishModalContextScreen
            setTicket={setTicket}
            ticket={ticket}
            setDescription={setDescription}
            description={description}
          />
        ) : screen === PublishModalScreenType.CONFIGURATION ? (
          <PublishModalConfigurationScreen
            setCopyOfBlueprintAdvancedConfigurations={setCopyOfBlueprintAdvancedConfigurations}
            copyOfBlueprintAdvancedConfigurations={copyOfBlueprintAdvancedConfigurations}
            stagedChanges={stagedChanges}
            integrationID={integrationID}
          />
        ) : (
          <PublishModalConfirmationScreen
            setScreen={setScreen}
            stagedChanges={stagedChanges}
            blueprintAdvancedConfigurations={blueprintAdvancedConfigurations}
            setCopyOfBlueprintAdvancedConfigurations={setCopyOfBlueprintAdvancedConfigurations}
          />
        )}
      </div>
    </Dialog>
  );
};

export default PublishModulePublishModal;

// import { Col, Row } from "react-bootstrap";
import { Accordion, Card, Text } from "@merge-api/merge-javascript-shared";
import PublishModuleCanaryTestingPrePublishInfo from "./CanaryTesting/PublishModuleCanaryTestingPrePublishInfo";
import { usePublishModuleContext } from "./context/PublishModuleContext";
import StagedComponentContextProvider from "./context/StagedComponentContextProvider";
import CanaryTestingStagedComponent from "./CanaryTesting/CanaryTestingStagedComponent";
import PublishModuleCanaryTestingPostPublishInfo from "./CanaryTesting/PublishModuleCanaryTestingPostPublishInfo";
import CanaryTestingPublishResults from "./CanaryTesting/CanaryTestingPublishResults";

const PublishModulePublishCard = () => {
  const { publishModuleInfo, integrationID, canaryTestInfo } = usePublishModuleContext();

  const isIntegrationInCanaryTesting = canaryTestInfo?.is_integration_in_canary_testing;
  // const isCanaryTestingFinished = canaryTestInfo?.is_canary_testing_finished ?? false;
  const numStagedComponents = publishModuleInfo
    ? Object.values(publishModuleInfo.staged_changes).reduce((sum, array) => sum + array.length, 0)
    : 0;

  const allStagedComponents = publishModuleInfo
    ? Object.values(publishModuleInfo.staged_changes).flatMap((array) => array)
    : [];

  return (
    <div>
      <Card className="p-4 divide-solid divide-gray-10">
        {!isIntegrationInCanaryTesting ? (
          <PublishModuleCanaryTestingPrePublishInfo />
        ) : (
          <>
            <PublishModuleCanaryTestingPostPublishInfo />
            {canaryTestInfo.is_canary_testing_finished && <CanaryTestingPublishResults />}
          </>
        )}
        {isIntegrationInCanaryTesting && (
          <Accordion
            title={
              <div>
                <Text variant="h6">{numStagedComponents} Staged components</Text>
              </div>
            }
            className="rounded-none pt-4 border-t"
            defaultExpanded={false}
            chevronOrientation="right"
            variant="none"
          >
            <Card variant="none" className="divide-y divide-solid divide-gray-10">
              {allStagedComponents.map((stagedComponent, index) => (
                <StagedComponentContextProvider
                  stagedComponent={stagedComponent}
                  integrationID={integrationID}
                >
                  <CanaryTestingStagedComponent
                    lastComponent={index === allStagedComponents.length - 1}
                  />
                </StagedComponentContextProvider>
              ))}
            </Card>
          </Accordion>
        )}
      </Card>
    </div>
  );
};

export default PublishModulePublishCard;

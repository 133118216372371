import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FOLDER_NAMES } from "../../../constants";
import { Integration } from "../../../models/Entities";
import { Button, ButtonVariant, Tooltip } from "@merge-api/merge-javascript-shared";
import {
  getBlueprintEditorPath,
  navigateToBlueprintsSubtab,
  navigateToTestSuitesTable,
} from "../../../router/RouterUtils";
import IntegrationEditorTopControlPanelAction from "./IntegrationEditorTopControlPanelAction";
import { ChevronRight } from "lucide-react";
import { AvatarSize, IntegrationAvatar } from "../../shared/MergeAvatars";
import useBlueprintContext from "../../blueprint-editor/context/useBlueprintContext";
import PublishStateBadge from "../../shared/PublishStateBadge";
import IntegrationEditorTopControlComboButton from "./IntegrationEditorTopControlPanelComboButton";
import { IntegrationEditorTopControlPanelActionProps } from "../../../models/Blueprints";

type Props = {
  actions: IntegrationEditorTopControlPanelActionProps[];
  publishedStateActions: IntegrationEditorTopControlPanelActionProps[];
  integration: Integration;
  path: string[];
  isArchived?: boolean;
};

const IntegrationEditorTopControlPanel = ({
  actions,
  integration,
  path,
  publishedStateActions,
  isArchived,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { blueprint, isIntegrationInCanaryTesting } = useBlueprintContext();
  const blueprintVersion = blueprint?.version;

  const isOnBlueprintEditorPage =
    blueprintVersion &&
    location.pathname === getBlueprintEditorPath(integration.id, blueprintVersion.id);

  return (
    <div className="bg-white h-16 w-full px-4 py-3 flex align-items-center justify-between flex-[1_0_auto] border-b border-b-gray-0">
      <div className="flex items-center space-x-1">
        <IntegrationAvatar integration={integration} size={AvatarSize.xs} />
        <Button
          variant={ButtonVariant.TextBlack}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            navigateToBlueprintsSubtab(history, integration.id, event.metaKey || event.ctrlKey)
          }
        >
          {integration.name}
        </Button>
        {path.map((folder, index) => (
          <React.Fragment key={folder}>
            <ChevronRight size={12} className="text-gray-60" />
            {index < 1 ? (
              <Button
                variant={ButtonVariant.TextBlack}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  if (folder === FOLDER_NAMES.MAPPING_TEST_FOLDER) {
                    navigateToTestSuitesTable(
                      history,
                      integration.id,
                      event.metaKey || event.ctrlKey
                    );
                  } else {
                    navigateToBlueprintsSubtab(
                      history,
                      integration.id,
                      event.metaKey || event.ctrlKey
                    );
                  }
                }}
              >
                <div className="max-w-[198px] truncate overflow-hidden text-ellipsis">{folder}</div>
              </Button>
            ) : (
              <Tooltip title={folder}>
                <div className="font-semibold text-black max-w-[400px] truncate overflow-hidden text-ellipsis py-2 px-4">
                  {folder}
                </div>
              </Tooltip>
            )}
          </React.Fragment>
        ))}

        {isOnBlueprintEditorPage && (
          <PublishStateBadge publishState={blueprintVersion.publish_state} />
        )}
      </div>
      <div className="flex items-center justify-end space-x-3">
        {actions
          .filter((action) => !action?.isHidden)
          .map((action) => (
            <IntegrationEditorTopControlPanelAction key={action.text} {...action} />
          ))}
        <IntegrationEditorTopControlComboButton
          isIntegrationInCanaryTesting={isIntegrationInCanaryTesting}
          publishedStateActions={publishedStateActions}
          blueprintVersion={blueprintVersion}
          isArchived={isArchived}
        />
      </div>
    </div>
  );
};

export default IntegrationEditorTopControlPanel;

import { useEffect, useState } from "react";

type Props = {
  initialTimeInSeconds: number | undefined | null;
};

const CountdownTimeRemaining = ({ initialTimeInSeconds: initialTimeInSeconds }: Props) => {
  const [timeRemaining, setTimeRemaining] = useState(initialTimeInSeconds ?? 0);

  useEffect(() => {
    if (timeRemaining <= 0) return; // Stop countdown at 0

    const interval = setInterval(() => {
      setTimeRemaining((prev) => Math.max(0, prev - 1));
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining]);

  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return <div>{`${formatTime(timeRemaining)}`}</div>;
};

export default CountdownTimeRemaining;

import { ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onPrimaryButtonClick: () => void;
  setAreFiltersEnabled: (value: boolean) => void;
  isDisableDialog: boolean;
}

const DISABLE_DIALOG_SUBTITLE =
  "Disabling this means existing Linked Accounts that have modified_at filtering enabled for these models will no longer be correctly applied. Please ensure customers are okay with a non-working S2 filter.";
const ENABLE_DIALOG_SUBTITLE =
  "Enabling this means these modified_at filters will become available for all Linked Accounts within this integration. Please be cautious as this may cause unexpected behavior if the filters are not correctly set up.";

const AMFAvailabilityDialog = ({
  isOpen,
  setIsOpen,
  onPrimaryButtonClick,
  setAreFiltersEnabled,
  isDisableDialog,
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      title="Are you sure?"
      onClose={() => {
        setIsOpen(false);
      }}
      primaryButtonVariant={
        isDisableDialog ? ButtonVariant.DangerFilled : ButtonVariant.PrimaryCharcoal
      }
      onPrimaryButtonClick={() => {
        setIsOpen(false);
        setAreFiltersEnabled(!isDisableDialog);
        onPrimaryButtonClick();
      }}
      primaryButtonText={`${isDisableDialog ? "Disable" : "Enable"} filters`}
    >
      <Text>{isDisableDialog ? DISABLE_DIALOG_SUBTITLE : ENABLE_DIALOG_SUBTITLE}</Text>
    </Dialog>
  );
};

export default AMFAvailabilityDialog;

import {
  ALERT_CATEGORIES,
  AlertSearchFilterValue,
  AlertSearchQueryParams,
  AlertStatus,
  IntegrationTier,
  AlertSearchFilterConfig,
  MetricRuleNamesDisplay,
  MetricRuleNames,
  SearchMetadata,
  SEARCH_METADATA_CATEGORIES,
} from "./constants";
import {
  TypeaheadComparatorClusterOption,
  TypeaheadComparatorEnum,
} from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";

export const calculateDeviation = (
  metricValue: number | undefined,
  baselineValue: number | undefined
): number | null => {
  if (typeof metricValue !== "number" || typeof baselineValue !== "number") {
    return null;
  }
  return normalizePercentage(metricValue - baselineValue);
};

export const normalizePercentage = (metricValue: number): number => {
  const absValue = Math.abs(metricValue);
  if (absValue > 1) {
    return Math.floor(absValue * 100) / 100;
  }
  return Math.floor(absValue * 10000) / 100;
};

export const getStatus = (
  resolvedAt: string | Date | undefined,
  silencedAt: string | Date | undefined
): AlertStatus => {
  if (resolvedAt) {
    return AlertStatus.RESOLVED;
  } else if (silencedAt) {
    return AlertStatus.SILENCED;
  } else {
    return AlertStatus.ACTIVE;
  }
};

export const getBadgeColor = (status: AlertStatus): BaseColor => {
  switch (status) {
    case AlertStatus.RESOLVED:
      return "teal";
    case AlertStatus.SILENCED:
      return "gray";
    case AlertStatus.ACTIVE:
    default:
      return "blue";
  }
};

export const getIntegrationTier = (integrationTier?: string): IntegrationTier => {
  if (!integrationTier) return IntegrationTier.TIER_4;
  const normalizedIntegrationTier = integrationTier
    .toUpperCase()
    .replace(" ", "_") as keyof typeof IntegrationTier;
  return Object.values(IntegrationTier).includes(IntegrationTier[normalizedIntegrationTier])
    ? IntegrationTier[normalizedIntegrationTier]
    : IntegrationTier.TIER_4;
};

export const getMetricName = (metricName: string): string => {
  const metricEnumKey = metricName as MetricRuleNames;
  return MetricRuleNamesDisplay[metricEnumKey] || "Unknown Metric";
};

export const orderErrorCodes = (errorCodes?: Record<string, number>): Map<string, number> => {
  if (!errorCodes) return new Map();

  const entries = Object.entries(errorCodes).filter(([code]) => code !== "200");

  // Split into regular errors and blueprint restart errors
  const restartBlueprintErrors = entries.filter(([code]) => {
    const codeNum = parseInt(code);
    return codeNum === 640 || codeNum === 643; // 640 and 643 are the only blueprint restart errors
  });
  const otherErrors = entries.filter(([code]) => {
    return !restartBlueprintErrors.some(([restartCode]) => restartCode === code);
  });

  // Sort each group by count descending
  const sortedOtherErrors = otherErrors.sort(([, countA], [, countB]) => countB - countA);
  const sortedRestartBlueprintErrors = restartBlueprintErrors.sort(
    ([, countA], [, countB]) => countB - countA
  );

  return new Map([...sortedOtherErrors, ...sortedRestartBlueprintErrors]);
};

const constructFilter = (
  metadata: SearchMetadata[],
  valueName: AlertSearchFilterValue,
  label: string,
  useIdAsLabel: boolean = false
): TypeaheadComparatorClusterOption => {
  return {
    value: valueName,
    label,
    comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
    target: {
      targetOptions: metadata.map((item) => ({
        value: item.id,
        label: useIdAsLabel ? item.id : item.name || "",
      })),
    },
  };
};

export const constructCategorySpecificAlertFilters = (
  category: ALERT_CATEGORIES,
  searchMetadata: Record<SEARCH_METADATA_CATEGORIES, SearchMetadata[]>
): TypeaheadComparatorClusterOption[] => {
  const alertCategories = Object.values(ALERT_CATEGORIES);
  const categoryIndex = alertCategories.indexOf(category as ALERT_CATEGORIES);
  const filterCategories = alertCategories.slice(0, categoryIndex + 1);

  const filterConfigs: AlertSearchFilterConfig[] = [
    {
      category: SEARCH_METADATA_CATEGORIES.INTEGRATION,
      value: "integration_id",
      displayName: "Integration Name",
    },
    {
      category: SEARCH_METADATA_CATEGORIES.ORGANIZATION,
      value: "organization_id",
      displayName: "Organization Name",
    },
    {
      category: SEARCH_METADATA_CATEGORIES.LINKED_ACCOUNT,
      value: "linked_account_id",
      displayName: "Linked Account ID",
    },
  ];

  const categoryFilters = filterConfigs
    .filter((config) => filterCategories.includes((config.category as unknown) as ALERT_CATEGORIES))
    .map((config) =>
      constructFilter(
        searchMetadata[config.category] || [],
        config.value,
        config.displayName,
        config.category === SEARCH_METADATA_CATEGORIES.LINKED_ACCOUNT
      )
    );

  const blueprintFilter = constructFilter(
    searchMetadata[SEARCH_METADATA_CATEGORIES.BLUEPRINT] || [],
    "blueprint_id",
    "Blueprint ID",
    true
  );
  categoryFilters.push(blueprintFilter);

  return categoryFilters;
};

export const constructAlertQueryParamsFromSearchFilters = (
  searchFilters: TypeaheadComparatorClusterOption[],
  showResolved: boolean,
  showSilenced: boolean
): AlertSearchQueryParams => {
  const queryParams: AlertSearchQueryParams = {};
  searchFilters.forEach((filter) => {
    const filterKey = filter.value;
    const filterValues = filter.target?.targetOptions?.map((option) => option.value);

    if (filterKey && filterValues?.length) {
      queryParams[filterKey as keyof AlertSearchQueryParams] = filterValues.join(",") as any; // Comma separate if there are more than one.
    }
  });

  queryParams["show_resolved"] = showResolved;
  queryParams["show_silenced"] = showSilenced;

  return queryParams;
};

export const constructAlertCountQueryParams = (
  showResolved: boolean,
  showSilenced: boolean
): AlertSearchQueryParams => {
  const queryParams: AlertSearchQueryParams = {};
  queryParams["show_resolved"] = showResolved;
  queryParams["show_silenced"] = showSilenced;
  return queryParams;
};

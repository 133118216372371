import { Button, ButtonVariant, Card, Text, TextField } from "@merge-api/merge-javascript-shared";
import HeaderBase from "../../shared/HeaderBase";
import { WandSparkles } from "lucide-react";
import { useEffect, useState } from "react";
import useLoadEndpointCollectionRun from "../hooks/useLoadEndpointCollectionRun";
import {
  EndpointCollectionRun,
  EndpointCollectionRunNotificationStatus,
  EndpointCollectionRunStatus,
} from "../../utils/Entities";
import useCollectEndpoints from "../hooks/useCollectEndpoints";
import EndpointCollectionRunCard from "./EndpointCollectionRunCard";
import CardHeader from "../../shared/CardHeader";
import { showErrorToast } from "../../../shared/Toasts";
import { ChecklistStatusValue } from "../../../../constants";

interface AIInitializiationProps {
  integrationID: string;
  apiDocumentationURL: string;
  setApiDocumentationURL: React.Dispatch<React.SetStateAction<string>>;
  aiSearchConfigurationStatus: ChecklistStatusValue;
  setAISearchConfigurationStatus: (prev: ChecklistStatusValue) => void;
}

const AIInitializiation = ({
  integrationID,
  apiDocumentationURL,
  setApiDocumentationURL,
  aiSearchConfigurationStatus,
  setAISearchConfigurationStatus,
}: AIInitializiationProps) => {
  // state
  const [hasTextFieldError, setHasTextFieldError] = useState<boolean>(false);
  const [isParsing, setIsParsing] = useState<boolean>(
    aiSearchConfigurationStatus === ChecklistStatusValue.PENDING
  );
  const [endpointCollectionRun, setEndpointCollectionRun] = useState<
    EndpointCollectionRun | undefined
  >(undefined);
  const [hasLoadedEndpointCollectionRun, setHasLoadedEndpointCollectionRun] = useState<boolean>(
    false
  );

  // hooks
  const {
    fetchEndpointCollectionRun,
    pollEndpointCollectionRun,
    stopPollingEndpointCollectionRun,
  } = useLoadEndpointCollectionRun({
    integrationID,
    setEndpointCollectionRun,
    setHasLoadedEndpointCollectionRun,
  });
  const { collectEndpoints } = useCollectEndpoints({ integrationID });

  // make an initial call to fetch the current endpoint collection run
  // stop the polling when the component unmounts
  useEffect(() => {
    fetchEndpointCollectionRun();
    return () => {
      stopPollingEndpointCollectionRun();
    };
  }, []);

  // if the endpoint collection run is ongoing, poll for the latest endpoint collection run
  // otherwise, stop any ongoing polling
  // set the AI search configuration status based on the endpoint collection run status
  useEffect(() => {
    if (!hasLoadedEndpointCollectionRun) return;
    if (endpointCollectionRun?.api_documentation_url) {
      setApiDocumentationURL(endpointCollectionRun.api_documentation_url);
    }
    if (
      endpointCollectionRun?.status === EndpointCollectionRunStatus.RUNNING ||
      endpointCollectionRun?.status === EndpointCollectionRunStatus.PENDING
    ) {
      setIsParsing(true);
      setAISearchConfigurationStatus(ChecklistStatusValue.PENDING);
      pollEndpointCollectionRun();
    } else {
      setIsParsing(false);
      stopPollingEndpointCollectionRun();
      if (
        endpointCollectionRun?.status === EndpointCollectionRunStatus.ABORTED ||
        endpointCollectionRun?.status === EndpointCollectionRunStatus.FAILURE
      ) {
        setAISearchConfigurationStatus(ChecklistStatusValue.FAILURE);
      } else if (endpointCollectionRun?.status === EndpointCollectionRunStatus.SUCCESS) {
        setAISearchConfigurationStatus(ChecklistStatusValue.IMPLEMENTED);
      }
    }
  }, [endpointCollectionRun, hasLoadedEndpointCollectionRun]);

  // handlers
  const onSuccessParse = () => {
    setAISearchConfigurationStatus(ChecklistStatusValue.PENDING);
    pollEndpointCollectionRun();
  };

  const onErrorParse = () => {
    showErrorToast("Failed to parse the documentation. Please try again.");
    setIsParsing(false);
  };

  const onClickParseDocumentation = () => {
    if (apiDocumentationURL === "") {
      setHasTextFieldError(true);
      return;
    }

    setHasTextFieldError(false);
    setIsParsing(true);
    collectEndpoints(apiDocumentationURL, onSuccessParse, onErrorParse);
  };

  const onTextChange = (value: string) => {
    setApiDocumentationURL(value);
    setHasTextFieldError(false);
  };

  return (
    <Card className="pt-4 mt-6 px-5 pb-5 bg-white mb-6 min-w-fit" variant="shadow">
      <CardHeader title="Initialization" />
      <div className="space-y-4">
        <Text>
          Set up AI features with the integration’s API documentation URL. Our AI will crawl through
          documentation to find all available endpoints and details about the integration.
        </Text>
        <div>
          <HeaderBase title={"API Documentation URL"} />
          <div className="flex flex-row items-start w-full space-x-4">
            <TextField
              placeholder="Documentation URL"
              className="w-full"
              value={apiDocumentationURL}
              onChange={(e) => onTextChange(e.target.value)}
              error={hasTextFieldError}
              errorText="Empty input! Add a URL."
              disabled={isParsing}
            />
            <Button
              variant={ButtonVariant.PrimaryCharcoal}
              leftIcon={!isParsing ? <WandSparkles size={12} /> : undefined}
              disabled={isParsing}
              loading={isParsing}
              onClick={onClickParseDocumentation}
            >
              Parse documentation
            </Button>
          </div>
        </div>
        {endpointCollectionRun &&
          endpointCollectionRun.notification_status !==
            EndpointCollectionRunNotificationStatus.DISMISSED && (
            <EndpointCollectionRunCard
              integrationID={integrationID}
              endpointCollectionRun={endpointCollectionRun}
              setEndpointCollectionRun={setEndpointCollectionRun}
              fetchEndpointCollectionRun={fetchEndpointCollectionRun}
            />
          )}
      </div>
    </Card>
  );
};

export default AIInitializiation;

import { Link, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { ExternalLink } from "lucide-react";
import { getBlueprintEditorPath } from "../../../../router/RouterUtils";

interface AlertBlueprintCellProps {
  blueprintId: string;
  blueprintName?: string;
  integrationId: string;
  versionId: string;
}

const AlertBlueprintCell = ({
  blueprintId,
  blueprintName,
  integrationId,
  versionId,
}: AlertBlueprintCellProps) => {
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Link
        href={getBlueprintEditorPath(integrationId, versionId)}
        target="_blank"
        className="flex items-center gap-2"
      >
        <Tooltip title={blueprintName || blueprintId}>
          <Text variant="h6" className="max-w-[350px] truncate">
            {blueprintName || blueprintId}
          </Text>
        </Tooltip>
        <ExternalLink size={16} />
      </Link>
    </div>
  );
};

export default AlertBlueprintCell;

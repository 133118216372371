import CardHeader from "../../../shared/CardHeader";
import {
  AdminAPICategory,
  APICategory,
  Card,
  fullDisplayNameForAPICategory,
  UnreleasedAPICategory,
} from "@merge-api/merge-javascript-shared";
import {
  LinkingFlowStepPathIntegrationBuilder,
  LinkingFlowStepPathTypeEnums,
} from "../../../utils/Entities";
import { convertQueryValueToEnumKey } from "../../utils/helpers";
import { useEffect } from "react";
import { AuthType } from "../../../../../models/Entities";
import AdvancedStepPathFields from "./AdvancedStepPathFields";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";
import { Text } from "@merge-api/merge-javascript-shared";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";

enum CategoryOption {
  ALL = "All",
}

type CategorySelection = AdminAPICategory | CategoryOption.ALL;

interface ConfigureStepPathSectionProps {
  setRequestedStepPath: React.Dispatch<
    React.SetStateAction<LinkingFlowStepPathIntegrationBuilder | undefined>
  >;
  requestedStepPath: LinkingFlowStepPathIntegrationBuilder;
  authConfigType: AuthType | undefined;
}

const selectablePathTypeOptions = [
  LinkingFlowStepPathTypeEnums.PATH_TYPE_MANUAL_LINKING,
  LinkingFlowStepPathTypeEnums.PATH_TYPE_PARTNERSHIP,
];

const scraperPathTypeOptions = [
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER,
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER_MFA,
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER_SECURITY_QUESTION,
];

const ConfigureStepPathSection = ({
  setRequestedStepPath,
  requestedStepPath,
  authConfigType,
}: ConfigureStepPathSectionProps) => {
  const presetPathType = convertQueryValueToEnumKey("presetPathType", LinkingFlowStepPathTypeEnums);

  // hooks
  useEffect(() => {
    if (presetPathType) {
      setRequestedStepPath({
        ...requestedStepPath,
        path_type: presetPathType,
      });
    }
  }, [presetPathType]);

  // context
  const { integration } = useIntegrationBuilderContext();

  // const derived from state and props
  const integrationCategories = integration?.categories;
  const isMultiCategory = (integrationCategories?.length ?? 0) > 1;

  const ADMIN_API_CATEGORIES: AdminAPICategory[] = [
    ...Object.values(APICategory),
    ...Object.values(UnreleasedAPICategory),
  ];

  const availableCategories: AdminAPICategory[] = ADMIN_API_CATEGORIES.filter(
    (category) => !integrationCategories || integrationCategories.includes(category)
  );

  return (
    <Card className="pt-4 px-5 pb-5 bg-white mb-6 min-w-fit space-y-6">
      <CardHeader title="Configure step path" />
      <TypeaheadHeader
        dataTestID="field-step-path-path-type"
        title="Path type"
        options={
          presetPathType || scraperPathTypeOptions.includes(requestedStepPath.path_type)
            ? Object.values(LinkingFlowStepPathTypeEnums)
            : selectablePathTypeOptions
        }
        getOptionLabel={(option: LinkingFlowStepPathTypeEnums) => option}
        value={presetPathType || requestedStepPath.path_type}
        onChange={(
          _: any,
          selectedOption: LinkingFlowStepPathTypeEnums | LinkingFlowStepPathTypeEnums[] | null
        ) => {
          if (typeof selectedOption === "string") {
            setRequestedStepPath({
              ...requestedStepPath,
              path_type: selectedOption || LinkingFlowStepPathTypeEnums.PATH_TYPE_MANUAL_LINKING,
            });
          }
        }}
        renderOption={(option: LinkingFlowStepPathTypeEnums) => <Text variant="md">{option}</Text>}
        disabled={!!presetPathType || scraperPathTypeOptions.includes(requestedStepPath.path_type)}
        disableClearable={true}
      />
      {isMultiCategory && (
        <TypeaheadHeader
          title="Category"
          learnMoreText="Selecting 'All categories' will set the value to null in our database. This should be the default for all non-multi category integrations and all new Merge Link step paths."
          subtitle="Select which category you would like this step path to be associated with. Selecting 'All categories' will show this step path for all categories if it is active."
          options={[
            CategoryOption.ALL,
            ...(Object.values(availableCategories) as AdminAPICategory[]),
          ]}
          getOptionLabel={(option: AdminAPICategory | CategoryOption.ALL) =>
            option === CategoryOption.ALL ? "All categories" : fullDisplayNameForAPICategory(option)
          }
          value={requestedStepPath.category ?? CategoryOption.ALL}
          onChange={(_: any, selectedOption: CategorySelection | CategorySelection[] | null) => {
            if (Array.isArray(selectedOption)) {
              return;
            }
            if (selectedOption) {
              setRequestedStepPath({
                ...requestedStepPath,
                category: selectedOption === CategoryOption.ALL ? null : selectedOption,
              });
            }
          }}
          renderOption={(option: AdminAPICategory | CategoryOption.ALL) => (
            <Text variant="md">
              {option === CategoryOption.ALL
                ? "All categories"
                : fullDisplayNameForAPICategory(option)}
            </Text>
          )}
          disableClearable={true}
        />
      )}
      <AdvancedStepPathFields
        authConfigType={authConfigType}
        requestedStepPath={requestedStepPath}
        setRequestedStepPath={setRequestedStepPath}
      />
    </Card>
  );
};

export default ConfigureStepPathSection;

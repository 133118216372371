import { Link, Text } from "@merge-api/merge-javascript-shared";
import { Minus } from "lucide-react";
import { SelectiveSyncFilterSchema } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { getIntegrationBuilderSelectiveSyncFilterBuilderForId } from "../../../../../router/RouterUtils";

interface Props {
  integrationID: string;
  commonModelName: string;
  existing_filter: SelectiveSyncFilterSchema | null;
}

const AutomatedModifiedAtFiltersTableExistingFilterCell = ({
  integrationID,
  commonModelName,
  existing_filter,
}: Props) => {
  if (!existing_filter) {
    return <Minus size={16} className="text-gray-60" />;
  }

  return (
    <div className="flex flex-col">
      <Text>
        <Link
          href={getIntegrationBuilderSelectiveSyncFilterBuilderForId(
            integrationID,
            existing_filter.id
          )}
          target="_blank"
        >
          {`${commonModelName} modified_at`}
        </Link>
      </Text>
      <Text variant="sm" className="text-gray-60">
        This filter is overriding the automated filter
      </Text>
    </div>
  );
};

export default AutomatedModifiedAtFiltersTableExistingFilterCell;

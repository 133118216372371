import { useState } from "react";
import { Button } from "@merge-api/merge-javascript-shared";
import { Plus } from "lucide-react";
import { BlueprintParameterValueConstant, BlueprintSwitchStep } from "../../../models/Blueprints";
import { HeaderPretitle } from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import { ModifySwitchOptionType } from "../utils/BlueprintEditorUtils";
import InputFormField from "./InputFormField";
import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelSwitchForm = () => {
  const { selectedStep, updateSwitchStepOption } = useBlueprintContext();
  const step = selectedStep as BlueprintSwitchStep;
  const [newOption, setNewOption] = useState("");
  const [isCreatingNewOption, setIsCreatingNewOption] = useState(false);

  const { constant } = step.parameter_values.options as BlueprintParameterValueConstant;
  const options = (constant as Array<any>).sort();

  return (
    <form>
      <HeaderPretitle className="mt-3">Parameter</HeaderPretitle>
      <TypeaheadFormField
        title={"Value"}
        subtitle={"The value to compare."}
        valueKey={"value"}
        parameterType={"any"}
      />
      <HeaderPretitle className="mt-3">Options</HeaderPretitle>
      {options.map((option) => (
        <p key={option} className="mb-1 mt-3">
          <b>{option}</b>
        </p>
      ))}
      {isCreatingNewOption ? (
        <div>
          <InputFormField
            onChange={setNewOption}
            placeholder=""
            currentValue={newOption}
            title={"New Option"}
            subtitle={"Set the value for the switch option. This cannot be changed after creation."}
          />
          <Button
            onClick={() => {
              updateSwitchStepOption(step, newOption, ModifySwitchOptionType.ADD);
              setIsCreatingNewOption(false);
            }}
          >
            Add Option
          </Button>
        </div>
      ) : (
        <Button className="mt-1.5" size="sm" onClick={() => setIsCreatingNewOption(true)}>
          {" "}
          <Plus />{" "}
        </Button>
      )}
    </form>
  );
};

export default BlueprintEditorRightPanelSwitchForm;

import { fetchWithAuth } from "../../../../api-client/api_client";
import { HTTPMethod } from "../../../../models/HTTPMethods";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";

const useUnstageBlueprintVersions = () => {
  const unstageBlueprintVersions = (
    blueprintVersionIDs: string[],
    onResponse: () => void,
    setIsLoadingStagedComponents: (value: boolean) => void = () => {}
  ) => {
    setIsLoadingStagedComponents(true);
    fetchWithAuth({
      path: `/blueprints/versions/unstage-many`,
      method: HTTPMethod.POST,
      body: {
        blueprint_version_ids: blueprintVersionIDs,
      },
      onResponse: () => {
        showSuccessToast("Blueprints successfully unstaged");
        onResponse();
      },
      onError: () => {
        showErrorToast("Failed to unstage blueprints");
        setIsLoadingStagedComponents(false);
      },
    });
  };

  return { unstageBlueprintVersions };
};

export default useUnstageBlueprintVersions;

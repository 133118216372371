import { useEffect, useState } from "react";
import { Button } from "@merge-api/merge-javascript-shared";
import Editor from "react-simple-code-editor";
import {
  BlueprintParameterValueConstant,
  BlueprintParameterValueReturnValue,
  BlueprintParameterValue,
  BlueprintParameterValueNestedParameterValues,
  BlueprintParameterValueType,
  BlueprintRaiseExceptionStep,
} from "../../../models/Blueprints";
import useBlueprintContext from "../context/useBlueprintContext";
import { BlueprintAvailableParameter } from "../utils/BlueprintEditorUtils";

import max from "lodash/max";
import CustomFunctionTypeaheadFormField from "./custom-function/CustomFunctionTypeaheadFormField";
import { HeaderPretitle, SmallTextMutedParagraph } from "../../shared/text/MergeText";

const { highlight, languages } = require("prismjs");

const BlueprintEditorRightPanelRaiseExceptionForm = () => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintRaiseExceptionStep;

  const parameters = (step.parameter_values
    .parameters as BlueprintParameterValueNestedParameterValues).nested_parameter_values;
  const exception_text = (step.parameter_values.exception_text as BlueprintParameterValueConstant)
    .constant as string;
  const [unsavedExceptionText, setUnsavedExceptionText] = useState(exception_text);

  useEffect(() => {
    setUnsavedExceptionText(unsavedExceptionText);
  }, [selectedStep?.id]);

  const maxParameterVal = max([
    ...Object.keys(parameters).map((arg) => Number(arg.slice(1))),
    0,
  ]) as number;

  const updateParameters = (newParameters: {
    [key: string]: BlueprintParameterValue | undefined;
  }) =>
    updateStepParameterValue(step, "parameters", {
      nested_parameter_values: newParameters,
      value_type: BlueprintParameterValueType.nestedParameterValues,
    });

  const addArg = () =>
    updateParameters({
      ...parameters,
      ["x" + (maxParameterVal + 1)]: {
        step_id: "",
        return_schema_path: [],
        request_return_value_path: [],
        value_type: BlueprintParameterValueType.returnValue,
      },
    });

  const updateExistingParameter = (
    key: string,
    newValue: BlueprintParameterValueReturnValue | null
  ) => {
    updateParameters({ ...parameters, [key]: newValue ?? undefined });
  };

  const deleteParameter = (key: string) => {
    const { [key]: _, ...newParameters } = parameters;
    updateParameters(newParameters);
  };

  return (
    <form>
      <HeaderPretitle className="my-2">Arguments</HeaderPretitle>
      {Object.entries(parameters).map(([key, value]) => (
        <div key={key}>
          <CustomFunctionTypeaheadFormField
            onChange={(options: BlueprintAvailableParameter[]) =>
              updateExistingParameter(
                key,
                options[0]?.parameterValue as BlueprintParameterValueReturnValue | null
              )
            }
            currentParameterValue={value}
            onDelete={() => deleteParameter(key)}
            title={key}
            subtitle={"Set the value for the argument."}
          />
        </div>
      ))}

      <Button className="mt-0 mb-3 btn-block w-full" onClick={addArg}>
        Add New Parameter
      </Button>
      <hr />
      <HeaderPretitle className="my-2">Exception Text</HeaderPretitle>
      <div>
        <SmallTextMutedParagraph>
          Enter a string below. To show the value of x1 in the string, type {"{x1}"}.
        </SmallTextMutedParagraph>
        <Editor
          highlight={(code) => highlight(code, languages.markup, "markup")}
          value={unsavedExceptionText}
          onBlur={() => {
            updateStepParameterValue(step, "exception_text", {
              constant: unsavedExceptionText,
              value_type: BlueprintParameterValueType.constant,
            });
          }}
          onValueChange={(newText) => {
            setUnsavedExceptionText(newText);
          }}
          padding={10}
          style={{
            border: "1px solid #d2ddec",
            borderRadius: 8,
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            minHeight: "100px",
            overflow: "auto",
          }}
        />
      </div>
    </form>
  );
};

export default BlueprintEditorRightPanelRaiseExceptionForm;

import { useEffect, useState } from "react";
import { UserFacingFilterType } from "../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { fetchMappedFilterTypesForBlueprintVersion } from "../../../../../integration-builder/selective-sync-filters/components/shared/SelectiveSyncFilterBuilderAPIClient";
import { showWarningToast } from "../../../../../shared/Toasts";

interface Props {
  integrationID: string;
  blueprintVersionID: string;
}
/**
 * Custom hook to fetch and manage user-facing filter type options for selective sync.
 * This hook fetches the available filter types for a given integration and provides
 * them as an array of UserFacingFilterType objects.
 *
 * @param {Object} props - The props object.
 * @param {string} props.integrationID - The ID of the integration to fetch filter types for.
 * @param {string} props.blueprintVersionID - The ID of the blueprint version to fetch filter types for.
 * @returns {Object} An object containing the filterTypeOptions array.
 */

const useFetchUserFacingFilterTypeOptionsForBlueprintVersion = ({
  integrationID,
  blueprintVersionID,
}: Props) => {
  const [filterTypeOptions, setFilterTypeOptions] = useState<UserFacingFilterType[]>([]);

  // Function to fetch available filter types and convert them to map
  const fetchAvailableFilterTypesForBlueprintVersion = (blueprintVersionID: string) => {
    fetchMappedFilterTypesForBlueprintVersion({
      integrationID: integrationID,
      blueprintVersionID: blueprintVersionID,
      onSuccess: (response: Record<string, UserFacingFilterType>) => {
        setFilterTypeOptions(Object.values(response));
      },
      onError: () => {
        showWarningToast("Failed to load available selective sync filters");
      },
    });
  };

  // Fetch filter types during component load
  useEffect(() => {
    fetchAvailableFilterTypesForBlueprintVersion(blueprintVersionID);
  }, []);

  return {
    filterTypeOptions,
  };
};

export default useFetchUserFacingFilterTypeOptionsForBlueprintVersion;

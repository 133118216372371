import { Button, Link } from "@merge-api/merge-javascript-shared";
import MergeTable from "../../../../shared/MergeTable";
import TableSkeletonLoader from "../../../../shared/TableSkeletonLoader";
import Callout from "../../../shared/Callout";
import IntegrationBuilderHeader from "../../../shared/IntegrationBuilderHeader";
import {
  LinkingFlowStepPathIntegrationBuilder,
  AuthConfigIntegrationBuilder,
  LinkingFlowStepPathTableRowsIntegrationBuilder,
} from "../../../utils/Entities";
import LinkingFlowStepPathsTableRow from "./LinkingFlowStepPathsTableRow";
import { Calculator } from "lucide-react";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";
import { navigateToIntegrationBuilderAuthConfigTable } from "../../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { showSuccessToast } from "../../../../shared/Toasts";
import IntegrationBuilderEmptyState from "../../../shared/IntegrationBuilderEmptyState";
import { getTenantConfigForMergeLink } from "../../../../../api-client/api_client";

interface LinkingFlowStepPathsTableProps {
  authConfigs: AuthConfigIntegrationBuilder[] | undefined;
  linkingFlowStepPaths: LinkingFlowStepPathIntegrationBuilder[] | undefined;
  integrationID: string;
  linkToken: string | undefined;
  fetchLinkToken: () => void;
  stepPathTableRows: LinkingFlowStepPathTableRowsIntegrationBuilder[] | undefined;
  isLoadingLinkingFlowStepPathsTableRows: boolean;
  reloadLinkingFlowStepPathsAndAuthConfigs: () => void;
}

const LinkingFlowStepPathsTable = ({
  authConfigs,
  linkingFlowStepPaths,
  stepPathTableRows,
  isLoadingLinkingFlowStepPathsTableRows,
  reloadLinkingFlowStepPathsAndAuthConfigs,
  integrationID,
  linkToken,
  fetchLinkToken,
}: LinkingFlowStepPathsTableProps) => {
  const history = useHistory();

  useIntegrationBuilderContext({
    shouldRenderSubmitButton: false,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
    markForDeletion: false,
    shouldRenderStageButton: false,
  });

  /* PREVIEW MERGE LINK */

  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    ...getTenantConfigForMergeLink(),
    onSuccess: () => {
      showSuccessToast("Successfully navigated through Merge Link steps!");
    },
    onExit: () => {
      fetchLinkToken();
    },
  });

  return (
    <div className="space-y-6">
      <IntegrationBuilderHeader
        title="Merge Link steps"
        button={
          linkingFlowStepPaths &&
          linkingFlowStepPaths.length > 0 && (
            <Button
              size="md"
              color="white"
              leftIcon={<Calculator size={16} />}
              disabled={!isReady}
              onClick={open}
            >
              Preview Merge Link
            </Button>
          )
        }
      />
      <Callout
        title="What is a Merge Link path?"
        description="A Merge Link path represents a way for the end user to authenticate via Merge Link. Each path must be tied to a single authentication configuration."
      />
      <MergeTable
        hover={authConfigs && authConfigs.length > 0}
        hasMarginBottom={false}
        hasMorePaddingOnFirstElement
        header={
          authConfigs &&
          authConfigs.length > 0 &&
          linkingFlowStepPaths &&
          linkingFlowStepPaths.length == 0 ? (
            <>
              <th scope="col">Authentication configuration</th>
              <th scope="col" className="w-full justify-start"></th>
            </>
          ) : (
            <>
              <th scope="col">Authentication configuration</th>
              <th scope="col">Path type</th>
              <th scope="col">Status</th>
              <th scope="col">Description</th>
              <th scope="col"></th>
            </>
          )
        }
        content={
          isLoadingLinkingFlowStepPathsTableRows ? (
            <TableSkeletonLoader cols={4} rows={5} size="large" />
          ) : (
            <>
              {stepPathTableRows && stepPathTableRows.length > 0 ? (
                <>
                  {stepPathTableRows.map((row) => (
                    <LinkingFlowStepPathsTableRow
                      key={row.id}
                      tableRow={row}
                      linkingFlowStepPath={linkingFlowStepPaths?.find(
                        (stepPath) =>
                          stepPath.auth_configuration_id === row.auth_config_id &&
                          (!row.path_type || row.path_type === stepPath.path_type)
                      )}
                      integrationID={integrationID}
                      reloadLinkingFlowStepPathsAndAuthConfigs={
                        reloadLinkingFlowStepPathsAndAuthConfigs
                      }
                    />
                  ))}
                </>
              ) : (
                <IntegrationBuilderEmptyState
                  title="No authentication configurations"
                  subtitle={
                    <div>
                      In order to create Merge Link steps, you must first create an{" "}
                      <Link
                        className="pointer-events-auto p-0 m-0 text-blue-40 text-sm hover:text-blue-60 hover:text-opacity-70"
                        onClick={() =>
                          navigateToIntegrationBuilderAuthConfigTable(history, integrationID)
                        }
                      >
                        authentication configuration
                      </Link>
                    </div>
                  }
                  numberOfColumns={5}
                />
              )}
            </>
          )
        }
      />
    </div>
  );
};

export default LinkingFlowStepPathsTable;

import { createContext, Dispatch, SetStateAction, useContext } from "react";
import {
  CanaryTestInfo,
  CanaryTestResults,
  IntegrationValidatorRuleResult,
  PublishIntegrationVersionRequestBodyType,
  PublishModuleInfoV2,
  ValidatorRunStatusType,
} from "../types";
import { IntegrationTestSuiteExecution } from "../../../../models/MappingTests";
import { IntegrationChecklistData } from "../../../../constants";

// Define the context type
export interface PublishModuleContextState {
  integrationID: string;
  refetchPublishModuleInfo: () => void;

  // Publishing related state
  isPublishModalOpen: boolean;
  setIsPublishModalOpen: Dispatch<SetStateAction<boolean>>;
  overrideComment: string | undefined;
  setOverrideComment: Dispatch<SetStateAction<string | undefined>>;
  overrideTicket: string | undefined;
  setOverrideTicket: Dispatch<SetStateAction<string | undefined>>;
  isSaving: boolean;
  setIsSaving: Dispatch<SetStateAction<boolean>>;
  onPublish: (body: PublishIntegrationVersionRequestBodyType) => void;
  isCanaryTestingToggleEnabled: boolean;
  setIsCanaryTestingToggleEnabled: Dispatch<SetStateAction<boolean>>;
  canaryTestInfo: CanaryTestInfo | undefined;
  canaryTestResults: CanaryTestResults | undefined;
  isCanaryTestingPublishModalOpen: boolean;
  setIsCanaryTestingPublishModalOpen: Dispatch<SetStateAction<boolean>>;
  onCanaryTestPublish: () => void;
  onCanaryTestAbort: () => void;
  onCanaryTestRollback: () => void;
  isCanaryTestingAbortModalOpen: boolean;
  setIsCanaryTestingAbortModalOpen: Dispatch<SetStateAction<boolean>>;

  // Staged component related state
  isLoadingStagedComponents: boolean;
  setIsLoadingStagedComponents: Dispatch<SetStateAction<boolean>>;
  publishModuleInfo: PublishModuleInfoV2 | undefined;
  hasChanges: boolean;
  isPublishBlocked: boolean;
  addBlockingRule: (rule: IntegrationValidatorRuleResult) => void;
  removeBlockingRule: (rule: IntegrationValidatorRuleResult) => void;
  validatorRunStatus: ValidatorRunStatusType | undefined;

  // Mapping test related state
  isFetchingLatestTestSuiteExecution: boolean;
  hasFailedFetchingLatestExecution: boolean;
  integrationTestSuiteExecution: IntegrationTestSuiteExecution | null;
  isRunningMappingTests: boolean;
  fetchLatestIntegrationVersionTestSuiteExecution: () => void;
  onRunTestsForStagedIntegrationVersion: () => void;
  overrideFailingMappingTest: () => void;
  areMappingTestsFailing: boolean;
  setAreMappingTestsFailing: Dispatch<SetStateAction<boolean>>;

  // Integration checklist related state
  integrationChecklist: IntegrationChecklistData | undefined;
  fetchIntegrationChecklist: () => void;
}

// Create the context
export const PublishModuleContext = createContext<PublishModuleContextState>({
  integrationID: "",
  refetchPublishModuleInfo: () => {},

  isPublishModalOpen: false,
  setIsPublishModalOpen: () => {},
  overrideComment: undefined,
  setOverrideComment: () => {},
  overrideTicket: undefined,
  setOverrideTicket: () => {},
  isSaving: false,
  setIsSaving: () => {},
  onPublish: () => {},
  isCanaryTestingToggleEnabled: true,
  setIsCanaryTestingToggleEnabled: () => {},
  canaryTestInfo: undefined,
  canaryTestResults: undefined,
  isCanaryTestingPublishModalOpen: false,
  setIsCanaryTestingPublishModalOpen: () => {},
  onCanaryTestPublish: () => {},
  onCanaryTestAbort: () => {},
  onCanaryTestRollback: () => {},
  isCanaryTestingAbortModalOpen: false,
  setIsCanaryTestingAbortModalOpen: () => {},

  isLoadingStagedComponents: true,
  setIsLoadingStagedComponents: () => {},
  publishModuleInfo: undefined,
  hasChanges: false,
  isPublishBlocked: true,
  addBlockingRule: () => {},
  removeBlockingRule: () => {},

  isFetchingLatestTestSuiteExecution: false,
  hasFailedFetchingLatestExecution: false,
  integrationTestSuiteExecution: null,
  isRunningMappingTests: false,
  overrideFailingMappingTest: () => {},
  areMappingTestsFailing: false,
  setAreMappingTestsFailing: () => {},

  fetchLatestIntegrationVersionTestSuiteExecution: () => {},
  onRunTestsForStagedIntegrationVersion: () => {},
  integrationChecklist: undefined,
  fetchIntegrationChecklist: () => {},
  validatorRunStatus: undefined,
});

// Hook to use the context
export const usePublishModuleContext = () => {
  const context = useContext(PublishModuleContext);
  if (!context) {
    throw new Error("usePublishModuleContext must be used within a PublishModuleProvider");
  }
  return context;
};

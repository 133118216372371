import { Text } from "@merge-api/merge-javascript-shared";
import { ArrowDown, ArrowUp } from "lucide-react";
import { normalizePercentage } from "./utils/helpers";

interface CurrentDataCellProps {
  currentData: number | null | undefined;
  baselineData: number | null | undefined;
}

const CurrentDataCell = ({ currentData, baselineData }: CurrentDataCellProps) => {
  if (currentData == null || baselineData == null) {
    return (
      <>
        <Text>-</Text> {/* Show dash if data is missing */}
      </>
    );
  }

  const isAboveBaseline = currentData > baselineData;
  const isBelowBaseline = currentData < baselineData;

  return (
    <>
      {/* This shouldn't occur with the current set of alerts, but setting this here for the future. */}
      {isAboveBaseline && <ArrowUp className="mr-1 w-4 h-4" />}
      {isBelowBaseline && <ArrowDown className="text-[#D61525] mr-1 w-4 h-4" />}
      <Text>{normalizePercentage(currentData ?? 0)}%</Text>
    </>
  );
};

export default CurrentDataCell;

import { AdminAPICategory, Text } from "@merge-api/merge-javascript-shared";
import { Minus } from "lucide-react";
import SimpleCategoryCell from "../../../shared/SimpleCategoryCell";
import { AutomatedModifiedAtFilterDetails } from "../../types";
import AutomatedModifiedAtFiltersTableBlueprintsCell from "./AutomatedModifiedAtFiltersTableBlueprintsCell";
import AutomatedModifiedAtFiltersTableExistingFilterCell from "./AutomatedModifiedAtFiltersTableExistingFilterCell";

interface Props {
  integrationID: string;
  automatedModifiedAtFilterDetails: AutomatedModifiedAtFilterDetails;
}

const AutomatedModifiedAtFiltersTableRow = ({
  integrationID,
  automatedModifiedAtFilterDetails,
}: Props) => {
  const {
    amf_model: automatedModifiedAtFilter,
    num_linked_account_conditions,
    existing_filter,
    blueprint_details,
  } = automatedModifiedAtFilterDetails;
  const { id, common_model_id: commonModelID } = automatedModifiedAtFilter;
  const category = commonModelID?.split(".")[0];
  const commonModelName = commonModelID?.split(".")[1];

  return (
    <tr key={id}>
      <td className="align-top">
        <Text variant="h6">{commonModelName}</Text>
      </td>
      <td className="align-top">
        <SimpleCategoryCell category={category as AdminAPICategory} />
      </td>
      <td className="max-w-[300px] align-top">
        <AutomatedModifiedAtFiltersTableBlueprintsCell
          integrationID={integrationID}
          blueprint_details={blueprint_details}
        />
      </td>
      <td className="align-top">
        {num_linked_account_conditions === 0 ? (
          <Minus size={16} className="text-gray-60" />
        ) : (
          <Text>{num_linked_account_conditions}</Text>
        )}
      </td>
      <td className="align-top">
        <AutomatedModifiedAtFiltersTableExistingFilterCell
          integrationID={integrationID}
          commonModelName={commonModelName}
          existing_filter={existing_filter}
        />
      </td>
    </tr>
  );
};

export default AutomatedModifiedAtFiltersTableRow;

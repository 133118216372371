import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { IntegrationAvatar } from "../../../../components/shared/MergeAvatars";

interface IntegrationCellProps {
  integrationName: string;
  integrationSquareImageUrl?: string;
}

const IntegrationCell = ({ integrationName, integrationSquareImageUrl }: IntegrationCellProps) => {
  return (
    <Tooltip title={integrationName}>
      <div className="flex flex-row items-center">
        <IntegrationAvatar
          integrationName={integrationName}
          squareImage={integrationSquareImageUrl}
        />
        <Text variant="h6" className="ml-4 max-w-[200px] truncate">
          {integrationName}
        </Text>
      </div>
    </Tooltip>
  );
};
export default IntegrationCell;

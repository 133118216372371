import { Button, Tooltip } from "@merge-api/merge-javascript-shared";
import { usePublishModuleContext } from "./context/PublishModuleContext";

const PublishModulePublishButton = () => {
  const {
    isPublishBlocked,
    isSaving,
    setIsPublishModalOpen,
    isCanaryTestingToggleEnabled,
    setIsCanaryTestingPublishModalOpen,
  } = usePublishModuleContext();

  return (
    <div className="flex flex-row items-center space-x-2">
      <Tooltip
        title={
          isPublishBlocked
            ? "Cannot publish. Blocking rules must be addressed or overridden and mapping tests must pass before publishing."
            : ""
        }
      >
        <Button
          loading={isSaving}
          disabled={isSaving || isPublishBlocked}
          onClick={() => {
            isCanaryTestingToggleEnabled
              ? setIsPublishModalOpen(true)
              : setIsCanaryTestingPublishModalOpen(true);
          }}
        >
          {isCanaryTestingToggleEnabled ? "Publish with canary testing" : "Publish staged versions"}
        </Button>
      </Tooltip>
    </div>
  );
};

export default PublishModulePublishButton;

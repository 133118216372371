import { Button, ButtonVariant, Menu, MenuItem } from "@merge-api/merge-javascript-shared";
import { handleResolveAction, handleSilenceAction } from "../utils/alertActionHelpers";
import { AdminAlert } from "../../../../../models/Entities";
import { Dispatch, SetStateAction } from "react";
import { ALERT_CATEGORIES } from "../utils/constants";
import { snakeCaseToFirstLetterUpperCase } from "../../../../../utils";
import {
  constructLinkedAccountOrganizationDebuggerLink,
  constructIntegrationDebuggerLink,
  constructBlueprintDebuggerLink,
} from "../utils/urlHelpers";

interface DrawerAlertActionCellProps {
  alert: AdminAlert;
  updateAdminAlert: Dispatch<SetStateAction<Record<string, any>>>;
}

const DrawerAlertActionCell = ({ alert, updateAdminAlert }: DrawerAlertActionCellProps) => {
  const isResolved = !!alert.resolved_at;
  const resolveButtonText = !isResolved ? "Resolve" : "Unresolve";
  const isSilenced = !!alert.silenced_at;
  const category = alert.alert_level || "";
  return (
    <div className="flex justify-end items-center space-x-5">
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="flex gap-4"
      >
        {
          <Button
            variant={ButtonVariant.TertiaryWhite}
            size="sm"
            onClick={() => {
              window.open(constructBlueprintDebuggerLink(alert.blueprint_id), "_blank");
            }}
          >
            Blueprint debugger
          </Button>
        }
        {
          <Button
            variant={ButtonVariant.TertiaryWhite}
            size="sm"
            onClick={() => {
              if (category === ALERT_CATEGORIES.INTEGRATION) {
                window.open(constructIntegrationDebuggerLink(alert.integration_id || ""), "_blank");
              } else {
                window.open(
                  constructLinkedAccountOrganizationDebuggerLink(
                    alert.organization_id || "",
                    alert.linked_account_id || ""
                  ),
                  "_blank"
                );
              }
            }}
          >
            {snakeCaseToFirstLetterUpperCase(category)} debugger
          </Button>
        }
        <Menu
          ButtonProps={{
            children: resolveButtonText,
            variant: ButtonVariant.TertiaryWhite,
            size: "sm",
            onClick: () => {
              handleResolveAction(alert, isResolved, updateAdminAlert);
            },
          }}
          isComboButton
          menuPlacement="bottom-end"
          menuClassName="z-[1400]"
        >
          <MenuItem onClick={() => handleSilenceAction(alert, isSilenced, updateAdminAlert)}>
            {isSilenced ? "Mark as Active" : "Mark as Noise"}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default DrawerAlertActionCell;

import {
  TypeaheadComparatorClusterOption,
  TypeaheadComparatorEnum,
} from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import { firstLetterUpperCase } from "../../../../../utils";
import { AdminAlert, APICategory, APICategoryDisplay } from "../../../../../models/Entities";
import { BlueprintOperationType } from "../../../../../models/Blueprints";

export enum ALERT_CATEGORIES {
  INTEGRATION = "INTEGRATION",
  ORGANIZATION = "ORGANIZATION",
  LINKED_ACCOUNT = "LINKED_ACCOUNT",
}

export enum SEARCH_METADATA_CATEGORIES {
  INTEGRATION = "INTEGRATION",
  ORGANIZATION = "ORGANIZATION",
  LINKED_ACCOUNT = "LINKED_ACCOUNT",
  BLUEPRINT = "BLUEPRINT",
}

export enum IntegrationTier {
  TIER_1 = "Tier 1",
  TIER_2 = "Tier 2",
  TIER_3 = "Tier 3",
  TIER_4 = "Tier 4",
}

export enum AlertStatus {
  RESOLVED = "Resolved",
  ACTIVE = "Active",
  SILENCED = "Silenced",
}

export enum MetricRuleNames {
  BLUEPRINT_SUCCESS_RATE_ALERT = "BLUEPRINT_SUCCESS_RATE_ALERT",
}

export const MetricRuleNamesDisplay: Record<MetricRuleNames, string> = {
  [MetricRuleNames.BLUEPRINT_SUCCESS_RATE_ALERT]: "Success rate",
};

export type SearchMetadata = {
  id: string;
  name?: string;
};

export enum AlertDashboardColumn {
  INTEGRATION = "Integration",
  DATE = "Start Date",
  STATUS = "Status",
  SEVERITY = "Severity",
  METRIC = "Metric",
  OVERALL = "Overall",
  CURRENT_LA = "Current",
  CURRENT_ORGANIZATION = "Current ",
  DEVIATION = "Deviation",
  ORGANIZATION = "Organization",
  LINKED_ACCOUNT_ID = "Linked Account ID",
  BLUEPRINT = "Blueprint",
  BLUEPRINT_LAST_UPDATED = "Last BP Update",
  ACTIONS = "Actions",
}

// Each table has a different ordering due to some fields not being shared.
export const TABLE_TYPE_COLUMN_ORDERINGS = {
  [ALERT_CATEGORIES.INTEGRATION]: [
    AlertDashboardColumn.INTEGRATION,
    AlertDashboardColumn.DATE,
    AlertDashboardColumn.STATUS,
    AlertDashboardColumn.SEVERITY,
    AlertDashboardColumn.METRIC,
    AlertDashboardColumn.OVERALL,
    AlertDashboardColumn.BLUEPRINT_LAST_UPDATED,
    AlertDashboardColumn.BLUEPRINT,
    AlertDashboardColumn.ACTIONS,
  ],
  [ALERT_CATEGORIES.ORGANIZATION]: [
    AlertDashboardColumn.ORGANIZATION,
    AlertDashboardColumn.DATE,
    AlertDashboardColumn.STATUS,
    AlertDashboardColumn.SEVERITY,
    AlertDashboardColumn.INTEGRATION,
    AlertDashboardColumn.METRIC,
    AlertDashboardColumn.OVERALL,
    AlertDashboardColumn.CURRENT_ORGANIZATION,
    AlertDashboardColumn.DEVIATION,
    AlertDashboardColumn.BLUEPRINT_LAST_UPDATED,
    AlertDashboardColumn.BLUEPRINT,
    AlertDashboardColumn.ACTIONS,
  ],
  [ALERT_CATEGORIES.LINKED_ACCOUNT]: [
    AlertDashboardColumn.LINKED_ACCOUNT_ID,
    AlertDashboardColumn.DATE,
    AlertDashboardColumn.STATUS,
    AlertDashboardColumn.SEVERITY,
    AlertDashboardColumn.INTEGRATION,
    AlertDashboardColumn.ORGANIZATION,
    AlertDashboardColumn.METRIC,
    AlertDashboardColumn.OVERALL,
    AlertDashboardColumn.CURRENT_LA,
    AlertDashboardColumn.DEVIATION,
    AlertDashboardColumn.BLUEPRINT_LAST_UPDATED,
    AlertDashboardColumn.BLUEPRINT,
    AlertDashboardColumn.ACTIONS,
  ],
};

export const AlertDashboardColumnTooltip: Partial<Record<AlertDashboardColumn, string>> = {
  [AlertDashboardColumn.OVERALL]: "Success over total for the Blueprint ID (last 2 days)",
  [AlertDashboardColumn.CURRENT_LA]:
    "Success over total for the Blueprint ID and Linked Account (last 2 days)",
  [AlertDashboardColumn.CURRENT_ORGANIZATION]:
    "Success over total for the Blueprint ID and Organization (last 2 days)",
  [AlertDashboardColumn.DEVIATION]: "Overall minus Current",
  [AlertDashboardColumn.SEVERITY]: "Severity of the alert, tied to integration tier",
  [AlertDashboardColumn.DATE]:
    "The alert was created at this time, but has been detected till the current time",
  [AlertDashboardColumn.BLUEPRINT_LAST_UPDATED]: "Time when the Blueprint was last published.",
};

export type AlertSearchFilterValue =
  | "integration_id"
  | "organization_id"
  | "linked_account_id"
  | "blueprint_id";

export interface AlertSearchFilterConfig {
  category: SEARCH_METADATA_CATEGORIES;
  value: AlertSearchFilterValue;
  displayName: string;
}

export type AlertsData = {
  alerts: AdminAlert[] | undefined;
  hasNext: boolean;
  onNext: () => void;
  isLoading: boolean;
};

export type AlertSearchQueryParams = {
  id?: string;
  alert_level?: ALERT_CATEGORIES;
  severity_level?: string;
  linked_account_id?: string;
  integration_id?: string;
  organization_id?: string;
  blueprint_id?: string;
  show_resolved?: boolean;
  show_silenced?: boolean;
  metric_name?: string;
  blueprint_category?: string;
  blueprint_operation_type?: string;
};

export const DEFAULT_SEVERITY_TYPEAHEAD_OPTION: TypeaheadComparatorClusterOption = {
  value: "severity_level",
  label: "Severity",
  comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
  target: {
    targetOptions: Object.entries(IntegrationTier).map(([key, value]) => ({
      value: key,
      label: firstLetterUpperCase(value.toLowerCase()),
    })),
  },
};

export const DEFAULT_METRIC_NAME_TYPEAHEAD_OPTION: TypeaheadComparatorClusterOption = {
  value: "metric_name",
  label: "Metric rules",
  comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
  target: {
    targetOptions: Object.values(MetricRuleNames).map((rule) => ({
      value: rule,
      label: MetricRuleNamesDisplay[rule],
    })),
  },
};

export const DEFAULT_BLUEPRINT_CATEGORY_TYPEAHEAD_OPTION: TypeaheadComparatorClusterOption = {
  value: "blueprint_category",
  label: "Blueprint category",
  comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
  target: {
    targetOptions: Object.values(APICategory).map((category) => ({
      value: category,
      label: APICategoryDisplay[category],
    })),
  },
};

export const DEFAULT_BLUEPRINT_OPERATION_TYPE_TYPEAHEAD_OPTION: TypeaheadComparatorClusterOption = {
  value: "blueprint_operation_type",
  label: "Blueprint operation type",
  comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
  target: {
    targetOptions: Object.values(BlueprintOperationType).map((operationType) => ({
      value: operationType,
      label: operationType,
    })),
  },
};

export const availableConstantAlertFilters: TypeaheadComparatorClusterOption[] = [
  DEFAULT_SEVERITY_TYPEAHEAD_OPTION,
  DEFAULT_METRIC_NAME_TYPEAHEAD_OPTION,
  DEFAULT_BLUEPRINT_CATEGORY_TYPEAHEAD_OPTION,
  DEFAULT_BLUEPRINT_OPERATION_TYPE_TYPEAHEAD_OPTION,
];

import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { IntegrationEditorTopControlPanelActionProps } from "../../../models/Blueprints";

const IntegrationEditorTopControlPanelAction = ({
  Icon,
  isDisabled,
  onClick,
  text,
}: IntegrationEditorTopControlPanelActionProps) => {
  return (
    <Button
      variant={ButtonVariant.TextBlack}
      disabled={isDisabled}
      onClick={() => {
        if (!isDisabled) {
          onClick();
        }
      }}
      leftIcon={<Icon size="12px" />}
    >
      {text}
    </Button>
  );
};

export default IntegrationEditorTopControlPanelAction;

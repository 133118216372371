import { Link, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { CanaryTestBlueprintSuccessRateInfo } from "../types";
import { renderSuccessRateArrow, roundSuccessRate, trimStringToSize } from "../utils";
import { usePublishModuleContext } from "../context/PublishModuleContext";
import { navigateToBlueprintEditor } from "../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { Info } from "lucide-react";
import clsx from "clsx";

type Props = {
  successRateInfo: CanaryTestBlueprintSuccessRateInfo;
  isLastItem: boolean;
};

const CanaryTestingBlueprintSuccessRateRow = ({ successRateInfo, isLastItem }: Props) => {
  const { integrationID } = usePublishModuleContext();
  const history = useHistory();

  const controlSuccessRate = successRateInfo.historical_success_rate;
  const testSuccessRate = successRateInfo.test_success_rate;
  const controlSuccessRateString = roundSuccessRate(controlSuccessRate);
  const testSuccessRateString = roundSuccessRate(testSuccessRate);
  const shouldRenderSuccessRateArrow =
    controlSuccessRate !== null &&
    testSuccessRate !== null &&
    controlSuccessRate !== undefined &&
    testSuccessRate !== undefined;

  return (
    <div className={clsx("grid grid-cols-12 gap-4 w-full", !isLastItem ? "border-b" : "")}>
      <div className="py-3 px-3 flex w-full col-span-6">
        <Link
          onClick={() =>
            navigateToBlueprintEditor(
              history,
              integrationID,
              successRateInfo.blueprint_version_id!,
              true
            )
          }
          variant="md"
          target="_blank"
          className="inline-flex flex-row items-center text-blue-40 hover:text-blue-60"
        >
          {trimStringToSize(successRateInfo.blueprint_name, successRateInfo.blueprint_version_id)}
        </Link>
      </div>
      <div className="py-3 pl-2 flex w-full col-span-3">
        <Text variant="h6">{controlSuccessRateString}</Text>
        {(controlSuccessRate === null || controlSuccessRate === undefined) && (
          <Tooltip title="This blueprint didn't run during the past 2 weeks">
            <Info className="ml-2 text-gray-60" width={16} height={16} />
          </Tooltip>
        )}
      </div>
      <div className="py-3 col-span-3 flex items-center">
        {shouldRenderSuccessRateArrow &&
          renderSuccessRateArrow(controlSuccessRate, testSuccessRate)}
        <Text variant="h6">{testSuccessRateString}</Text>
        {(testSuccessRate === null || testSuccessRate === undefined) && (
          <Tooltip title="This blueprint didn't run during the canary test">
            <Info className="ml-2 text-gray-60" width={16} height={16} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default CanaryTestingBlueprintSuccessRateRow;

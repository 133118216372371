import { ChangeEvent, useState } from "react";
import { add, format } from "date-fns";
import { Dialog, Typeahead, TextField, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { DATE_PICKER_OPTIONS } from "../../../constants";
import { calculateDateFromPicker } from "../utils/SharedComponentUtils";

interface DatePickerProps {
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  onStartDateSet: (date: Date | null) => void;
  onEndDateSet: (date: Date | null) => void;
}

const createDateOptionString = (startDate: Date, endDate: Date) => {
  const formatString = "M/d/yyyy";
  return `${format(startDate, formatString)} - ${format(endDate, formatString)}`;
};

const MergeDatePicker = ({
  selectedStartDate,
  selectedEndDate,
  onStartDateSet,
  onEndDateSet,
}: DatePickerProps) => {
  const today = new Date();

  const [selectedDateOption, setSelectedDateOption] = useState<string | undefined>(
    (selectedStartDate &&
      selectedEndDate &&
      createDateOptionString(selectedStartDate, selectedEndDate)) ??
      undefined
  );

  const [isCalendarSelectorEnabled, setIsCalendarSelectorEnabled] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(selectedStartDate);
  const [endDate, setEndDate] = useState<Date | null>(selectedEndDate);

  const allDateOptions: Array<any> = Object.values(DATE_PICKER_OPTIONS);

  function onDateChoiceSelection(dateOption: string | undefined) {
    if (dateOption == undefined || dateOption == DATE_PICKER_OPTIONS.ALL_TIME) {
      onStartDateSet(null);
      onEndDateSet(null);
      setSelectedDateOption(undefined);
    } else if (dateOption === DATE_PICKER_OPTIONS.CUSTOM) {
      setIsCalendarSelectorEnabled(true);
    } else {
      setStartDate(null);
      setEndDate(null);
      onEndDateSet(null);
      setSelectedDateOption(dateOption);
      onStartDateSet(calculateDateFromPicker(dateOption));
      onEndDateSet(today);
    }
  }

  function onCalendarStartSelect(event: ChangeEvent<HTMLInputElement>) {
    const date = event.target.valueAsDate;
    setStartDate(date);
  }

  function onCalendarEndSelect(event: ChangeEvent<HTMLInputElement>) {
    const date = event.target.valueAsDate;
    setEndDate(date);
  }

  function dateRangeChosen() {
    if (startDate != null && endDate != null) {
      setSelectedDateOption(createDateOptionString(startDate, endDate));
    }
    onStartDateSet(startDate);

    // add 1 day here so that custom range is inclusive
    // i.e. if user selects 1/24/2020 and 1/24/2020 it will add a day
    // so that it goes from midnight of that day to midnight of the next
    onEndDateSet(endDate && add(endDate, { days: 1 }));
    setIsCalendarSelectorEnabled(false);
  }

  return (
    <>
      <Typeahead
        className="bg-white"
        placeholder="All time"
        value={selectedDateOption}
        options={allDateOptions}
        onChange={(_, dateOption: any) => onDateChoiceSelection(dateOption)}
      />
      <Dialog
        open={isCalendarSelectorEnabled}
        hideSecondaryButton
        onClose={() => {
          setIsCalendarSelectorEnabled(false);
        }}
        onPrimaryButtonClick={dateRangeChosen}
        primaryButtonVariant={ButtonVariant.PrimaryCharcoal}
        primaryButtonText="Select date range"
        primaryButtonDisabled={startDate == undefined || endDate == undefined}
        title="Custom date range"
        variant="md"
      >
        <div className="flex flex-row items-center">
          <TextField
            className="w-full"
            placeholder="Select date"
            type="date"
            value={startDate ? startDate.toISOString().split("T")[0] : ""}
            onChange={onCalendarStartSelect}
          />
          <div className="date-picker-dash ml-2 mr-2">–</div>
          <TextField
            className="w-full"
            placeholder="Select date"
            type="date"
            value={endDate ? endDate.toISOString().split("T")[0] : ""}
            onChange={onCalendarEndSelect}
            min={startDate ? startDate.toISOString().split("T")[0] : ""}
          />
        </div>
      </Dialog>
    </>
  );
};

export default MergeDatePicker;

import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

interface ExplainAlertProps {
  title: string;
  subtitle: string;
  className?: string;
}
const ExplainAlert = ({ title, subtitle, className }: ExplainAlertProps) => {
  return (
    <Alert title={title} color="gray" icon={<Info size={16} />} className={className}>
      <Text>{subtitle}</Text>
    </Alert>
  );
};

export default ExplainAlert;

import { Accordion, Card, Spinner, Text } from "@merge-api/merge-javascript-shared";
import { useMemo } from "react";
import { useStagedComponentContext } from "../context/StagedComponentContext";
import StagedComponentContextProvider from "../context/StagedComponentContextProvider";
import { ValidatedStagedComponent } from "../types";
import StagedComponentVersionCardTitle from "./StagedComponentVersionCardTitle";
import StagedComponentTabs from "./StagedComponentTabs";

type Props = {
  stagedComponent: ValidatedStagedComponent;
  integrationID: string;
};

const PublishModuleStagedComponentVersionCardV2 = () => {
  const {
    inProgressRules,
    newBlockingRules,
    newWarningRules,
    existingBlockingRules,
    existingWarningRules,
    passedRules,
  } = useStagedComponentContext();

  const numCompletedRules = useMemo(() => {
    return (
      passedRules.length +
      newBlockingRules.length +
      newWarningRules.length +
      existingBlockingRules.length +
      existingWarningRules.length
    );
  }, [passedRules, newBlockingRules, newWarningRules, existingBlockingRules, existingWarningRules]);

  const numInProgressRules = useMemo(() => {
    return inProgressRules.length;
  }, [inProgressRules]);

  return (
    <Card className="px-5 pt-5">
      <div className="pb-4 space-y-4">
        <StagedComponentVersionCardTitle />
        {numInProgressRules > 0 ? (
          <Card variant="none" className="rounded-lg py-4 px-5 w-full bg-gray-0">
            <Accordion
              title={
                <div className="space-x-5 flex flex-row items-center">
                  <Spinner size={"sm"} />
                  <Text variant="h6">
                    {`${numCompletedRules} rules complete and ${numInProgressRules} in progress`}
                  </Text>
                </div>
              }
              variant="none"
              chevronPlacement="right"
              className={"bg-transparent w-full rounded-lg"}
              titleClassName={"bg-transparent w-full"}
            >
              <div className="mt-4 space-y-2">
                <Text variant="h6">In progress</Text>
                {/* Bulleted list */}
                <ul className="list-disc pl-4">
                  {inProgressRules.map((rule) => (
                    <li key={rule.rule_id}>
                      <Text>{rule.rule_name}</Text>
                    </li>
                  ))}
                </ul>
              </div>
            </Accordion>
          </Card>
        ) : null}
      </div>
      <StagedComponentTabs />
    </Card>
  );
};

const PublishModuleStagedComponentVersionCardV2WithContext = ({
  stagedComponent,
  integrationID,
}: Props) => {
  return (
    <StagedComponentContextProvider stagedComponent={stagedComponent} integrationID={integrationID}>
      <PublishModuleStagedComponentVersionCardV2 />
    </StagedComponentContextProvider>
  );
};

export default PublishModuleStagedComponentVersionCardV2WithContext;

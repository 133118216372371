import { useState } from "react";
import { AdminAlert } from "../../../../models/Entities";
import AlertDashboardTableCell from "./AlertDashboardTableCell";
import { ALERT_CATEGORIES, TABLE_TYPE_COLUMN_ORDERINGS } from "./utils/constants";
import AlertDashboardDrawer from "./drawer/AlertDashboardDrawer";
import {
  navigateToAlertDashboardAlertDrawer,
  navigateToAlertDashboardTab,
} from "../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";

type AlertDashboardTableRowProps = {
  alert: AdminAlert;
  tableType: ALERT_CATEGORIES;
  activeAlertID?: string;
};

const AlertDashboardTableRow = ({
  alert,
  tableType,
  activeAlertID,
}: AlertDashboardTableRowProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(
    activeAlertID ? alert.id === activeAlertID : false
  );
  // We use state for alert in order to rerender the status on actions.
  const [adminAlert, setAdminAlert] = useState<AdminAlert>(alert);

  const updateAdminAlert = (fields: Record<string, any>): void => {
    setAdminAlert((prevAlert) => ({
      ...prevAlert,
      ...fields,
    }));
  };

  const columnOrder = TABLE_TYPE_COLUMN_ORDERINGS[tableType] || [];
  const history = useHistory();
  const handleDrawerOpen = () => {
    navigateToAlertDashboardAlertDrawer(history, tableType, adminAlert.id);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    navigateToAlertDashboardTab(history, tableType);
    setIsDrawerOpen(false);
  };

  const renderColumns = () => {
    return columnOrder.map((column) => (
      <td key={column}>
        <AlertDashboardTableCell
          column={column}
          alert={adminAlert}
          updateAdminAlert={updateAdminAlert}
        />
      </td>
    ));
  };

  return (
    <>
      <AlertDashboardDrawer
        alert={adminAlert}
        category={tableType}
        isDrawerOpen={isDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        updateAdminAlert={updateAdminAlert}
      />
      <tr
        className="cursor-pointer text-align-left"
        onClick={() => {
          handleDrawerOpen();
        }}
      >
        {" "}
        {renderColumns()}
      </tr>
    </>
  );
};

export default AlertDashboardTableRow;

import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { fetchWithAuth } from "../../../../../api-client/api_client";
import { UserFacingFilterType } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";

export const fetchMappedCommonModelIDsForIntegration = ({
  integrationID,
  onError,
  onSuccess,
}: {
  integrationID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/${integrationID}/mapped-common-model-ids`,
    method: HTTPMethod.GET,
    onResponse: (data: any) => {
      onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      onError(error);
    },
  });
};

// Note that when we fetch these, although it is querying our FilterType DB model
// we convert it to our Pydantic UserFacingFilterDetails before returning
// them over the API
export const fetchMappedFilterTypesForIntegration = ({
  integrationID,
  onError,
  onSuccess,
}: {
  integrationID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/${integrationID}/filter-types`,
    method: HTTPMethod.GET,
    onResponse: (data: { [id: string]: UserFacingFilterType }) => {
      onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      onError(error);
    },
  });
};

// With the creation of AMF, we need to fetch the filter types for a specific
// blueprint version. This is because the filter types used through AMF are
// tied to the blueprint version.
export const fetchMappedFilterTypesForBlueprintVersion = ({
  integrationID,
  blueprintVersionID,
  onError,
  onSuccess,
}: {
  integrationID: string;
  blueprintVersionID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/${integrationID}/blueprint-versions/${blueprintVersionID}/filter-types`,
    method: HTTPMethod.GET,
    onResponse: (data: { [id: string]: UserFacingFilterType }) => {
      onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      onError(error);
    },
  });
};

// Note that when we fetch these, although it is querying our FilterType DB model
// we convert it to our Pydantic UserFacingFilterDetails before returning
// them over the API
export const fetchAPIEndpointsSimple = ({
  integrationID,
  onError,
  onSuccess,
}: {
  integrationID: string;
  onSuccess: (response: any) => void;
  onError: (response: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/${integrationID}/api-endpoints-simple`,
    method: "GET",
    onResponse: (data) => {
      onSuccess(data);
    },
    onError,
  });
};

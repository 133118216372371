import clsx from "clsx";
import StagedComponentRulesAccordion from "./StagedComponentRulesAccordion";
import { useStagedComponentContext } from "../context/StagedComponentContext";

interface StagedComponentRulesContentProps {
  enabled?: boolean;
}

const StagedComponentRulesContent = ({ enabled = false }: StagedComponentRulesContentProps) => {
  const {
    newBlockingRules,
    newWarningRules,
    existingBlockingRules,
    existingWarningRules,
    passedRules,
    overriddenRules,
    isFetchBlueprint,
  } = useStagedComponentContext();

  return (
    <div
      className={clsx("divide-y divide-solid divide-gray-0 mt-2", {
        "border-t border-solid border-gray-0": !enabled || !isFetchBlueprint,
      })}
    >
      <StagedComponentRulesAccordion
        ruleResults={[...newBlockingRules, ...newWarningRules]}
        title="New alerts"
        learnMoreText="Alerts since this component was last published. These alerts require action."
        isCollapsible={false}
        className="py-4"
      />
      <StagedComponentRulesAccordion
        ruleResults={[...existingBlockingRules, ...existingWarningRules]}
        title="Existing alerts"
        learnMoreText="Alerts from previous publishes. You can publish a blueprint without overriding these alerts."
        className="py-4"
      />
      <StagedComponentRulesAccordion ruleResults={passedRules} title="Passed" className="py-4" />
      <StagedComponentRulesAccordion
        ruleResults={overriddenRules}
        title="Overridden"
        className="py-4"
      />
    </div>
  );
};

export default StagedComponentRulesContent;

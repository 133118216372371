import { useState } from "react";
import { JSONSchemaTypeOption } from "../utils/BlueprintDataTransformUtils";
import Dropdown from "../right-panel/Dropdown";
import { Button } from "@merge-api/merge-javascript-shared";

interface Props {
  setSubItemType: (fieldType: JSONSchemaTypeOption) => void;
  subItemType: JSONSchemaTypeOption | undefined;
  level: number;
}

const BlueprintValidationEditorArrayFieldSubItem = ({ setSubItemType, subItemType }: Props) => {
  const [newSubItemType, setNewSubItemType] = useState<JSONSchemaTypeOption | undefined>(
    subItemType
  );

  return (
    <div className="pl-20 py-4 flex justify-start w-auto max-w-full">
      {
        <div className="flex flex-row gap-4">
          <Dropdown
            choices={Object.values(JSONSchemaTypeOption).map((option) => ({
              name: option,
              id: option,
            }))}
            currentValue={newSubItemType}
            placeholder={"Field Type"}
            onChange={(e) => {
              setNewSubItemType(e.target.value);
            }}
          />

          <Button
            disabled={subItemType === newSubItemType}
            onClick={() => {
              if (newSubItemType) {
                setSubItemType(newSubItemType);
              }
            }}
          >
            <b>Save Item Type</b>
          </Button>
        </div>
      }
    </div>
  );
};

export default BlueprintValidationEditorArrayFieldSubItem;

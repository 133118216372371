import {
  AsyncAutogeneratedReadMappingTestRun,
  AutogeneratedReadMappingTestRunStatus,
  AutogeneratedReadMappingTestRunStatusDescriptions,
} from "../../../models/MappingTests";
import { Alert, ButtonVariant, Dialog, ProgressBar } from "@merge-api/merge-javascript-shared";

interface ViewAutoGeneratedRunModalProps {
  generatedReadTestRun: AsyncAutogeneratedReadMappingTestRun;
  inProgressExecution: boolean;
  dialogOpen: boolean;
  closeDialog: () => void;
}

const ViewAutoGeneratedRunModal = ({
  generatedReadTestRun,
  inProgressExecution,
  closeDialog,
  dialogOpen,
}: ViewAutoGeneratedRunModalProps) => {
  // progress bar value dervied from state
  const totalBlueprintVersionsToRun = generatedReadTestRun?.total_blueprint_versions_to_run ?? 0;
  const additionalStages = 3;
  const blueprintsCompleted = generatedReadTestRun?.blueprint_run_data?.length ?? 0;
  const progressBarDenominatorValue = totalBlueprintVersionsToRun + additionalStages;
  let progressBarNumeratorValue = 0;

  switch (generatedReadTestRun.status) {
    case AutogeneratedReadMappingTestRunStatus.PENDING:
      progressBarNumeratorValue = 0;
      break;
    case AutogeneratedReadMappingTestRunStatus.RUNNING_FETCH_BLUEPRINTS_FOR_LINKED_ACCOUNT:
      progressBarNumeratorValue = blueprintsCompleted;
      break;
    case AutogeneratedReadMappingTestRunStatus.GENERATING_MAPPING_TEST:
      progressBarNumeratorValue = 1 + blueprintsCompleted;
      break;
    default:
      progressBarNumeratorValue = 0;
  }

  const progressBarValue = (progressBarNumeratorValue / progressBarDenominatorValue) * 100;

  // Pull current blueprint name being run from last blueprint_run_data item
  const lastBlueprintRunData = generatedReadTestRun?.blueprint_run_data?.slice(-1)[0];
  const blueprintNameBeingRun = lastBlueprintRunData?.blueprint_name;

  const failedExecution = generatedReadTestRun
    ? [
        AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_FAILURES,
        AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_EXCEPTIONS,
        AutogeneratedReadMappingTestRunStatus.ABORTED,
      ].includes(generatedReadTestRun?.status)
    : false;

  return (
    <Dialog
      title="Fetch test status"
      primaryButtonVariant={ButtonVariant.PrimaryCharcoal}
      primaryButtonText="Close"
      onPrimaryButtonClick={() => {
        closeDialog();
      }}
      open={dialogOpen}
      hideSecondaryButton={true}
      onClose={closeDialog}
      variant="sm"
      disableCloseOnBackdropClick={false}
    >
      {inProgressExecution && (
        <>
          <p>
            {AutogeneratedReadMappingTestRunStatusDescriptions[generatedReadTestRun?.status]}
            {generatedReadTestRun?.status ===
              AutogeneratedReadMappingTestRunStatus.RUNNING_FETCH_BLUEPRINTS_FOR_LINKED_ACCOUNT &&
            blueprintNameBeingRun
              ? ` ${blueprintNameBeingRun}...`
              : ""}
          </p>
          <ProgressBar value={progressBarValue} color="teal" />
        </>
      )}
      {failedExecution && (
        <>
          <Alert showWarningIcon color="red" size="sm" className="mb-3 flex items-center">
            {AutogeneratedReadMappingTestRunStatusDescriptions[generatedReadTestRun?.status]}
          </Alert>
          {generatedReadTestRun?.status ===
            AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_FAILURES && (
            <>
              <p className="overflow-hidden break-words">
                The mapping test request mocks and assertions were successfully generated, however,
                the mapping test run itself failed. The following models have errors:
              </p>
              <p className="overflow-y-auto break-words max-h-[200px] border rounded p-2">
                {generatedReadTestRun?.exception_message}
              </p>
            </>
          )}
          {generatedReadTestRun?.status ===
            AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_EXCEPTIONS && (
            <p className="overflow-hidden break-words">{generatedReadTestRun?.exception_message}</p>
          )}
          {generatedReadTestRun?.status === AutogeneratedReadMappingTestRunStatus.ABORTED && (
            <>
              <p className="overflow-hidden break-words">
                The worker running this task ran into an issue. Please try again.
              </p>
              <p className="overflow-hidden break-words">
                If the issue persists, please contact the AI team.
              </p>
            </>
          )}
        </>
      )}
      {generatedReadTestRun?.status ===
        AutogeneratedReadMappingTestRunStatus.FINISHED_WITH_SUCCESS && (
        <p>
          Successfully generated mapping test. Hang tight, navigating to the mapping test editor...
        </p>
      )}
    </Dialog>
  );
};
export default ViewAutoGeneratedRunModal;

import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { APIEndpointMethod } from "../../../../models/Entities";
import { MergeFlagFeature, useMergeFlag } from "../../../shared/hooks/useMergeFlag";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { AutomatedModifiedAtFilterDetails } from "../types";

const useAutomatedModifiedAtFilters = (integrationID: string) => {
  const { enabled: isFlagEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_AUTOMATED_MODIFIED_AT_FILTERING,
    integrationId: integrationID,
  });

  const [isLoadingFilters, setIsLoadingFilters] = useState(true);
  const [amfModelsDetails, setAmfModelsDetails] = useState<AutomatedModifiedAtFilterDetails[]>([]);
  const [areAllFiltersEnabled, setAreAllFiltersEnabled] = useState(false);

  useEffect(() => {
    if (isFlagEnabled === undefined) return;
    setAreAllFiltersEnabled(isFlagEnabled);
  }, [isFlagEnabled]);

  const loadAllAutomatedModifiedAtFilters = () => {
    setIsLoadingFilters(true);
    fetchWithAuth({
      path: `/integrations/selective-sync/integration/${integrationID}/automated-modified-at-filters`,
      method: APIEndpointMethod.GET,
      onResponse: (responseData: AutomatedModifiedAtFilterDetails[]) => {
        setIsLoadingFilters(false);
        setAmfModelsDetails(responseData);
      },
      onError: () => {
        setIsLoadingFilters(false);
        setAmfModelsDetails([]);
        showErrorToast("Failed to fetch automated modified at filters");
      },
    });
  };

  const enableAllFilters = () => {
    fetchWithAuth({
      path: `/integrations/selective-sync/integration/${integrationID}/enable-automated-modified-at-filters`,
      method: APIEndpointMethod.POST,
      onResponse: () => {
        setAreAllFiltersEnabled(true);
        showSuccessToast("All filters enabled");
      },
      onError: () => {
        showErrorToast("Failed to enable all filters");
      },
    });
  };

  const disableAllFilters = () => {
    fetchWithAuth({
      path: `/integrations/selective-sync/integration/${integrationID}/disable-automated-modified-at-filters`,
      method: APIEndpointMethod.POST,
      onResponse: () => {
        setAreAllFiltersEnabled(false);
        showSuccessToast("All filters disabled");
      },
      onError: () => {
        showErrorToast("Failed to disable all filters");
      },
    });
  };

  return {
    isLoadingFilters,
    amfModelsDetails,
    areAllFiltersEnabled,
    setAreAllFiltersEnabled,
    loadAllAutomatedModifiedAtFilters,
    enableAllFilters,
    disableAllFilters,
  };
};

export default useAutomatedModifiedAtFilters;

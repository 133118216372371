import CanaryTestResultsTables from "../../../shared/CanaryTestResultsTables";
import { usePublishModuleContext } from "../context/PublishModuleContext";

import useLoadCanaryTestResults from "../hooks/useLoadCanaryTestResults";
import { useEffect } from "react";

const CanaryTestingPublishResults = () => {
  const { integrationID } = usePublishModuleContext();

  const { canaryTestResults, fetchCanaryTestResultsByIntegration } = useLoadCanaryTestResults();

  useEffect(() => {
    fetchCanaryTestResultsByIntegration(integrationID);
  }, [integrationID]);

  return <CanaryTestResultsTables canaryTestResults={canaryTestResults} />;
};

export default CanaryTestingPublishResults;

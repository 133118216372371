import { CanaryTestDashboardRow as CanaryTestDashboardRowType } from "./types";
import { IntegrationAvatar } from "../shared/MergeAvatars";
import CountdownTimeRemaining from "../shared/CountdownTimeRemaining";
import { Badge, Text } from "@merge-api/merge-javascript-shared";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { getPublishRequestsPath } from "../../router/RouterUtils";

interface CanaryTestDashboardRowProps {
  row: CanaryTestDashboardRowType;
}

const CanaryTestDashboardRow = ({ row }: CanaryTestDashboardRowProps) => {
  const history = useHistory();

  return (
    <tr
      key={row.integration_id}
      onClick={() => history.push(getPublishRequestsPath(row.integration_id))}
      className="cursor-pointer hover:bg-[#F9FAFB]"
    >
      <td>
        <div className="flex flex-row items-center">
          <IntegrationAvatar
            integrationName={row.integration_name}
            squareImage={row.integration_image || undefined}
            className=""
          />
          <Text variant="h6" className="pl-2">
            {row.integration_name}
          </Text>
        </div>
      </td>
      <td>
        {row.is_canary_testing_finished ? (
          <Badge color="blue">Complete</Badge>
        ) : row.time_remaining_in_seconds === 0 ? (
          <Badge color="yellow">Waiting for periodic task</Badge>
        ) : (
          <Badge>
            Running:&nbsp;
            <CountdownTimeRemaining initialTimeInSeconds={row.time_remaining_in_seconds} />
          </Badge>
        )}
      </td>
      <td>
        <Text variant="md">
          {row.start_time ? format(new Date(row.start_time), "h:mm a, MMM d, yyyy") : "-"}
        </Text>
      </td>
      <td>
        <Text variant="md">{row.user_name || "Unknown user"}</Text>
      </td>
    </tr>
  );
};

export default CanaryTestDashboardRow;

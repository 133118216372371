import { AdminAlert } from "../../../../../models/Entities";

export const getKibanaBaseUrl = (): string => {
  switch (process.env.REACT_APP_MERGE_ENV) {
    case "PRODUCTION":
      return "https://kibana.access.teleport.merge.land:3080";
    case "DEVELOP":
      return "https://kibana-develop.access.teleport.merge.land:3080";
    default:
      return "localhost:3080"; // Invalid local URL for debugging
  }
};

export const constructKibanaLinkFromAlertErrorCode = (
  alert: AdminAlert,
  errorCode: string
): string => {
  const createFilterQueryString = (key: string, value: string) => {
    return `(meta:(key:${key},params:(query:'${value}'),type:phrase),query:(match_phrase:(${key}:'${value}')))`;
  };

  const filters: string[] = [createFilterQueryString("response_code", errorCode)];

  if (alert.integration_id) {
    filters.push(createFilterQueryString("integration_id", alert.integration_id));
  }

  if (alert.organization_id) {
    filters.push(createFilterQueryString("organization_id", alert.organization_id));
  }

  const filtersQueryString = `filters:!(${filters.join(",")})`;

  const url = `${getKibanaBaseUrl()}/_plugin/kibana/app/discover#/?_a=(query:(language:kuery,query:'*'),${filtersQueryString})`;
  return url;
};

export const constructIntegrationDebuggerLink = (integrationId: string): string => {
  return `https://retool-prod-main.tools.merge.land/apps/3fa7e442-5caa-11ee-a86a-7f3c114a4606/Post-Sales/Integration%20Debugger#integrationId=${integrationId}`;
};

export const constructLinkedAccountOrganizationDebuggerLink = (
  organizationId: string,
  linkedAccountId?: string
): string => {
  const baseUrl = `https://retool-prod-main.tools.merge.land/apps/6097bb7e-93bf-40ab-b9eb-df6f4d56f472/Post-Sales/Debugging%20Assistant#organizationid=${organizationId}`;
  return linkedAccountId ? `${baseUrl}&linkedaccountid=${linkedAccountId}` : baseUrl;
};

export const constructBlueprintDebuggerLink = (blueprintId: string): string => {
  return `https://retool-prod-main.tools.merge.land/apps/3d7b8e74-5c89-11ee-a713-8f7d12ef3717/Post-Sales/Blueprint%20Debugger#blueprintExecutionId&blueprintId=${blueprintId}&currentTab=BP%20Executions%2C%20Last%2021%20Days`;
};

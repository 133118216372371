import { Badge, Card, Text } from "@merge-api/merge-javascript-shared";
import { CloudMoon } from "lucide-react";
import GradientIcon from "../../../shared/icons/GradientIcon";
import EmptyStateWrapper from "../../../shared/layout/EmptyStateWrapper";
import { formattedModelClassName } from "../../utils/PublishModuleUtils";
import { usePublishModuleContext } from "../context/PublishModuleContext";
import { IntegrationComponentNames, ValidatorRunStatusType } from "../types";
import PublishModuleStagedComponentCardV2 from "./PublishModuleStagedComponentCardV2";
import UnstageBlueprintsDialog from "./UnstageBlueprintsDialog";

const LoadingSpinnerCard = () => {
  return (
    <Card className="flex justify-center align-center mb-6">
      <EmptyStateWrapper isSpinner />
    </Card>
  );
};

const PublishModuleStagedVersionsCardV2 = () => {
  const {
    publishModuleInfo,
    integrationID,
    isLoadingStagedComponents,
    validatorRunStatus,
  } = usePublishModuleContext();

  const filteredIntegrationComponents = publishModuleInfo
    ? Object.values(IntegrationComponentNames)
        .sort((a, b) => a.localeCompare(b))
        .filter(
          (integrationComponentName) =>
            publishModuleInfo.staged_changes?.[integrationComponentName]?.length
        )
    : [];

  // If the validator run status is pending or in progress WITH no staged components, show the loading spinner
  const isValidatorLoading =
    validatorRunStatus &&
    (validatorRunStatus == ValidatorRunStatusType.PENDING ||
      (validatorRunStatus == ValidatorRunStatusType.IN_PROGRESS &&
        filteredIntegrationComponents.length == 0));

  if (isLoadingStagedComponents || isValidatorLoading) {
    return <LoadingSpinnerCard />;
  }

  if (!filteredIntegrationComponents.length || !!!publishModuleInfo) {
    return (
      <Card className="p-5">
        <div className="py-8 px-6 space-y-4 flex flex-col justify-center items-center">
          <GradientIcon Icon={CloudMoon} size={32} endColor="#2DB9EE" startColor="#0042E5" />
          <Text variant="h5" className="text-gray-70">
            No staged components
          </Text>
        </div>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      {filteredIntegrationComponents.map((integrationComponentName) => (
        <div>
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex flex-row items-center space-x-2">
              <Text variant="h4">{formattedModelClassName(integrationComponentName)}</Text>
              <Badge>{publishModuleInfo.staged_changes?.[integrationComponentName]?.length}</Badge>
            </div>
            {integrationComponentName === IntegrationComponentNames.BLUEPRINT &&
              (publishModuleInfo.staged_changes?.[integrationComponentName]?.length ?? 0) > 1 && (
                <UnstageBlueprintsDialog
                  blueprints={publishModuleInfo.staged_changes?.[integrationComponentName]}
                />
              )}
          </div>
          <PublishModuleStagedComponentCardV2
            stagedComponents={publishModuleInfo.staged_changes?.[integrationComponentName]}
            integrationID={integrationID}
          />
        </div>
      ))}
    </div>
  );
};

export default PublishModuleStagedVersionsCardV2;

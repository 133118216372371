import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { AdminAlert } from "../../../../models/Entities";
import SeverityCell from "./SeverityCell";
import IntegrationCell from "./IntegrationCell";
import StatusCell from "./StatusCell";
import CurrentDataCell from "./CurrentDataCell";
import MetricNameCell from "./MetricNameCell";
import MergeCopyToClipboard from "../../../../components/shared/MergeCopyToClipboard";
import {
  calculateDeviation,
  getIntegrationTier,
  getStatus,
  normalizePercentage,
} from "./utils/helpers";
import { formatDate } from "../../../../components/shared/utils/SharedComponentUtils";
import { AlertDashboardColumn } from "./utils/constants";
import AlertActionCell from "./AlertActionCell";
import { Dispatch, SetStateAction } from "react";
import AlertBlueprintCell from "./AlertBlueprintCell";
type AlertDashboardTableCellProps = {
  column: string;
  alert: AdminAlert;
  updateAdminAlert: Dispatch<SetStateAction<Record<string, any>>>;
};

const AlertDashboardTableCell = ({
  column,
  alert,
  updateAdminAlert,
}: AlertDashboardTableCellProps) => {
  switch (column) {
    case AlertDashboardColumn.DATE:
      return <Text>{formatDate(alert?.alert_comparison_period_start, "MMM DD, YYYY", false)}</Text>;
    case AlertDashboardColumn.ORGANIZATION:
      return (
        <Tooltip title={alert?.organization_name}>
          <Text variant="h6" className="max-w-[200px] truncate">
            {alert?.organization_name}
          </Text>
        </Tooltip>
      );
    case AlertDashboardColumn.LINKED_ACCOUNT_ID:
      return (
        <Tooltip title={alert?.linked_account_id || ""}>
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="flex items-center text-base"
          >
            <Text variant="h6" className="max-w-[200px] truncate mr-1">
              {alert?.linked_account_id}
            </Text>
            <MergeCopyToClipboard textToCopy={alert?.linked_account_id || ""} />
          </div>
        </Tooltip>
      );
    case AlertDashboardColumn.INTEGRATION:
      return (
        <IntegrationCell
          integrationName={alert?.integration_name || ""}
          integrationSquareImageUrl={alert?.integration_square_image_url}
        />
      );
    case AlertDashboardColumn.STATUS:
      return <StatusCell status={getStatus(alert.resolved_at, alert.silenced_at)}></StatusCell>;
    case AlertDashboardColumn.SEVERITY:
      return <SeverityCell severity={getIntegrationTier(alert.severity_level)} />;
    case AlertDashboardColumn.METRIC:
      return <MetricNameCell metricName={alert.metric_name}></MetricNameCell>;
    case AlertDashboardColumn.OVERALL:
      return <Text>{normalizePercentage(alert.baseline_value ?? 0)}%</Text>;
    case AlertDashboardColumn.CURRENT_LA:
    case AlertDashboardColumn.CURRENT_ORGANIZATION:
      return (
        <CurrentDataCell
          currentData={alert?.metric_value}
          baselineData={alert?.baseline_value}
        ></CurrentDataCell>
      );
    case AlertDashboardColumn.DEVIATION:
      return <Text>{calculateDeviation(alert.metric_value, alert.baseline_value)}%</Text>;
    case AlertDashboardColumn.BLUEPRINT:
      return (
        <AlertBlueprintCell
          blueprintId={alert.blueprint_id}
          blueprintName={alert.blueprint_name}
          integrationId={alert.integration_id || ""}
          versionId={alert.blueprint_version_ids?.[0] || ""}
        />
      );
    case AlertDashboardColumn.BLUEPRINT_LAST_UPDATED:
      return <Text>{formatDate(alert?.blueprint_last_updated || "", "MMM DD, YYYY", false)}</Text>;
    case AlertDashboardColumn.ACTIONS:
      return <AlertActionCell alert={alert} updateAdminAlert={updateAdminAlert} />;
    default:
      return <Text> WIP </Text>;
  }
};

export default AlertDashboardTableCell;

import { AdminAlert } from "../../../../../models/Entities";
import { fetchWithAuth } from "../../../../../api-client/api_client";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import { Dispatch, SetStateAction } from "react";

interface ManageAlertStatusProps {
  alert_id: string;
  updateAdminAlert: Dispatch<SetStateAction<Record<string, any>>>;
}

const handleResolveAction = (
  alert: AdminAlert,
  is_resolved: boolean,
  updateAdminAlert: Dispatch<SetStateAction<Record<string, any>>>
) => {
  (is_resolved ? unresolveAdminAlert : resolveAdminAlert)({
    alert_id: alert.id,
    updateAdminAlert,
  });
};

const handleSilenceAction = (
  alert: AdminAlert,
  is_silenced: boolean,
  updateAdminAlert: Dispatch<SetStateAction<Record<string, any>>>
) => {
  (is_silenced ? unsilenceAdminAlert : silenceAdminAlert)({
    alert_id: alert.id,
    updateAdminAlert,
  });
};
const resolveAdminAlert = ({ alert_id, updateAdminAlert }: ManageAlertStatusProps) => {
  fetchWithAuth({
    path: `analytics/alert-dashboard/${alert_id}/resolve-alert`,
    method: "POST",
    onResponse: () => {
      showSuccessToast("Successfully resolved alert");
      updateAdminAlert({
        resolved_at: new Date().toISOString(),
      });
    },
    onError: (error) => {
      showErrorToast(`Failed to resolve alert. Error message: \n ${error}`);
    },
  });
};

const unresolveAdminAlert = ({ alert_id, updateAdminAlert }: ManageAlertStatusProps) => {
  fetchWithAuth({
    path: `analytics/alert-dashboard/${alert_id}/unresolve-alert`,
    method: "POST",
    onResponse: () => {
      showSuccessToast("Successfully unresolved alert");
      updateAdminAlert({
        resolved_at: null,
      });
    },
    onError: (error) => {
      showErrorToast(`Failed to unresolve alert. Error message: \n ${error}`);
    },
  });
};

const silenceAdminAlert = ({ alert_id, updateAdminAlert }: ManageAlertStatusProps) => {
  fetchWithAuth({
    path: `analytics/alert-dashboard/${alert_id}/silence-alert`,
    method: "POST",
    onResponse: () => {
      showSuccessToast("Successfully silenced alert");
      updateAdminAlert({
        silenced_at: new Date().toISOString(),
      });
    },
    onError: (error) => {
      showErrorToast(`Failed to silence alert. Error message: \n ${error}`);
    },
  });
};

const unsilenceAdminAlert = ({ alert_id, updateAdminAlert }: ManageAlertStatusProps) => {
  fetchWithAuth({
    path: `analytics/alert-dashboard/${alert_id}/unsilence-alert`,
    method: "POST",
    onResponse: () => {
      showSuccessToast("Successfully unsilenced alert");
      updateAdminAlert({
        silenced_at: null,
      });
    },
    onError: (error) => {
      showErrorToast(`Failed to unsilence alert. Error message: \n ${error}`);
    },
  });
};

export { handleResolveAction, handleSilenceAction };

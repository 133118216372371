import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import { useEffect, useMemo, useState } from "react";
import {
  DiffModalTypeEnum,
  DiffModelTypeEnum,
  MapDiffModelTypeToName,
} from "../../../models/DiffModels";
import { compareStates } from "./utils/helpers-diff";
import SplitDiffView from "./components/SplitDiffView";
import EmptyStateWrapper from "../layout/EmptyStateWrapper";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  diffModalType?: DiffModalTypeEnum;
  isLoadingStates?: boolean;
  onPrimaryButtonClick?: () => void;
  primaryButtonText?: string;
  primaryButtonLoading?: boolean;
  modelType: DiffModelTypeEnum;
  currentState: { [key: string]: any };
  newState: { [key: string]: any };
  currentStateTitle?: string | undefined;
  newStateTitle?: string | undefined;
  title?: string;
}

const DiffModal = ({
  isModalOpen,
  setIsModalOpen,
  diffModalType = DiffModalTypeEnum.PRESAVE,
  isLoadingStates = false,
  onPrimaryButtonClick,
  primaryButtonText,
  modelType,
  primaryButtonLoading,
  currentState,
  newState,
  currentStateTitle,
  newStateTitle,
  title,
}: Props) => {
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState<boolean>(false);

  const diffState = useMemo(() => {
    if (!isLoadingStates) {
      return compareStates(modelType, currentState, newState);
    }
    return [];
  }, [isLoadingStates, modelType, currentState, newState]);

  const modelName = useMemo(() => {
    return MapDiffModelTypeToName[modelType];
  }, [modelType]);

  // Close modal if done saving
  useEffect(() => {
    if (isSaveButtonClicked && !primaryButtonLoading) {
      setIsModalOpen(false);
    }
  }, [isSaveButtonClicked, primaryButtonLoading]);

  return (
    <Dialog
      variant="xl"
      title={
        title
          ? title
          : diffModalType === DiffModalTypeEnum.COMPARE
          ? `Compare ${modelName}`
          : `Confirm changes to ${modelName}`
      }
      open={isModalOpen}
      footerButtonsHidden={diffModalType === DiffModalTypeEnum.COMPARE}
      primaryButtonVariant={ButtonVariant.PrimaryCharcoal}
      primaryButtonLoading={primaryButtonLoading}
      primaryButtonText={primaryButtonText ?? `Save ${modelName}`}
      onPrimaryButtonClick={() => {
        if (onPrimaryButtonClick) onPrimaryButtonClick();
        setIsSaveButtonClicked(true);
      }}
      onSecondaryButtonClick={() => setIsModalOpen(false)}
      onClose={() => setIsModalOpen(false)}
    >
      <div className="flex flex-column w-full h-[643px]">
        {isLoadingStates ? (
          <div className="flex justify-center align-center">
            <EmptyStateWrapper isSpinner />
          </div>
        ) : (
          <div className="flex flex-col w-full space-y-6">
            <SplitDiffView
              diffState={diffState}
              currentStateTitle={currentStateTitle}
              newStateTitle={newStateTitle}
              diffModalType={diffModalType}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default DiffModal;

import ExplainAlert from "../../../shared/ExplainAlert";
import IntegrationBuilderHeader from "../../../shared/IntegrationBuilderHeader";
import AutomatedModifiedAtFiltersTable from "./AutomatedModifiedAtFiltersTable";

interface Props {
  integrationID: string;
}

const AutomatedModifiedAtFiltersTableContainer = ({ integrationID }: Props) => {
  return (
    <>
      <IntegrationBuilderHeader title="Automated modified_at filters" />
      <ExplainAlert
        title="What are automated modified_at filters?"
        subtitle="Our backend automatically applies “modified_at” timestamp filters to the supported Common Models below, eliminating the need to manually create “modified_at” Selective Sync filters. End users & customers will be able to use these automated filters like any other Selective Sync filter. Note that existing “modified_at” Selective Sync filters will override the corresponding automated filter."
        className="mb-6"
      />
      <AutomatedModifiedAtFiltersTable integrationID={integrationID} />
    </>
  );
};

export default AutomatedModifiedAtFiltersTableContainer;

import { BlueprintVersionPublishState } from "../../models/Blueprints";
import { Badge } from "@merge-api/merge-javascript-shared";

const PublishStateBadge = (props: { publishState: BlueprintVersionPublishState }) => {
  switch (props.publishState) {
    case BlueprintVersionPublishState.Draft:
      return <Badge color="yellow">Draft</Badge>;
    case BlueprintVersionPublishState.Published:
      return <Badge color="teal">Published</Badge>;
    case BlueprintVersionPublishState.Unpublished:
      return <Badge color="gray">Unpublished</Badge>;
    case BlueprintVersionPublishState.Staged:
      return <Badge color="blue">Staged</Badge>;
    default:
      return <Badge color="gray">{props.publishState}</Badge>;
  }
};

export default PublishStateBadge;

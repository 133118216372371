import { ButtonVariant, Menu, MenuItem, Text } from "@merge-api/merge-javascript-shared";
import { useStagedComponentContext } from "../context/StagedComponentContext";
import { Ellipsis } from "lucide-react";
import { navigateToBlueprintEditor } from "../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  fetchBlueprintVersionForIntegration,
  fetchBlueprintVersions,
} from "../../../blueprint-editor/utils/BlueprintEditorAPIClient";
import {
  BlueprintVersion,
  BlueprintVersionPublishState,
  BlueprintWithTrigger,
} from "../../../../models/Blueprints";
import { DiffModalTypeEnum, DiffModelTypeEnum } from "../../../../models/DiffModels";
import DiffModal from "../../../shared/diff-view/DiffModal";

const CanaryTestingStagedComponent = ({ lastComponent }: { lastComponent: boolean }) => {
  const { component, integrationID } = useStagedComponentContext();
  const history = useHistory();

  // States for diff
  const [isDiffModalOpen, setIsDiffModalOpen] = useState<boolean>(false);
  const [isLoadingCurrentStateForDiff, setIsLoadingCurrentStateForDiff] = useState<boolean>(false);
  const [isLoadingNewStateForDiff, setIsLoadingNewStateForDiff] = useState<boolean>(false);
  const [blueprintVersions, setBlueprintVersions] = useState<BlueprintVersion[] | undefined>();
  const [currentStateForDiff, setCurrentStateForDiff] = useState<{ [key: string]: any }>({});
  const [newStateForDiff, setNewStateForDiff] = useState<{ [key: string]: any }>({});
  const [currentStateTitle, setCurrentStateTitle] = useState<string>();
  const [newStateTitle, setNewStateTitle] = useState<string>();

  // Open diff modal and load staged & published Blueprint version JSON's
  const onOpenBlueprintDiffModal = () => {
    setIsDiffModalOpen(true);
    setIsLoadingCurrentStateForDiff(true);
    setIsLoadingNewStateForDiff(true);
    fetchBlueprintVersions({
      blueprintID: component.component_id,
      onSuccess: (versions: BlueprintVersion[]) => {
        setBlueprintVersions(versions);
      },
    });
  };

  // Loads published & staged Blueprint versions, for diff modal
  useEffect(() => {
    if (
      !(blueprintVersions ?? []).find(
        (version) => version.publish_state === BlueprintVersionPublishState.Published
      )
    ) {
      setIsLoadingCurrentStateForDiff(false);
    }
    (blueprintVersions ?? []).forEach((version) => {
      if (version.publish_state === BlueprintVersionPublishState.Staged) {
        fetchBlueprintVersionForIntegration({
          integrationID: integrationID,
          blueprintVersionID: version.id,
          onSuccess: (response: BlueprintWithTrigger) => {
            const { schedule, ...blueprint } = response;
            setNewStateForDiff(blueprint);
            setNewStateTitle(`Blueprint Version - "${version.comment}"`);
            setIsLoadingNewStateForDiff(false);
          },
        });
      } else if (version.publish_state === BlueprintVersionPublishState.Published) {
        fetchBlueprintVersionForIntegration({
          integrationID: integrationID,
          blueprintVersionID: version.id,
          onSuccess: (response: BlueprintWithTrigger) => {
            const { schedule, ...blueprint } = response;
            setCurrentStateForDiff(blueprint);
            setCurrentStateTitle(`Blueprint Version - "${version.comment}"`);
            setIsLoadingCurrentStateForDiff(false);
          },
        });
      }
    });
  }, [blueprintVersions, integrationID]);

  const stagedAtDateString = component?.staged_at
    ? ` on ${new Date(component?.staged_at).toLocaleDateString("en-CA").split("T")[0]}`
    : "";

  const stagedByUserString = component?.staged_by_user ? ` by ${component?.staged_by_user}` : "";

  return (
    <div
      className={`${
        !lastComponent ? "py-4" : "pt-4"
      } flex flex-row items-center w-full justify-between`}
    >
      <div>
        <div className="flex flex-row items-center space-x-2">
          <Text variant="md" className="p-0 m-0">
            {component.name}
          </Text>
        </div>
        <Text variant="title-sm" className="text-gray-70">
          {component.comment}
        </Text>
        <div>
          <Text variant="title-sm" className="text-gray-70">
            {stagedAtDateString || stagedByUserString
              ? `Staged${stagedByUserString}${stagedAtDateString}`
              : "---"}
          </Text>
        </div>
      </div>
      <Menu
        ButtonProps={{
          children: <Ellipsis className="text-gray-60" size={16} />,
          variant: ButtonVariant.IconShadowHidden,
        }}
        menuPlacement="bottom-end"
      >
        <MenuItem onClick={onOpenBlueprintDiffModal}>Compare versions</MenuItem>
        <MenuItem
          onClick={() =>
            navigateToBlueprintEditor(history, integrationID, component.component_version_id, true)
          }
        >
          View blueprint
        </MenuItem>
      </Menu>
      {isDiffModalOpen && (
        <DiffModal
          currentState={currentStateForDiff}
          currentStateTitle={currentStateTitle}
          newState={newStateForDiff}
          newStateTitle={newStateTitle}
          modelType={DiffModelTypeEnum.BLUEPRINT}
          isLoadingStates={isLoadingCurrentStateForDiff || isLoadingNewStateForDiff}
          isModalOpen={isDiffModalOpen}
          setIsModalOpen={setIsDiffModalOpen}
          diffModalType={DiffModalTypeEnum.COMPARE}
        />
      )}
    </div>
  );
};

export default CanaryTestingStagedComponent;

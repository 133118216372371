import { BlueprintParameterSchema } from "../../../../models/Blueprints";
import isEmpty from "lodash/isEmpty";
import { Accordion, Col, Row } from "react-bootstrap";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import MergeModal from "../../../shared/MergeModal";
import { NestedPropertiesButtonToggle } from "../../../shared/MergeToggles";
import CustomJSONTypeaheadFormField from "./CustomJSONTypeaheadFormField";
import {
  ArrayIndexPathComponent,
  JSONPath,
  ObjectPropertyPathComponent,
  parameterKeyForJSONPath,
  schemaSupportsNestedEntries,
} from "./CustomJSONUtils";
import useCustomArrayParametersContext from "./useCustomArrayParametersContext";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";

interface Props {
  parameterSchema: any;
  rootParameterKey?: string;
  expandedNestedKeys: Record<string, boolean>;
  setExpandedNestedKeys: (x: CustomObjectToggles) => void;
  isShowing: boolean;
  setIsShowing: (x: boolean) => void;
}

type CustomObjectToggles = Record<string, boolean>;

const CustomJSONEditorModal = ({
  parameterSchema,
  rootParameterKey,
  expandedNestedKeys,
  setExpandedNestedKeys,
  isShowing,
  setIsShowing,
}: Props) => {
  const {
    getArrayParameterItems,
    canAddItemToArrayParameter,
    addItemToArrayParameter,
    deleteItemFromArrayParameter,
    clearCustomArrayParametersContext,
    clearCustomArrayParametersContextForParameter,
  } = useCustomArrayParametersContext(rootParameterKey);

  const toggleNestedProperties = (parameterKey: string, parameterType: string) => {
    if (parameterType === "array") {
      clearCustomArrayParametersContextForParameter(parameterKey);
    }
    setExpandedNestedKeys({
      ...expandedNestedKeys,
      [parameterKey]: !expandedNestedKeys[parameterKey],
    });
  };

  const collapseAllExpandedSubKeys = (parameterKey: string) => {
    const updatedKeys: Record<string, boolean> = {};
    const parameterPrefix = parameterKey + ".";
    for (const [key, isExpanded] of Object.entries(expandedNestedKeys)) {
      if (isExpanded && key.startsWith(parameterPrefix)) {
        updatedKeys[key] = false;
      }
    }
    setExpandedNestedKeys({
      ...expandedNestedKeys,
      ...updatedKeys,
    });
  };

  /**
   * A row in the editor modal that holds a CustomJSONTypeaheadFormField for the
   * given parameter. If the parameter is an object/array per the parameter's schema,
   * we add an accordion that will expand the parameter into a list of its nested fields.
   */
  const ExpandableParameter = (props: {
    parameterKey: string;
    parameterSchema: any;
    parameterPath: JSONPath;
  }) => {
    const { parameterKey, parameterSchema, parameterPath } = props;
    return (
      <Accordion
        className="row"
        defaultActiveKey={expandedNestedKeys[parameterKey] ? parameterKey : undefined}
      >
        <Col>
          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <div>
                  <DeprecatedH4 className="mb-1.5">{parameterKey}</DeprecatedH4>
                </div>
                {schemaSupportsNestedEntries(parameterSchema) && (
                  <div className="ml-1.5 mb-1.5">
                    <NestedPropertiesButtonToggle
                      eventKey={parameterKey}
                      callback={() => toggleNestedProperties(parameterKey, parameterSchema?.type)}
                    />
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  {!expandedNestedKeys[parameterKey] && (
                    <>
                      {rootParameterKey && (
                        <CustomJSONTypeaheadFormField
                          rootParameterKey={rootParameterKey}
                          parameterPath={parameterPath}
                          parameterType={parameterSchema.type}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <Accordion.Collapse eventKey={parameterKey}>
                {parameterSchema.type === "object" ? (
                  <ObjectParameterPropertyList
                    schema={parameterSchema}
                    parameterPath={parameterPath}
                  />
                ) : (
                  <ArrayParameterItemList schema={parameterSchema} parameterPath={parameterPath} />
                )}
              </Accordion.Collapse>
            </Col>
          </Row>
        </Col>
      </Accordion>
    );
  };

  /**
   * Maps an object parameter's properties into a list of ExpandableParameter entries.
   */
  const ObjectParameterPropertyList = (props: {
    schema: BlueprintParameterSchema<string | null>;
    parameterPath: JSONPath;
  }) => {
    const { schema, parameterPath } = props;
    return (
      <div>
        {!isEmpty(schema) && !isEmpty(schema?.properties) && (
          <>
            {Object.entries(schema?.properties ?? {}).map(([key, parameter]) => {
              const newPathComponent: ObjectPropertyPathComponent = {
                type: "object",
                key: key,
              };
              const newParameterPath = [...parameterPath, newPathComponent];
              const newParameterKey = parameterKeyForJSONPath(newParameterPath);
              return (
                <ExpandableParameter
                  parameterKey={newParameterKey}
                  parameterPath={newParameterPath}
                  parameterSchema={parameter}
                />
              );
            })}
          </>
        )}
      </div>
    );
  };

  /**
   * Maps an array parameter's items into a list of ExpandableParameter entries.
   */
  const ArrayParameterItemList = (props: {
    schema: BlueprintParameterSchema<string | null>;
    parameterPath: JSONPath;
  }) => {
    const { schema, parameterPath } = props;
    const arrayParameterItems = getArrayParameterItems(parameterPath);
    return (
      <div>
        {!isEmpty(schema) && !isEmpty(schema?.items) && rootParameterKey && (
          <div>
            {arrayParameterItems.map((index) => {
              const newPathComponent: ArrayIndexPathComponent = {
                type: "array",
                index: index,
              };
              const newParameterPath = [...parameterPath, newPathComponent];
              const parameter = schema?.items;
              return (
                <>
                  <Button
                    variant={ButtonVariant.SecondaryCharcoal}
                    onClick={() => {
                      deleteItemFromArrayParameter(parameterPath, index);
                      collapseAllExpandedSubKeys(parameterKeyForJSONPath(parameterPath));
                    }}
                  />
                  <ExpandableParameter
                    parameterKey={parameterKeyForJSONPath(newParameterPath)}
                    parameterPath={newParameterPath}
                    parameterSchema={parameter}
                  />
                </>
              );
            })}
            {canAddItemToArrayParameter(parameterPath) && (
              <Button
                className="mt-n2 mb-3 btn-block w-full"
                onClick={() => addItemToArrayParameter(parameterPath)}
              >
                Add New Item
              </Button>
            )}
          </div>
        )}
      </div>
    );
  };

  const parameterType = parameterSchema?.type;
  return (
    <MergeModal
      show={isShowing}
      onShow={clearCustomArrayParametersContext}
      onHide={() => {
        setIsShowing(false);
      }}
      title={`Edit Custom Object for Parameter "${rootParameterKey}"`}
      bodyClassName="overflow-scroll"
    >
      {parameterType === "object" ? (
        <ObjectParameterPropertyList schema={parameterSchema} parameterPath={[]} />
      ) : (
        <ArrayParameterItemList schema={parameterSchema} parameterPath={[]} />
      )}
    </MergeModal>
  );
};

export default CustomJSONEditorModal;

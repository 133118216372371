import { Card, Text, Badge, Link, Tooltip } from "@merge-api/merge-javascript-shared";
import { constructKibanaLinkFromAlertErrorCode } from "../utils/urlHelpers";
import { AdminAlert } from "../../../../../models/Entities";
import { ExternalLink, Info } from "lucide-react";

interface AlertDashboardDrawerErrorCodeDataProps {
  alert: AdminAlert;
  errorCodeCounts: Map<string, number>;
}

const AlertDashboardDrawerErrorCodeData = ({
  alert,
  errorCodeCounts,
}: AlertDashboardDrawerErrorCodeDataProps) => {
  return (
    <div className="mb-6">
      <Text variant="h4" className="mb-5">
        Error codes{" "}
        <Tooltip
          title={
            "Reports all non-200 (and 201) responses from the integration.  640 and 643 are counted as blueprint restarts."
          }
        >
          <div className="flex items-center translate-y-[3px]">
            <Info size={16} className="align-middle" />
          </div>
        </Tooltip>
      </Text>
      <Card className="p-4">
        <div className="space-y-3">
          {[...errorCodeCounts.entries()].map(([code, count], index, array) => (
            <div key={code} className="flex flex-col">
              <div className="flex justify-between items-center">
                <div className="flex gap-3 items-center w-full">
                  <Badge size="lg" color={code === "640" || code === "643" ? "gray" : "yellow"}>
                    <Text variant="h6">{code}</Text>
                  </Badge>
                  <Text variant="md" className="text-gray-50">
                    Count: {count}
                  </Text>
                </div>
                <div className="flex items-center text-blue">
                  <Link
                    variant="h6"
                    href={constructKibanaLinkFromAlertErrorCode(alert, code)}
                    className="flex items-center gap-1"
                    target="_blank"
                  >
                    Kibana
                    <ExternalLink size={16} />
                  </Link>
                </div>
              </div>

              {/* Exclude divider for last item */}
              {index !== array.length - 1 && <div className="mt-3 bg-gray-20 h-[0.5px]" />}
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default AlertDashboardDrawerErrorCodeData;

import { Button, Text } from "@merge-api/merge-javascript-shared";
import IntegrationTabs from "./integration-page/IntegrationTabs";
import useIntegrationData from "./integration-page/useIntegrationData";
import { navigateToIntegrationBuilderPostIntegrationInitialization } from "../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { IntegrationAvatar, AvatarSize } from "../shared/MergeAvatars";
import { Integration } from "../../models/Entities";

type Props = {
  children: JSX.Element | JSX.Element[];
  integration: Integration;
};

const IntegrationPageWrapper = ({ integration, children }: Props) => {
  const { integrationName, categories } = useIntegrationData(integration.id);
  const history = useHistory();

  return (
    <>
      <div className="flex flex-col mt-10">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-4">
            <IntegrationAvatar integration={integration} size={AvatarSize.sm} />
            <Text variant="h2">{integrationName}</Text>
          </div>
          <Button
            size="md"
            onClick={() =>
              navigateToIntegrationBuilderPostIntegrationInitialization(
                history,
                integration.id,
                true
              )
            }
          >
            Edit integration
          </Button>
        </div>

        <IntegrationTabs integrationID={integration.id} categories={categories} />
        {children}
      </div>
    </>
  );
};

export default IntegrationPageWrapper;

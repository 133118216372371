import { Accordion, ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";
import { RateLimitConfigurationIntegrationBuilder } from "../../../utils/Entities";
import RateLimitConfigurationSetupOptions from "../../../rate-limits/components/RateLimitConfigurationSetupOptions";
import APIEndpointRateLimitAccordionTitle from "./APIEndpointRateLimitAccordionTitle";
import React, { useContext, useEffect, useState } from "react";
import RateLimitPublishDialogContent from "../../../rate-limits/components/table/cells/RateLimitPublishDialogContent";
import RateLimitConfigurationContext from "../../../rate-limits/context/RateLimitConfigurationContext";
import APIEndpointContext from "../../context/APIEndpointContext";
import { deleteRateLimitConfiguration } from "../../helpers";
import { RateLimitFormCallback } from "../APIEndpointSetup";
interface APIEndpointRateLimitCardProps {
  rateLimitConfiguration: RateLimitConfigurationIntegrationBuilder;
  index: number;
  registerForm: (formID: string, formFunction: RateLimitFormCallback) => void;
  unregisterForm: (formID: string) => void;
  handleCanSubmitChange: (uniqueKey: string, canSubmit: boolean) => void;
}

interface AdditionalContextProps {
  rateLimitConfigurations: RateLimitConfigurationIntegrationBuilder[] | undefined;
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
  setRateLimitConfigurationsToDelete: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[]>
  >;
}

type APIEndpointRateLimitCardWithContextProps = APIEndpointRateLimitCardProps &
  AdditionalContextProps;

const APIEndpointRateLimitCardWithContext = React.memo(
  ({
    rateLimitConfigurations,
    setRateLimitConfigurations,
    setRateLimitConfigurationsToDelete,
    rateLimitConfiguration,
    index,
    registerForm,
    unregisterForm,
    handleCanSubmitChange,
  }: APIEndpointRateLimitCardWithContextProps) => {
    // state
    const [isPublishModalOpen, setIsPublishModalOpen] = useState<boolean>(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);

    // context
    const {
      isActive,
      setIsActive,
      formRateLimitConfigurationData,
      canSubmitRateLimitConfiguration,
    } = useContext(RateLimitConfigurationContext);

    // hook for passing back canSubmitRateLimitConfiguration to parent component
    useEffect(() => {
      const uniqueKey = rateLimitConfiguration.temp_id || rateLimitConfiguration.id || "";
      handleCanSubmitChange(uniqueKey, canSubmitRateLimitConfiguration);
    }, [canSubmitRateLimitConfiguration]);

    // propogate formRateLimitConfigurationData up to parent APIEndpointSetup component via registerForm
    useEffect(() => {
      // tie each form to either their ID or temp ID if a new form is added
      const formID = rateLimitConfiguration.temp_id || rateLimitConfiguration.id || "";

      registerForm(formID, formRateLimitConfigurationData);

      // cleanup function to unregister the form when the component unmounts
      return () => {
        unregisterForm(formID);
      };
    }, [
      registerForm,
      unregisterForm,
      formRateLimitConfigurationData,
      rateLimitConfiguration.id,
      rateLimitConfiguration.temp_id,
    ]);
    return (
      <div data-testid={`accordion-api-endpoint-rate-limit-config-${index}`}>
        <Accordion
          title={
            <APIEndpointRateLimitAccordionTitle
              isModalOpen={isPublishModalOpen}
              setIsModalOpen={setIsPublishModalOpen}
              cardNumber={index + 1}
              setIsDeletionModalOpen={setIsDeletionModalOpen}
            />
          }
          titleClassName="px-5 py-4"
        >
          <div className="px-5 pb-5">
            <hr className="text-gray-50 h-[0.5px] mt-0 mb-5 -mx-5" />
            <RateLimitConfigurationSetupOptions inlineSetup />
          </div>
        </Accordion>
        <Dialog
          title={isActive ? "Unpublish rate limit" : "Publish rate limit"}
          open={isPublishModalOpen}
          onClose={() => setIsPublishModalOpen(false)}
          primaryButtonVariant={
            isActive ? ButtonVariant.DangerFilled : ButtonVariant.PrimaryCharcoal
          }
          primaryButtonText={isActive ? "Unpublish" : "Publish"}
          onSecondaryButtonClick={() => setIsPublishModalOpen(false)}
          onPrimaryButtonClick={() => {
            setIsActive(!isActive);
            setIsPublishModalOpen(false);
          }}
          children={
            <RateLimitPublishDialogContent
              rateLimitConfiguration={rateLimitConfiguration}
              isInAPIEndpointForm={true}
            />
          }
        />
        <Dialog
          title="Delete rate limit configuration"
          open={isDeletionModalOpen}
          onClose={() => setIsDeletionModalOpen(false)}
          primaryButtonVariant={ButtonVariant.DangerFilled}
          primaryButtonText="Delete configuration"
          onSecondaryButtonClick={() => setIsDeletionModalOpen(false)}
          onPrimaryButtonClick={() => {
            setIsDeletionModalOpen(false);
            deleteRateLimitConfiguration(
              rateLimitConfiguration,
              rateLimitConfigurations,
              setRateLimitConfigurations,
              setRateLimitConfigurationsToDelete
            );
          }}
          children={
            <div className="space-y-2">
              <Text as="div">
                Deleting here will only remove this rate limit configuration from the UI. Please
                click "Save endpoint" for this change to take effect.
              </Text>
            </div>
          }
        />
      </div>
    );
  }
);

// We set up these wrappers to pass the props from the context to the component
// Any changes to the context (even if they are not used in the component) will trigger a re-render
const APIEndpointRateLimitCard = ({
  rateLimitConfiguration,
  index,
  registerForm,
  unregisterForm,
  handleCanSubmitChange,
}: APIEndpointRateLimitCardProps) => {
  const {
    rateLimitConfigurations,
    setRateLimitConfigurations,
    setRateLimitConfigurationsToDelete,
  } = useContext(APIEndpointContext);

  return (
    <APIEndpointRateLimitCardWithContext
      rateLimitConfigurations={rateLimitConfigurations}
      setRateLimitConfigurations={setRateLimitConfigurations}
      setRateLimitConfigurationsToDelete={setRateLimitConfigurationsToDelete}
      rateLimitConfiguration={rateLimitConfiguration}
      index={index}
      registerForm={registerForm}
      unregisterForm={unregisterForm}
      handleCanSubmitChange={handleCanSubmitChange}
    />
  );
};

export default APIEndpointRateLimitCard;

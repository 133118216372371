import MergeModal from "../../shared/MergeModal";
import { Button } from "@merge-api/merge-javascript-shared";

type Props = {
  show?: boolean;
  onHide: () => void;
};

export default function AsyncTestRunUnsavedChangesModal({ show, onHide }: Props) {
  return (
    <>
      <MergeModal
        show={show ?? false}
        onHide={onHide}
        title={"Unsaved Changes"}
        bodyClassName="overflow-hidden"
      >
        <div className="flex flex-col">
          <b>
            You have unsaved changes. You must save your changes in order to initiate and
            asynchronous test run.
          </b>
        </div>
        <div className="mt-6">
          <Button onClick={onHide}>Understood</Button>
        </div>
      </MergeModal>
    </>
  );
}

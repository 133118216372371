import { Card, Text } from "@merge-api/merge-javascript-shared";
import { TriangleAlert } from "lucide-react";

const NoCanaryTestingBox = () => {
  return (
    <Card variant="outline" className="flex flex-row p-4 mb-2 items-center bg-[#F7F8F9]">
      <TriangleAlert className="pr-3" />
      <Text variant="h6">Rolled out without canary testing</Text>
    </Card>
  );
};

export default NoCanaryTestingBox;

import { useState } from "react";
import {
  fetchWithoutAuth,
  UserSuccessData,
  FormErrorData,
  hasAuthToken,
  setAuthTokenAndUserType,
} from "../../api-client/api_client";
import { Link, Redirect } from "react-router-dom";

import cx from "classnames";
import { useForm } from "react-hook-form";
import LogoGray from "../../assets/svg/logo.svg";
import { useMediaQuery } from "react-responsive";
import SpinnerButton from "../shared/SpinnerButton";
import { Col, Form, Row } from "react-bootstrap";
import { showErrorToast } from "../shared/Toasts";
import DeprecatedH1 from "../deprecated/DeprecatedH1";

interface FormData {
  email: string;
  password: string;
  totp_token?: string;
}

function Login() {
  const { register, handleSubmit, setError, errors } = useForm();
  const [isErrorShown, setIsErrorShown] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTwoFactorFieldShown, setIsTwoFactorFieldShown] = useState(false);
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });

  const performLogin = (data: FormData) => {
    setIsLoading(true);
    const userInfo = {
      email: data.email,
      password: data.password,
      totp_token: data.totp_token,
    };
    fetchWithoutAuth({
      path: "/users/login",
      method: "POST",
      body: userInfo,
      onResponse: (data: UserSuccessData) => {
        if (data.token) {
          setAuthTokenAndUserType(data.token, data.user.type);
        }
        setIsLoading(false);
        setIsErrorShown("");
      },
      onError: (err: Response | undefined) => {
        setIsLoading(false);
        setIsErrorShown("");
        if (err) {
          err.json?.().then((data: FormErrorData) => {
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                if (data[field_name][0] === "A two-factor auth token is required for this user.") {
                  setIsTwoFactorFieldShown(true);
                } else {
                  setIsErrorShown(data[field_name][0]);
                }
                continue;
              }
              setError(field_name, { message: data[field_name][0] });
            }
          });
        } else {
          showErrorToast("Unable to connect to the server.");
        }
      },
    });
  };

  const redirectURLPath = new URLSearchParams(window.location.search).get("redirect");

  return (
    <>
      {hasAuthToken() ? <Redirect to={redirectURLPath ?? "/"} /> : null}
      <div className="Login">
        <div
          className={cx(
            "Login-header d-flex align-items-center",
            isDesktopOrTablet ? "justify-content-between" : "justify-content-center"
          )}
        >
          <a href="https://www.merge.dev">
            <img alt="logo" src={LogoGray} />
          </a>
          {isDesktopOrTablet && (
            <p className="mb-0">
              Don’t have an account yet?
              <a href="https://app.merge.dev/signup">
                <button className="btn-sign-up-instead">Sign up</button>
              </a>
            </p>
          )}
        </div>

        <Row className="Login-form d-flex align-items-center justify-content-center">
          <Col className="px-6 col-12 col-md-5 col-xl-4 my-9">
            <DeprecatedH1
              className={cx("display-4 text-center", isDesktopOrTablet ? "mb-3" : "mb-9")}
            >
              Sign In
            </DeprecatedH1>
            {isDesktopOrTablet && <p className="mb-9 text-center">Welcome back to Merge.</p>}

            <Form onSubmit={handleSubmit(performLogin)}>
              <Form.Group hidden={isTwoFactorFieldShown} controlId="email">
                {isErrorShown &&
                  (isTwoFactorFieldShown ? (
                    <div></div>
                  ) : (
                    <div className="alert alert-dark" role="alert">
                      {isErrorShown}
                    </div>
                  ))}
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email"
                  className={cx({ "is-invalid": errors.email })}
                  ref={register({
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group hidden={isTwoFactorFieldShown} controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Password"
                  className={cx({
                    "is-invalid": errors.password,
                  })}
                  ref={register({
                    required: true,
                    minLength: 8,
                    maxLength: 100,
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid password at least 8 characters in length.
                </Form.Control.Feedback>
              </Form.Group>

              {isTwoFactorFieldShown && (
                <Form.Group controlId="totp_token">
                  {isErrorShown && (
                    <div className="alert alert-dark" role="alert">
                      {isErrorShown}
                    </div>
                  )}
                  <Form.Label>Two-factor code</Form.Label>
                  <Form.Control
                    name="totp_token"
                    type="text"
                    className={cx({
                      "is-invalid": errors.totp_token,
                    })}
                    ref={register({
                      required: true,
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid two-factor auth token. If you continue to have trouble,
                    please contact us via live chat in the bottom right.
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Form.Group className="text-center">
                <SpinnerButton
                  text="Sign in"
                  isLoading={isLoading}
                  className="primaryAction btn btn-primary btn-block"
                />
              </Form.Group>
              <Row>
                <Col className="text-center">
                  <a href="https://app.merge.dev/password/send-reset">Forgot password</a>
                  {!isDesktopOrTablet && (
                    <>
                      {" ∙ "}
                      <Link to="/signup">Create account</Link>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Login;

type UserCellProps = {
  name?: string | null;
  email?: string | null;
};

const UserCell = ({ name, email }: UserCellProps) => {
  if (!name && !email) {
    return <td>-</td>;
  }

  return (
    <td>
      <div className="text-base font-medium flex-wrap">{name || "-"}</div>
      <div className="text-xs text-gray-50 flex-wrap">{email || "-"}</div>
    </td>
  );
};

export default UserCell;

import { useState, useEffect } from "react";
import HeaderBar from "../../portal/HeaderBar";
import { Col, Form, Row } from "react-bootstrap";
import { fetchWithAuth } from "../../../api-client/api_client";
import { useHistory, useParams } from "react-router-dom";
import {
  CreateOrUpdateReportTemplateProps,
  Integration,
  ReportTemplate,
} from "../../../models/Entities";
import InputFormField from "../../blueprint-editor/right-panel/InputFormField";
import styled from "styled-components";
import JSONSchemaConverterFormField from "../api-endpoints/JSONSchemaConverterFormField";
import SpinnerButton from "../../shared/SpinnerButton";
import FormField from "../../blueprint-editor/right-panel/FormField";
import { createOrUpdateReportTemplate } from "../utils/IntegrationsAPIClient";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import { navigateToReportTemplatesTable } from "../../../router/RouterUtils";
import ReportTemplateFileSchemaEditor from "./ReportTemplateFileSchemaEditor";
import { ArrowLeft } from "lucide-react";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";

const MAX_ITEMS_PER_COLUMN = 8;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 280px;
  overflow-y: auto;
  margin-bottom: 16px;
`;

const CheckboxColumn = styled.div`
  min-width: 200px;
  padding-right: 16px;
`;

const RequiredColumnCheck = styled(Form.Check)`
  [type="checkbox"] {
    border-radius: 0.375rem;
    border: 1.4px solid #075ff7; /* border width and color from SVG */
    border-radius: 3.5px !important;
  }
  label {
    margin-left: 6px;
    margin-top: 1px;
  }
`;

type Props = {};

type RouteParams = {
  reportTemplateID?: string;
  integrationID: string;
};

function ReportTemplateEditForm(_: Props) {
  const history = useHistory();
  const [integrationName, setIntegrationName] = useState<undefined | string>();
  const { integrationID, reportTemplateID } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<undefined | string>();
  const [namePattern, setNamePattern] = useState<undefined | string>();
  const [isRegexPattern, setIsRegexPattern] = useState<boolean>(false);
  const [returnSchema, setReturnSchema] = useState<undefined | string>();
  const [parsedSchema, setParsedSchema] = useState<Record<string, any> | undefined>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const isEditingReportTemplate = !!reportTemplateID;

  const updateParsedSchemaAndRequiredFields = (returnSchema: string) => {
    try {
      const schema = JSON.parse(returnSchema);
      if (schema) {
        setParsedSchema(schema);
        const newRequiredFields = schema?.properties?.report_rows?.items?.required ?? [];
        setRequiredFields(newRequiredFields);
      }
    } catch (error) {
      setParsedSchema(undefined);
    }
  };

  const handleSetRequiredField = (event: any, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setRequiredFields((prev) => [...prev, key]);
    } else {
      setRequiredFields((prev) => prev.filter((k) => k !== key));
    }

    // Ensure we are not mutating state directly by creating a shallow copy.
    const updatedParsedSchema = { ...parsedSchema };
    if (!updatedParsedSchema?.properties?.report_rows?.items?.required) {
      updatedParsedSchema.properties.report_rows.items.required = [];
    }
    if (isChecked) {
      updatedParsedSchema?.properties?.report_rows?.items.required.push(key);
    } else {
      updatedParsedSchema.properties.report_rows.items.required = updatedParsedSchema?.properties?.report_rows?.items.required.filter(
        (k: string) => k !== key
      );
    }
    setParsedSchema(updatedParsedSchema);
    setReturnSchema(JSON.stringify(updatedParsedSchema));
  };

  useEffect(() => {
    if (returnSchema) {
      updateParsedSchemaAndRequiredFields(returnSchema);
    }
  }, [returnSchema]);

  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/${integrationID}`,
      method: "GET",
      onResponse: (data: Integration) => {
        setIntegrationName(data.name);
      },
    });
    if (isEditingReportTemplate) {
      fetchWithAuth({
        path: `/integrations/${integrationID}/report-templates/${reportTemplateID}`,
        method: "GET",
        onResponse: (data: ReportTemplate) => {
          const {
            name,
            filename_pattern,
            is_filename_pattern_regex,
            required_fields,
            return_schema,
          } = data;
          setName(name);
          setIsRegexPattern(is_filename_pattern_regex);
          setRequiredFields(required_fields);
          setNamePattern(filename_pattern);
          setReturnSchema(JSON.stringify(return_schema));
          setName(data.name);
        },
      });
    }
  }, []);

  const handleSubmit = () => {
    const reportTemplateInfo: CreateOrUpdateReportTemplateProps = {
      integration_id: integrationID,
      name: name || "",
      filename_pattern: namePattern || "",
      is_filename_pattern_regex: isRegexPattern,
      required_fields: requiredFields,
      return_schema: parsedSchema || {},
    };

    if (reportTemplateID) {
      reportTemplateInfo.id = reportTemplateID;
    }

    setIsLoading(true);
    createOrUpdateReportTemplate({
      reportTemplateInfo,
      onSuccess: () => {
        showSuccessToast(
          `Successfully ${isEditingReportTemplate ? "edited" : "created"} report template`
        );
        setIsLoading(false);
        if (!isEditingReportTemplate) navigateToReportTemplatesTable(history, integrationID);
      },
      onError: () => {
        setIsLoading(false);
        showErrorToast(
          `Something went wrong ${
            isEditingReportTemplate ? "editing" : "creating"
          } report template `
        );
      },
    });
  };

  return (
    <div className="ml-6 pt-6">
      <Row>
        <Col>
          <Button
            size="md"
            variant={ButtonVariant.TextBlue}
            leftIcon={<ArrowLeft size={16} />}
            onClick={() => {
              navigateToReportTemplatesTable(history, integrationID);
            }}
          >
            Back to report templates
          </Button>
          <HeaderBar
            pretitle={"Blueprint Dashboard"}
            title={
              (isEditingReportTemplate ? "Edit " : "Add new ") +
              " Report Template for " +
              integrationName
            }
          />
        </Col>
      </Row>

      <InputContainer>
        <Row>
          <Col>
            <InputFormField
              currentValue={name}
              onChange={(name) => setName(name)}
              placeholder=""
              title={"Name"}
              subtitle={"A name for the Report type (e.g.: “Employee Census”)"}
            />
            <InputFormField
              currentValue={namePattern}
              onChange={(namePattern) => setNamePattern(namePattern)}
              placeholder=""
              title={"File name pattern"}
              subtitle={
                "Expected file name as a string (e.g.: “my-employees-*-*-*.csv” or “^my-employees-d{4}-d{2}-d{2}.csv$” for a file name with a date like “my-employees-2023-09-01.csv”)."
              }
              className="mb-3"
            />
            <Form.Check
              type="checkbox"
              label="Select this if the file name pattern is regex"
              onChange={() => {
                setIsRegexPattern(!isRegexPattern);
              }}
              checked={isRegexPattern}
              className="mb-6"
            />
            <JSONSchemaConverterFormField
              currentValue={returnSchema}
              setValue={(returnSchema) => setReturnSchema(returnSchema)}
              numRows={5}
              title={`Return Schema`}
              subtitle={
                "Params returned by the API request. Please follow the directions in the right panel. Copy the final JSON schema and paste it below."
              }
              className="mb-6"
            />
            <FormField
              title={"Required Fields"}
              subtitle={
                "Select the required column names. These will auto-populate based on the return schema above."
              }
              className="mb-1.5"
            >
              <></>
            </FormField>
            <CheckboxContainer>
              {parsedSchema ? (
                Object.keys(parsedSchema?.properties?.report_rows?.items?.properties ?? {})
                  .reduce((acc, key, index) => {
                    const columnIdx = Math.floor(index / MAX_ITEMS_PER_COLUMN);
                    // @ts-ignore
                    if (!acc[columnIdx]) acc[columnIdx] = [];
                    // @ts-ignore
                    acc[columnIdx].push(
                      <RequiredColumnCheck
                        key={key}
                        type="checkbox"
                        label={key}
                        onChange={(event: any) => handleSetRequiredField(event, key)}
                        checked={requiredFields.includes(key)}
                        className="mb-3 form-check-input-new"
                      />
                    );
                    return acc;
                  }, [])
                  .map((column, idx) => <CheckboxColumn key={idx}>{column}</CheckboxColumn>)
              ) : (
                <div>This schema is incorrectly configured</div>
              )}
            </CheckboxContainer>
          </Col>
          <ReportTemplateFileSchemaEditor />
        </Row>
        <SpinnerButton
          text={"Save and Continue editing Report Template"}
          className="btn-block btn-primary mb-6"
          isLoading={isLoading}
          onClick={() => handleSubmit()}
        />
      </InputContainer>
    </div>
  );
}

export default ReportTemplateEditForm;

import React, { useState } from "react";
import { ReactNode } from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import { BlueprintVersionUniqueIdentifierFormatInformation } from "./types";
import { ArrowDown, ArrowUp, Minus } from "lucide-react";

export const hasKeys = (object: { [key: string]: any } | undefined) =>
  Object.keys(object ?? {}).length > 0;

export const CommonModelHasUniqueIdentifierDiscrepancies = (commonModelUniqueIdentifierInfo: {
  [key: string]: {
    [key: string]: string[];
  };
}) => Object.values(commonModelUniqueIdentifierInfo ?? {}).some(hasKeys);

export const RenderUniqueIdentifierRuleAdditionalInformation = (
  additionalInformation: BlueprintVersionUniqueIdentifierFormatInformation
): ReactNode => {
  const {
    blueprint_version_id_to_name,
    unique_identifier_formats_by_blueprint_version_common_model_step_id,
  } = additionalInformation;

  // Initialize the expandedIds Set with all commonModelIds
  const [expandedIds, setExpandedIds] = useState<Set<string>>(
    new Set(Object.keys(unique_identifier_formats_by_blueprint_version_common_model_step_id ?? {}))
  );

  if (
    !unique_identifier_formats_by_blueprint_version_common_model_step_id ||
    !blueprint_version_id_to_name
  ) {
    return <></>;
  }

  const toggleExpand = (event: React.MouseEvent, commonModelId: string) => {
    event.stopPropagation();
    setExpandedIds((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(commonModelId)) {
        newExpanded.delete(commonModelId);
      } else {
        newExpanded.add(commonModelId);
      }
      return newExpanded;
    });
  };

  const renderSteps = (blueprintId: string, commonModelId: string) => {
    const steps =
      unique_identifier_formats_by_blueprint_version_common_model_step_id?.[commonModelId]?.[
        blueprintId
      ];
    return Object.entries(steps ?? {}).map(([stepId, fields]) => (
      <div key={stepId}>
        <Text variant="sm">
          <b>
            {blueprint_version_id_to_name?.[blueprintId] ?? ""}, {stepId.split("::")[1]}:
          </b>
        </Text>{" "}
        <Text variant="sm">{fields.join(", ")}</Text>
      </div>
    ));
  };

  return (
    <>
      {Object.entries(unique_identifier_formats_by_blueprint_version_common_model_step_id).map(
        ([commonModelId, blueprintVersions]) => {
          const processedCommonModelId = commonModelId.split(".")[1];
          return (
            CommonModelHasUniqueIdentifierDiscrepancies(blueprintVersions) && (
              <>
                <div
                  className="text-blue-40 cursor-pointer"
                  onClick={(event: React.MouseEvent) => toggleExpand(event, commonModelId)}
                >
                  <Text variant="sm">{processedCommonModelId}</Text>
                  <span
                    className={`ml-1 fe ${
                      expandedIds.has(commonModelId) ? "fe-chevron-up" : "fe-chevron-down"
                    }`}
                  />
                </div>
                {expandedIds.has(commonModelId) &&
                  Object.keys(blueprintVersions).map((blueprintId) => (
                    <React.Fragment key={blueprintId}>
                      {renderSteps(blueprintId, commonModelId)}
                    </React.Fragment>
                  ))}
              </>
            )
          );
        }
      )}
    </>
  );
};

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// Constructs a date string in the format of 2025-03-06 from a datetime
export const contructDateStringFromDatetime = (startTime: string | null | undefined): string => {
  return startTime
    ? ` on ${new Date(startTime).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      })}`
    : "";
};

export const roundSuccessRate = (successRate: number | undefined | null): string => {
  if (successRate === undefined || successRate === null) {
    return "—";
  }

  const percentage = Math.floor(successRate * 100);
  return `${percentage}%`;
};

export const renderSuccessRateArrow = (
  controlSuccessRate: number,
  testSuccessRate: number
): ReactNode => {
  const controlSuccessRatePercentage = Math.floor(controlSuccessRate * 100);
  const testSuccessRatePercentage = Math.floor(testSuccessRate * 100);
  if (controlSuccessRatePercentage === testSuccessRatePercentage) {
    return <Minus className="text-gray-60 mr-2" width={16} height={16} />;
  }

  if (testSuccessRatePercentage > controlSuccessRatePercentage) {
    return <ArrowUp className="text-teal-50 mr-2" width={16} height={16} />;
  } else {
    return <ArrowDown className="text-red-50 mr-2" width={16} height={16} />;
  }
};

export const trimStringToSize = (
  blueprintName: string | null,
  blueprintVersionId: string | null,
  numChars: number = 45
): string => {
  if (!blueprintName) return blueprintVersionId || "";
  if (blueprintName.length <= numChars) return blueprintName;

  return blueprintName.substring(0, numChars) + "…";
};

import { Badge } from "@merge-api/merge-javascript-shared";
import { firstLetterUpperCase } from "../../../../utils";
import { IntegrationTier } from "./utils/constants";

interface SeverityCellProps {
  severity: IntegrationTier;
}

const SeverityColorMap: Record<IntegrationTier, "red" | "orange" | "yellow" | "redStrong"> = {
  [IntegrationTier.TIER_4]: "yellow",
  [IntegrationTier.TIER_3]: "orange",
  [IntegrationTier.TIER_2]: "red",
  [IntegrationTier.TIER_1]: "red",
};

const SeverityCell = ({ severity }: SeverityCellProps) => {
  const severityColor = severity
    ? SeverityColorMap[severity]
    : SeverityColorMap[IntegrationTier.TIER_4];
  return (
    <Badge color={severityColor} className={`text-left-align font-medium`}>
      {firstLetterUpperCase(severity)}
    </Badge>
  );
};

export default SeverityCell;

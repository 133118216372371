import {
  ArrowLeftRight,
  Files,
  Globe,
  Key,
  Link,
  ListFilter,
  PanelLeft,
  Settings,
  Timer,
  Wand2,
} from "lucide-react";
import { useContext, useMemo } from "react";
import { CHECKLIST_VALUE_TO_BUILDER_STATUS } from "../../../constants";
import {
  INTEGRATION_BUILDER_PATH_INITIALIZATION,
  getIntegrationBuilderAISearchPathForId,
  getIntegrationBuilderAPIEndpointPathForId,
  getIntegrationBuilderAPIEndpointsConfigurationPathForId,
  getIntegrationBuilderAuthenticationConfigurationPathForId,
  getIntegrationBuilderAutomatedModifiedAtFilters,
  getIntegrationBuilderCustomerFacingFieldsPathForId,
  getIntegrationBuilderInitializationPathForId,
  getIntegrationBuilderLinkingFlowStepsPathConfigurationForId,
  getIntegrationBuilderLinkingFlowStepsPathForId,
  getIntegrationBuilderPaginationConfigurationsForId,
  getIntegrationBuilderRateLimitConfigurationPathForId,
  getIntegrationBuilderSelectiveSyncFilterBuilder,
  getIntegrationBuilderWebAddressSchemaConfigurationPathForId,
  getIntegrationBuilderWebAddressSchemaForId,
} from "../../../router/RouterUtils";
import { MergeFlagFeature, useMergeFlag } from "../../shared/hooks/useMergeFlag";
import IntegrationBuilderContext from "../context/IntegrationBuilderContext";
import { BuilderStatus, Section } from "./Entities";

const useIntegrationBuilderSections = (id: string | undefined): Section[] => {
  const isDisabled = !id;
  const {
    integration,
    isIntegrationInitializationComplete,
    aiSearchConfigurationStatus,
    isWebAddressSchemaComplete,
    isAuthenticationConfigurationComplete,
    isLinkingFlowStepPathComplete,
    isAPIEndpointsComplete,
    isPaginationConfigurationComplete,
    isRateLimitConfigurationComplete,
    isCustomerFacingFieldsComplete,
    isBaseAPIURLComplete,
    isAPIEndpointsConfigFieldsComplete,
    isSetupStepsConfigFieldsComplete,
  } = useContext(IntegrationBuilderContext);

  const { enabled: isAIFlagEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_AI_ENDPOINTS,
    isEnabledForUser: true,
  });

  const isAIEnabled = useMemo(() => isAIFlagEnabled && integration?.is_soap_integration === false, [
    isAIFlagEnabled,
    integration,
  ]);

  const tabs = [
    {
      name: "Integration initialization",
      state: isIntegrationInitializationComplete
        ? BuilderStatus.COMPLETED
        : BuilderStatus.NOT_COMPLETED,
      path: id
        ? getIntegrationBuilderInitializationPathForId(id)
        : INTEGRATION_BUILDER_PATH_INITIALIZATION,
      disabled: false,
      icon: <Settings size={16} />,
      sectionIdentifier: "initialization",
      nextSectionIdentifier: isAIEnabled
        ? "ai-search"
        : isBaseAPIURLComplete
        ? "web-address-schemas"
        : "web-address-schemas-configuration",
      previousSectionIdentifier: null,
    },
    ...(isAIEnabled
      ? [
          {
            name: "AI search",
            state: CHECKLIST_VALUE_TO_BUILDER_STATUS[aiSearchConfigurationStatus],
            path: id
              ? getIntegrationBuilderAISearchPathForId(id)
              : INTEGRATION_BUILDER_PATH_INITIALIZATION,
            disabled: isDisabled,
            icon: <Wand2 size={16} />,
            sectionIdentifier: "ai-search",
            nextSectionIdentifier: isBaseAPIURLComplete
              ? "web-address-schemas"
              : "web-address-schemas-configuration",
            previousSectionIdentifier: "initialization",
          },
        ]
      : []),
    {
      name: "Web address schemas",
      state:
        isBaseAPIURLComplete && isWebAddressSchemaComplete
          ? BuilderStatus.COMPLETED
          : BuilderStatus.NOT_COMPLETED,
      path: !id
        ? INTEGRATION_BUILDER_PATH_INITIALIZATION
        : isBaseAPIURLComplete
        ? getIntegrationBuilderWebAddressSchemaForId(id)
        : getIntegrationBuilderWebAddressSchemaConfigurationPathForId(id),
      disabled: isDisabled,
      icon: <Globe size={16} />,
      sectionIdentifier: isBaseAPIURLComplete
        ? "web-address-schemas"
        : "web-address-schemas-configuration",
      nextSectionIdentifier: isBaseAPIURLComplete
        ? "authentication-configurations"
        : "web-address-schemas",
      previousSectionIdentifier: isBaseAPIURLComplete
        ? "web-address-schemas-configuration"
        : isAIEnabled
        ? "ai-search"
        : "initialization",
      subSections: [
        {
          name: "Base API URL",
          state: isBaseAPIURLComplete ? BuilderStatus.COMPLETED : BuilderStatus.NOT_COMPLETED,
          path: id
            ? getIntegrationBuilderWebAddressSchemaConfigurationPathForId(id)
            : INTEGRATION_BUILDER_PATH_INITIALIZATION,
          disabled: isDisabled,
          sectionIdentifier: "web-address-schemas-configuration",
          nextSectionIdentifier: "web-address-schemas",
          previousSectionIdentifier: isAIEnabled ? "ai-search" : "initialization",
        },
        {
          name: "Web address schemas",
          state: isWebAddressSchemaComplete ? BuilderStatus.COMPLETED : BuilderStatus.NOT_COMPLETED,
          path: id
            ? getIntegrationBuilderWebAddressSchemaForId(id)
            : INTEGRATION_BUILDER_PATH_INITIALIZATION,
          disabled: isDisabled,
          sectionIdentifier: "web-address-schemas",
          nextSectionIdentifier: "authentication-configurations",
          previousSectionIdentifier: "web-address-schemas-configuration",
        },
      ],
    },
    {
      name: "Authentication configurations",
      state: isAuthenticationConfigurationComplete
        ? BuilderStatus.COMPLETED
        : BuilderStatus.NOT_COMPLETED,
      path: id
        ? getIntegrationBuilderAuthenticationConfigurationPathForId(id)
        : INTEGRATION_BUILDER_PATH_INITIALIZATION,
      disabled: isDisabled,
      icon: <Key size={16} />,
      sectionIdentifier: "authentication-configurations",
      nextSectionIdentifier: isSetupStepsConfigFieldsComplete
        ? "merge-link-steps"
        : "merge-link-steps-configuration",
      previousSectionIdentifier: "web-address-schemas",
    },
    {
      name: "Merge Link steps",
      state:
        isSetupStepsConfigFieldsComplete && isLinkingFlowStepPathComplete
          ? BuilderStatus.COMPLETED
          : BuilderStatus.NOT_COMPLETED,
      disabled: isDisabled,
      path: !id
        ? INTEGRATION_BUILDER_PATH_INITIALIZATION
        : isSetupStepsConfigFieldsComplete
        ? getIntegrationBuilderLinkingFlowStepsPathForId(id)
        : getIntegrationBuilderLinkingFlowStepsPathConfigurationForId(id),
      icon: <Link size={16} />,
      sectionIdentifier: isSetupStepsConfigFieldsComplete
        ? "merge-link-steps"
        : "merge-link-steps-configuration",
      nextSectionIdentifier: isSetupStepsConfigFieldsComplete
        ? "pagination-configurations"
        : "merge-link-steps",
      previousSectionIdentifier: isSetupStepsConfigFieldsComplete
        ? "merge-link-steps-configuration"
        : "authentication-configurations",
      subSections: [
        {
          name: "Configuration",
          state: isSetupStepsConfigFieldsComplete
            ? BuilderStatus.COMPLETED
            : BuilderStatus.NOT_COMPLETED,
          path: id
            ? getIntegrationBuilderLinkingFlowStepsPathConfigurationForId(id)
            : INTEGRATION_BUILDER_PATH_INITIALIZATION,
          disabled: isDisabled,
          sectionIdentifier: "merge-link-steps-configuration",
          nextSectionIdentifier: "merge-link-steps",
          previousSectionIdentifier: "authentication-configurations",
        },
        {
          name: "Merge Link steps",
          state: isLinkingFlowStepPathComplete
            ? BuilderStatus.COMPLETED
            : BuilderStatus.NOT_COMPLETED,
          path: id
            ? getIntegrationBuilderLinkingFlowStepsPathForId(id)
            : INTEGRATION_BUILDER_PATH_INITIALIZATION,
          disabled: isDisabled,
          sectionIdentifier: "merge-link-steps",
          nextSectionIdentifier: "pagination-configurations",
          previousSectionIdentifier: "merge-link-steps-configuration",
        },
      ],
    },
    {
      name: "Pagination configurations",
      state: isPaginationConfigurationComplete
        ? BuilderStatus.COMPLETED
        : BuilderStatus.NOT_COMPLETED,
      path: id
        ? getIntegrationBuilderPaginationConfigurationsForId(id)
        : INTEGRATION_BUILDER_PATH_INITIALIZATION,
      disabled: isDisabled,
      icon: <Files size={16} />,
      sectionIdentifier: "pagination-configurations",
      nextSectionIdentifier: "rate-limit-configurations",
      previousSectionIdentifier: "merge-link-steps",
    },
    {
      name: "Rate limits",
      state: isRateLimitConfigurationComplete
        ? BuilderStatus.COMPLETED
        : BuilderStatus.NOT_COMPLETED,
      path: id
        ? getIntegrationBuilderRateLimitConfigurationPathForId(id)
        : INTEGRATION_BUILDER_PATH_INITIALIZATION,
      disabled: isDisabled,
      icon: <Timer size={16} />,
      sectionIdentifier: "rate-limit-configurations",
      nextSectionIdentifier: isAPIEndpointsConfigFieldsComplete
        ? "api-endpoints"
        : "api-endpoints-configuration",
      previousSectionIdentifier: "pagination-configurations",
    },
    {
      name: "API endpoints",
      state:
        isAPIEndpointsConfigFieldsComplete && isAPIEndpointsComplete
          ? BuilderStatus.COMPLETED
          : BuilderStatus.NOT_COMPLETED,
      path: !id
        ? INTEGRATION_BUILDER_PATH_INITIALIZATION
        : isAPIEndpointsConfigFieldsComplete
        ? getIntegrationBuilderAPIEndpointPathForId(id)
        : getIntegrationBuilderAPIEndpointsConfigurationPathForId(id),
      disabled: isDisabled,
      icon: <ArrowLeftRight size={16} />,
      sectionIdentifier: isAPIEndpointsConfigFieldsComplete
        ? "api-endpoints"
        : "api-endpoints-configuration",
      nextSectionIdentifier: isAPIEndpointsConfigFieldsComplete
        ? "customer-facing-fields"
        : "api-endpoints",
      previousSectionIdentifier: isAPIEndpointsConfigFieldsComplete
        ? "api-endpoints-configuration"
        : "rate-limit-configurations",
      subSections: [
        {
          name: "Configuration",
          state: isAPIEndpointsConfigFieldsComplete
            ? BuilderStatus.COMPLETED
            : BuilderStatus.NOT_COMPLETED,
          path: id
            ? getIntegrationBuilderAPIEndpointsConfigurationPathForId(id)
            : INTEGRATION_BUILDER_PATH_INITIALIZATION,
          disabled: isDisabled,
          sectionIdentifier: "api-endpoints-configuration",
          nextSectionIdentifier: "api-endpoints",
          previousSectionIdentifier: "rate-limit-configurations",
        },
        {
          name: "API endpoints",
          state: isAPIEndpointsComplete ? BuilderStatus.COMPLETED : BuilderStatus.NOT_COMPLETED,
          path: id
            ? getIntegrationBuilderAPIEndpointPathForId(id)
            : INTEGRATION_BUILDER_PATH_INITIALIZATION,
          disabled: isDisabled,
          sectionIdentifier: "api-endpoints",
          nextSectionIdentifier: "customer-facing-fields",
          previousSectionIdentifier: "api-endpoints-configuration",
        },
      ],
    },
    {
      name: "Customer-facing fields",
      state: isCustomerFacingFieldsComplete ? BuilderStatus.COMPLETED : BuilderStatus.NOT_COMPLETED,
      path: id
        ? getIntegrationBuilderCustomerFacingFieldsPathForId(id)
        : INTEGRATION_BUILDER_PATH_INITIALIZATION,
      disabled: isDisabled,
      icon: <PanelLeft size={16} />,
      sectionIdentifier: "customer-facing-fields",
      nextSectionIdentifier: "selective-sync-filters",
      previousSectionIdentifier: "api-endpoints",
    },
    {
      name: "Selective Sync filters",
      state: BuilderStatus.NOT_COMPLETED,
      path: id
        ? getIntegrationBuilderSelectiveSyncFilterBuilder(id)
        : INTEGRATION_BUILDER_PATH_INITIALIZATION,
      disabled: isDisabled,
      icon: <ListFilter size={16} />,
      sectionIdentifier: "selective-sync-filters",
      nextSectionIdentifier: null,
      previousSectionIdentifier: "customer-facing-fields",
      subSections: [
        {
          name: "Selective Sync filters",
          state: BuilderStatus.NOT_COMPLETED,
          path: id
            ? getIntegrationBuilderSelectiveSyncFilterBuilder(id)
            : INTEGRATION_BUILDER_PATH_INITIALIZATION,
          disabled: isDisabled,
          sectionIdentifier: "selective-sync-filters",
          nextSectionIdentifier: "automated-modified_at-filters",
          previousSectionIdentifier: "customer-facing-fields",
        },
        {
          name: "Automated modified_at filters",
          state: BuilderStatus.NOT_COMPLETED,
          path: id
            ? getIntegrationBuilderAutomatedModifiedAtFilters(id)
            : INTEGRATION_BUILDER_PATH_INITIALIZATION,
          disabled: isDisabled,
          sectionIdentifier: "automated-modified-at-filters",
          nextSectionIdentifier: null,
          previousSectionIdentifier: "selective-sync-filters",
        },
      ],
    },
  ];

  return tabs;
};

export default useIntegrationBuilderSections;

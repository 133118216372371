import { Accordion } from "react-bootstrap";
import MergeTable from "../../../shared/MergeTable";
import TableSkeletonLoader from "../../../shared/TableSkeletonLoader";
import EmptyStateWrapper from "../../../shared/layout/EmptyStateWrapper";
import { ChangelogEvent } from "../utils/types";
import ChangelogTableRow from "./ChangelogTableRow/ChangelogTableRow";
import { groupChangelogEventsByCanaryId } from "../utils/helpers";
import CombinedChangelogTableRow from "./ChangelogTableRow/CombinedChangelogTableRow";

interface ChangelogTableProps {
  changelogEvents?: ChangelogEvent[];
  isLoading: boolean;
  className?: string;
}

const ChangelogTable = ({ className, changelogEvents = [], isLoading }: ChangelogTableProps) => {
  const combinedChangelogEvents = groupChangelogEventsByCanaryId(changelogEvents);

  const renderContent = () => {
    if (isLoading) {
      return <TableSkeletonLoader cols={4} rows={8} size="large" />;
    }

    if (!changelogEvents.length) {
      return (
        <tr>
          <td colSpan={5} className="py-9">
            <EmptyStateWrapper isTable title="No changelog events" />
          </td>
        </tr>
      );
    }

    return (
      <>
        {combinedChangelogEvents.map((event) => {
          if (Array.isArray(event)) {
            return <CombinedChangelogTableRow key={event[0].id} changelogEvents={event} />;
          } else {
            return <ChangelogTableRow key={event.id} changelogEvent={event} />;
          }
        })}
      </>
    );
  };

  return (
    <div className={className}>
      <Accordion>
        <MergeTable
          hover={false}
          hasMorePaddingOnFirstElement
          header={
            <>
              <th scope="col">User</th>
              <th scope="col">Model</th>
              <th scope="col">Event type</th>
              <th scope="col">Date</th>
              <th scope="col" />
            </>
          }
          content={renderContent()}
        />
      </Accordion>
    </div>
  );
};

export default ChangelogTable;

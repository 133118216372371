import { useState } from "react";
import { CanaryTestResults } from "../types";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast } from "../../../shared/Toasts";

interface IntegrationProps {
  integrationID: string;
}

interface CanaryProps {
  canaryTestID: string;
}

const useLoadCanaryTestResults = () => {
  const [canaryTestResults, setCanaryTestResults] = useState<CanaryTestResults | undefined>(
    undefined
  );

  const fetchCanaryTestResultsByIntegration = (integrationID: string) => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/canary-test-results`,
      method: "GET",
      onResponse: (data: CanaryTestResults) => {
        setCanaryTestResults(data);
      },
      onError: () => {
        showErrorToast("Error fetching canary test results by integration");
      },
    });
  };

  const fetchCanaryTestResultsByID = (canaryTestID: string) => {
    fetchWithAuth({
      path: `/integrations/canary-test-results/${canaryTestID}`,
      method: "GET",
      onResponse: (data: CanaryTestResults) => {
        setCanaryTestResults(data);
      },
      onError: () => {
        showErrorToast("Error fetching canary test results by ID");
      },
    });
  };

  return {
    canaryTestResults,
    setCanaryTestResults,
    fetchCanaryTestResultsByIntegration,
    fetchCanaryTestResultsByID,
  };
};

// Wrapper hooks for specific use cases
export const useLoadCanaryTestResultsForIntegration = ({ integrationID }: IntegrationProps) => {
  const {
    canaryTestResults,
    setCanaryTestResults,
    fetchCanaryTestResultsByIntegration,
  } = useLoadCanaryTestResults();

  const fetchCanaryTestResults = () => {
    fetchCanaryTestResultsByIntegration(integrationID);
  };

  return {
    canaryTestResults,
    setCanaryTestResults,
    fetchCanaryTestResults,
  };
};

export const useLoadCanaryTestResultsByID = ({ canaryTestID }: CanaryProps) => {
  const {
    canaryTestResults,
    setCanaryTestResults,
    fetchCanaryTestResultsByID,
  } = useLoadCanaryTestResults();

  const fetchCanaryTestResults = () => {
    fetchCanaryTestResultsByID(canaryTestID);
  };

  return {
    canaryTestResults,
    setCanaryTestResults,
    fetchCanaryTestResults,
  };
};

export default useLoadCanaryTestResults;

import useBlueprintContext from "../context/useBlueprintContext";
import {
  toggleBlueprintIsLongRunning,
  updateBlueprintName,
} from "../utils/BlueprintEditorAPIClient";
import { useHistory } from "react-router-dom";
import { getStaleParameterValueKeysForStep, getStepForStepID } from "../utils/BlueprintEditorUtils";
import { EditableText } from "../../shared/EditableText";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import MergeText, { TextType } from "../../shared/text/MergeText";
import { fetchBlueprintReadme, updateBlueprintReadme } from "../utils/BlueprintEditorAPIClient";
import OverviewReadmePanel from "../../shared/readme/OverviewReadmePanel";
import OverviewSection from "../../shared/layout/OverviewSection";
import { useState } from "react";
import {
  BlueprintStep,
  BlueprintVersionPublishState,
  LEFT_ALIGN_COOKIE_KEY,
} from "../../../models/Blueprints";
import styled from "styled-components";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import MergeCopyToClipboard from "../../shared/MergeCopyToClipboard";
import { Badge, Text } from "@merge-api/merge-javascript-shared";
import { getBadgeColorForCoveragePercent } from "../../shared/utils/SharedComponentUtils";
import {
  navigateToIndividualReportTemplate,
  navigateToTestSuitesTable,
} from "../../../router/RouterUtils";
import CheckboxFormField from "../right-panel/CheckboxFormField";
import { useCookies } from "react-cookie";

const StepWithStaleParamCard = styled.div`
  border-radius: 6px;
  border: 1px solid #d2ddec;
  font-size: 12px;
  margin-bottom: 12px;
  padding: 4px;
`;

const StaleParamKey = styled.div`
  font-size: 10px;
  padding: 0px;
`;

const humanizeEnum = (string: String) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const BlueprintEditorLeftPanelOverviewSubtab = () => {
  const {
    blueprint,
    setBlueprintHumanName,
    setSelectedStep,
    blueprintVersions,
    backendStaleParameters,
  } = useBlueprintContext();
  const {
    name,
    operation_type,
    mapped_models,
    version,
    endpoints,
    id,
    human_name,
    integration,
    is_long_running,
    report_template_id,
  } = blueprint;

  const [isLongRunning, setIsLongRunning] = useState<boolean>(is_long_running ?? false);
  const history = useHistory();

  const [cookies, setCookie, removeCookie] = useCookies([LEFT_ALIGN_COOKIE_KEY]);

  const test_coverage_percent = blueprintVersions.find(
    (bp) => bp.publish_state === BlueprintVersionPublishState.Published
  )?.test_coverage?.coverage_percent;

  const findStaleParamsRecursively = (
    step: BlueprintStep,
    result: { [step_id: string]: Set<string> }
  ) => {
    const staleKeys = getStaleParameterValueKeysForStep(blueprint, step);
    // If we find stale keys, add it to the result
    if (staleKeys.size > 0) result[step.id] = staleKeys;

    // Recursive case
    Object.entries(step?.paths ?? []).forEach(([_, path]) => {
      path.forEach((step) => findStaleParamsRecursively(step, result));
    });

    return result;
  };

  const handleStaleParamCardClick = (stepID: string) => {
    const step = getStepForStepID(blueprint, stepID) as BlueprintStep;
    setSelectedStep(step);
    const cardElement = document.getElementById(`${step.id}-${step.template.name}`);
    if (cardElement) {
      cardElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const onSuccess = (response: any) => {
    setBlueprintHumanName(response.human_name);
  };

  const handleToggleBlueprintIsLongRunning = (newIsLongRunningValue: boolean) => {
    toggleBlueprintIsLongRunning({
      blueprintID: blueprint.id,
      isLongRunning: newIsLongRunningValue,
      onSuccess: (_: any) => {
        showSuccessToast("Successfully updated is_long_running value for Blueprint");
        setIsLongRunning(newIsLongRunningValue);
      },
      onError: (_: any) => {
        showErrorToast("Failed to update is_long_running value for Blueprint");
      },
    });
  };

  const commonModelsPills = (
    <div>
      {mapped_models.length > 0 ? (
        mapped_models.map((model) => <Badge className="gray">{model}</Badge>)
      ) : (
        <MergeText size="14px" type={TextType.MUTED2} isBold>
          None
        </MergeText>
      )}
    </div>
  );

  const docsLink = integration.api_documentation_url ? (
    <a href={integration.api_documentation_url} target="_blank" rel="noreferrer">
      <MergeText size="14px" type={TextType.BLUE} isBold>
        Docs
      </MergeText>
    </a>
  ) : (
    <MergeText size="14px" type={TextType.MUTED2} isBold>
      None
    </MergeText>
  );

  const APIEndpointsPills = (
    <div>
      {endpoints.length > 0 ? (
        endpoints.map((endpoint) => (
          <Badge className="blue">{`${endpoint.method} ${endpoint.path}`}</Badge>
        ))
      ) : (
        <MergeText size="14px" type={TextType.MUTED2} isBold>
          None
        </MergeText>
      )}
    </div>
  );

  const onSaveBlueprintName = (newName: string) => {
    updateBlueprintName({ name: newName, blueprintID: id, onSuccess });
  };

  // Certain fields will be rendered conditionally, depending ont he operation type of the Blueprint
  const sections = [
    <OverviewSection
      title="Blueprint Name"
      overviewBodyTextClassName="w-100"
      content={<EditableText originalText={human_name || name} onSave={onSaveBlueprintName} />}
    />,
    <OverviewSection
      title="Blueprint ID"
      content={
        <div className="d-flex align-items-center">
          <Text className="mr-1.5" variant="h6">
            {blueprint.id}
          </Text>
          <MergeCopyToClipboard textToCopy={blueprint.id} />
        </div>
      }
    />,
    <OverviewSection
      title="Blueprint version ID"
      content={
        <div className="d-flex align-items-center">
          <Text className="mr-1.5" variant="h6">
            {blueprint.version.id}
          </Text>
          <MergeCopyToClipboard textToCopy={blueprint.version.id} />
        </div>
      }
    />,
    <OverviewSection
      title="Publish Status"
      content={
        <MergeText size="14px" type={TextType.BLACK} isBold>
          {humanizeEnum(version.publish_state ?? "")}
        </MergeText>
      }
    />,
    <OverviewSection title="Blueprint Type" content={<>{humanizeEnum(operation_type ?? "")}</>} />,
    <OverviewSection
      title="Test coverage %"
      linkName="Mapping tests"
      onClick={() => {
        navigateToTestSuitesTable(history, integration.id, true);
      }}
      content={
        test_coverage_percent != null &&
        test_coverage_percent != undefined && (
          <Badge size="lg" color={getBadgeColorForCoveragePercent(test_coverage_percent)}>
            {Math.round(test_coverage_percent)}
          </Badge>
        )
      }
    />,
    <OverviewSection title="API Endpoints Used" content={APIEndpointsPills} />,
    <OverviewSection title="Created or Updated Common Models" content={commonModelsPills} />,
    report_template_id ? (
      <OverviewSection
        title="Report Template ID"
        linkName="Report Template"
        onClick={() =>
          navigateToIndividualReportTemplate(history, integration.id, report_template_id, true)
        }
        content={
          <div className="d-flex align-items-center">
            <Text className="mr-1.5" variant="h6">
              {report_template_id}
            </Text>
            <MergeCopyToClipboard textToCopy={report_template_id} />
          </div>
        }
      />
    ) : (
      <></>
    ),
    <OverviewSection title={`${integration.name} API Documentation`} content={docsLink} />,
    <OverviewSection
      title="Backend Stale Parameters (Alpha)"
      collapsible={true}
      content={
        backendStaleParameters
          ? Object.entries(backendStaleParameters?.stale_parameters_info ?? {}).map(
              ([stepID, paramsInfo]) =>
                paramsInfo.length > 0 && (
                  <StepWithStaleParamCard
                    onClick={() => handleStaleParamCardClick(stepID.split("::").slice(-1)[0])}
                  >
                    {stepID.split("::").slice(-1)}
                    {paramsInfo.map((paramInfo) => (
                      <StaleParamKey key={paramInfo.key_path.join(",")}>
                        <MergeText type={TextType.DANGER}>
                          {paramInfo.key_path.join(", ")}
                        </MergeText>
                      </StaleParamKey>
                    ))}
                  </StepWithStaleParamCard>
                )
            )
          : null
      }
    />,

    <OverviewSection
      title={`Blueprint is long running`}
      content={
        <Form.Check
          type="checkbox"
          label={
            <div className="d-flex align-items-center">
              Is long running
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-is-long-running">
                    Blueprints that are long running get sent to our slow-blueprint celery queue.
                    The pods in this queue have more memory than fast-blueprint pods, and support
                    concurrency
                  </Tooltip>
                }
                placement="top"
              >
                <i className="ml-1.5 text-muted fe fe-info" />
              </OverlayTrigger>
            </div>
          }
          defaultChecked={isLongRunning}
          onChange={() => handleToggleBlueprintIsLongRunning(!isLongRunning)}
        />
      }
    />,
    <OverviewReadmePanel
      fetchReadme={(props) => fetchBlueprintReadme({ blueprintID: blueprint.id, ...props })}
      updateReadme={(props) => updateBlueprintReadme({ blueprintID: blueprint.id, ...props })}
    />,
    <OverviewSection
      title="BPE settings"
      content={
        <CheckboxFormField
          title="Left align steps"
          currentValue={cookies.leftAlignSteps}
          onChange={(newValue) => {
            if (newValue) {
              setCookie("leftAlignSteps", newValue, { path: "/" });
            } else {
              removeCookie("leftAlignSteps", { path: "/" });
            }
          }}
        />
      }
    />,
  ];

  return <>{sections.map((section) => section)}</>;
};

export default BlueprintEditorLeftPanelOverviewSubtab;

import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { Plus } from "lucide-react";
import UnpublishedBlueprintEmptyState from "./UnpublishedBlueprintEmptyState";
import { useStagedComponentContext } from "../../context/StagedComponentContext";

const CommonModelDiffRunTab = () => {
  const { hasPublishedBlueprint } = useStagedComponentContext();
  return (
    <div className="pt-6 pb-5">
      {hasPublishedBlueprint ? (
        <Button
          leftIcon={<Plus size={16} />}
          onClick={function noRefCheck() {}}
          size="md"
          variant={ButtonVariant.TextBlue}
        >
          Add Common Model diff run
        </Button>
      ) : (
        <UnpublishedBlueprintEmptyState />
      )}
    </div>
  );
};

export default CommonModelDiffRunTab;

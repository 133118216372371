import clsx from "clsx";
import CanaryTestingBlueprintSuccessRateRow from "../integrations/publish-requests/CanaryTesting/CanaryTestingBlueprintSuccessRateRow";
import { CanaryTestResults } from "../integrations/publish-requests/types";
import { renderSuccessRateArrow, roundSuccessRate } from "../integrations/publish-requests/utils";
import { Card, Text, Tooltip } from "@merge-api/merge-javascript-shared";

import { Info } from "lucide-react";

type CanaryTestResultsProps = {
  canaryTestResults: CanaryTestResults | undefined;
};

const CanaryTestResultsTables = ({ canaryTestResults }: CanaryTestResultsProps) => {
  const controlSuccessRate = canaryTestResults?.control_success_rate;
  const testSuccessRate = canaryTestResults?.test_success_rate;
  const controlSuccessRateString = roundSuccessRate(canaryTestResults?.control_success_rate);
  const testSuccessRateString = roundSuccessRate(canaryTestResults?.test_success_rate);
  const shouldRenderArrow =
    testSuccessRate !== undefined &&
    testSuccessRate !== null &&
    controlSuccessRate !== undefined &&
    controlSuccessRate !== null;

  const blueprintsUnderTesting = canaryTestResults?.blueprints_under_testing_rates.blueprints;
  const topChangedBlueprints = canaryTestResults?.top_blueprints_rates.blueprints;

  return (
    <>
      <div className="py-4 pl-3 grid grid-cols-12 gap-4 w-full">
        <div className="col-span-6">
          <Text variant="h6">Area</Text>
        </div>
        <div className="col-span-3 flex w-full items-center">
          <Text variant="h6" className="pr-2">
            Control success rate
          </Text>
          <Tooltip title="The control group measures success rate during the last 2 weeks">
            <Info className="text-gray-60" width={16} height={16} />
          </Tooltip>
        </div>
        <div className="col-span-3 flex w-full items-center">
          <Text variant="h6" className="pr-2">
            Test success rate
          </Text>
          <Tooltip title="The test group includes Merge, Free and Launch linked accounts">
            <Info className="text-gray-60" width={16} height={16} />
          </Tooltip>
        </div>
      </div>
      <Card variant="outline" className="mb-3">
        <div className="grid grid-cols-12 gap-4 w-full">
          <div className="py-3 px-2 flex w-full col-span-6">
            <Text variant="h6">Overall</Text>
          </div>
          <div className="py-3 pl-2 flex w-full col-span-3">
            <Text variant="h6">{controlSuccessRateString}</Text>
          </div>
          <div className="py-3 col-span-3 flex items-center">
            {shouldRenderArrow && renderSuccessRateArrow(controlSuccessRate, testSuccessRate)}
            <Text variant="h6">{testSuccessRateString}</Text>
          </div>
        </div>
      </Card>

      {blueprintsUnderTesting && (
        <Card variant="outline" className="mb-3">
          <div className="grid grid-cols-12 gap-4 w-full">
            <div
              className={clsx(
                "pt-3 px-3 flex w-full col-span-6",
                blueprintsUnderTesting.length === 0 ? "pb-3" : "pb-1"
              )}
            >
              <Text variant="h6">Updated blueprints</Text>
            </div>
          </div>
          {blueprintsUnderTesting.map((successRateInfo, index) => (
            <CanaryTestingBlueprintSuccessRateRow
              successRateInfo={successRateInfo}
              isLastItem={index === blueprintsUnderTesting.length - 1}
            />
          ))}
        </Card>
      )}

      {topChangedBlueprints && (
        <Card variant="outline" className="mb-4">
          <div className="grid grid-cols-12 gap-4 w-full">
            <div
              className={clsx(
                "pt-3 px-3 flex w-full col-span-6",
                topChangedBlueprints.length === 0 ? "pb-3" : "pb-1"
              )}
            >
              <Text variant="h6" className="pr-2">
                Top 5 blueprints
              </Text>
              <Tooltip title="The top 5 blueprints with the most relative change in success rate">
                <Info className="text-gray-60" width={16} height={16} />
              </Tooltip>
            </div>
          </div>
          {topChangedBlueprints.map((successRateInfo, index) => (
            <CanaryTestingBlueprintSuccessRateRow
              successRateInfo={successRateInfo}
              isLastItem={index === topChangedBlueprints.length - 1}
            />
          ))}
        </Card>
      )}
    </>
  );
};

export default CanaryTestResultsTables;

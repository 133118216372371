import { isEqual } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";
import Callout from "../../../shared/Callout";
import {
  AuthConfigIntegrationBuilder,
  IntegrationSetupChecklistItem,
  LinkChoiceStepOption,
  LinkingFlowStepPathIntegrationBuilder,
  LinkingFlowStepPathTypeEnums,
} from "../../../utils/Entities";
import useCreateOrPatchLinkChoiceStepOptions from "../../hooks/useCreateOrPatchLinkChoiceStepOptions";
import useCreateOrPatchLinkingFlowStepPath from "../../hooks/useCreateOrPatchLinkingFlowStepPath";
import useDeleteLinkingFlowStepPath from "../../hooks/useDeleteLinkingFlowStepPath";
import ConfigureStepPathSection from "./ConfigureStepPathSection";
import ConfigureStepsSection from "./ConfigureStepsSection";
import LinkingFlowStepPathSetupHeader from "./LinkingFlowStepPathSetupHeader";
import { DiffModelTypeEnum } from "../../../../../models/DiffModels";
import { convertToDisplayableDiffState } from "../../../../shared/diff-view/utils/helpers";
import EditorLeavingGuard from "../../../../shared/unsaved-changes/EditorLeavingGuard";

const LINKING_FLOW_STEP_PATH_SUBMIT_TEXT = "Save linking flow step path";
const LINKING_FLOW_STEP_PATH_DELETE_TEXT = "Delete linking flow step path";

interface LinkingFlowStepPathSetupProps {
  authConfigs: AuthConfigIntegrationBuilder[] | undefined;
  integrationSetupChecklistItems: IntegrationSetupChecklistItem[] | undefined;
  linkingFlowStepPaths: LinkingFlowStepPathIntegrationBuilder[] | undefined;
  linkChoiceStepOptions: LinkChoiceStepOption[] | undefined;
  setLinkChoiceStepOptions: React.Dispatch<
    React.SetStateAction<LinkChoiceStepOption[] | undefined>
  >;
  integrationID: string;
  reloadLinkingFlowStepPathsAndAuthConfigs: () => void;
}

type RouteParams = {
  linkingFlowStepPathID: string | undefined;
  authConfigID: string | undefined;
};

const LinkingFlowStepPathSetup = ({
  authConfigs,
  integrationSetupChecklistItems,
  linkingFlowStepPaths,
  linkChoiceStepOptions,
  setLinkChoiceStepOptions,
  integrationID,
  reloadLinkingFlowStepPathsAndAuthConfigs,
}: LinkingFlowStepPathSetupProps) => {
  /* INITIATE STATES */
  const { authConfigID, linkingFlowStepPathID } = useParams<RouteParams>();

  // This is the loaded step path params, if it exists.
  const selectedStepPath = linkingFlowStepPaths?.find(
    (stepPath) => stepPath.id === linkingFlowStepPathID
  );

  // The modified state of the data
  const [requestedStepPath, setRequestedStepPath] = useState<
    LinkingFlowStepPathIntegrationBuilder
  >();
  const [requestedLinkChoiceStepOptions, setRequestedLinkChoiceStepOptions] = useState<
    LinkChoiceStepOption[]
  >(linkChoiceStepOptions || []);

  /* INITIATE CREATE/PATCH ACTIONS */
  const {
    createLinkingFlowStepPath,
    patchLinkingFlowStepPath,
    isLoadingStepPathUpdate,
  } = useCreateOrPatchLinkingFlowStepPath({
    reloadLinkingFlowStepPathsAndAuthConfigs: reloadLinkingFlowStepPathsAndAuthConfigs,
    integrationID: integrationID,
    linkingFlowStepPathID: linkingFlowStepPathID,
    requestedLinkingFlowStepPath: requestedStepPath,
  });

  const {
    createOrPatchLinkChoiceStepOptions,
    isLoadingStepOptionsUpdate,
  } = useCreateOrPatchLinkChoiceStepOptions({
    integrationID: integrationID,
    requestedLinkChoiceStepOptions: requestedLinkChoiceStepOptions,
    setLinkChoiceStepOptions: setLinkChoiceStepOptions,
  });

  const {
    deleteLinkingFlowStepPath,
    isLoadingLinkingFlowStepPathDeletion,
  } = useDeleteLinkingFlowStepPath({
    integrationID: integrationID,
    reloadLinkingFlowStepPathsAndAuthConfigs: reloadLinkingFlowStepPathsAndAuthConfigs,
    linkingFlowStepPathID: selectedStepPath?.id,
  });

  /* INITIATE INTEGRATIONBUILDER BUTTONS */
  const {
    setOnSubmit,
    setCanSubmit,
    setOnDelete,
    setCurrentStateForDiff,
    setNewStateForDiff,
    computeHasUnsavedChanges,
  } = useIntegrationBuilderContext();
  useIntegrationBuilderContext({
    submitButtonText: LINKING_FLOW_STEP_PATH_SUBMIT_TEXT,
    deleteButtonText: LINKING_FLOW_STEP_PATH_DELETE_TEXT,
    isLoadingSubmit: isLoadingStepPathUpdate || isLoadingStepOptionsUpdate,
    isLoadingDelete: isLoadingLinkingFlowStepPathDeletion,
    shouldRenderSubmitButton: true,
    shouldRenderNavigationButtons: false,
    shouldRenderDeleteButton: !!selectedStepPath,
    modelTypeForDiff: DiffModelTypeEnum.MERGE_LINK_STEPS,
    shouldHideDiffModal: false,
    markForDeletion: false,
    shouldRenderStageButton: false,
  });

  /* RE-RENDER STATES CORRECTLY */

  // Initiate requestedStepPath state
  useEffect(() => {
    if (selectedStepPath) {
      setRequestedStepPath(selectedStepPath);
    } else if (authConfigID) {
      setRequestedStepPath({
        is_active: true,
        path_type: LinkingFlowStepPathTypeEnums.PATH_TYPE_MANUAL_LINKING,
        auth_configuration_id: authConfigID,
        steps: [],
        category: null,
      });
    }
  }, [authConfigID, selectedStepPath]);

  useEffect(() => {
    setRequestedLinkChoiceStepOptions(linkChoiceStepOptions || []);
  }, [linkChoiceStepOptions]);

  // Only call the relevant API if there are changes
  const isStepPathChanged = useMemo(() => {
    return !isEqual(requestedStepPath, selectedStepPath);
  }, [requestedStepPath, selectedStepPath]);

  const isChoiceStepOptionsChanged = useMemo(() => {
    return !isEqual(requestedLinkChoiceStepOptions, linkChoiceStepOptions);
  }, [requestedLinkChoiceStepOptions, linkChoiceStepOptions]);

  useEffect(() => {
    setCanSubmit(isStepPathChanged || isChoiceStepOptionsChanged);
    setOnSubmit(() => {
      if (isStepPathChanged || authConfigID) {
        selectedStepPath ? patchLinkingFlowStepPath() : createLinkingFlowStepPath();
      }
      if (isChoiceStepOptionsChanged) {
        createOrPatchLinkChoiceStepOptions();
      }
    });
  }, [
    requestedStepPath,
    requestedLinkChoiceStepOptions,
    isStepPathChanged,
    authConfigID,
    isChoiceStepOptionsChanged,
  ]);

  useEffect(() => {
    setOnDelete(deleteLinkingFlowStepPath);
  }, [setOnDelete, selectedStepPath]);

  /* This useEffect initializes the model state to be used in the diff modal.
   * When adding/removing fields, you must update helpers-merge-link-steps-diff.ts
   * to ensure that these state changes get effectively captured by diff modal.
   */
  useEffect(() => {
    if ((authConfigID || selectedStepPath) && linkChoiceStepOptions) {
      let initialStepPath: LinkingFlowStepPathIntegrationBuilder = {
        is_active: true,
        path_type: LinkingFlowStepPathTypeEnums.PATH_TYPE_MANUAL_LINKING,
        auth_configuration_id: authConfigID ?? "",
        category: null,
        steps: [],
      };
      initialStepPath = selectedStepPath ?? initialStepPath;
      setCurrentStateForDiff(
        convertToDisplayableDiffState(
          initialStepPath,
          linkChoiceStepOptions,
          integrationSetupChecklistItems,
          authConfigs
        )
      );
      setNewStateForDiff(
        convertToDisplayableDiffState(
          initialStepPath,
          linkChoiceStepOptions,
          integrationSetupChecklistItems,
          authConfigs
        )
      );
    }
  }, [
    authConfigs,
    authConfigID,
    selectedStepPath,
    linkChoiceStepOptions,
    integrationSetupChecklistItems,
  ]);

  /* This useEffect updates the model state to be used in the diff modal.
   * When adding new fields/removing them, you'll have to add them to multiple places
   * to ensure that these state changes get effectively captured by diff modal.
   */
  useEffect(() => {
    setNewStateForDiff(
      convertToDisplayableDiffState(
        requestedStepPath,
        requestedLinkChoiceStepOptions,
        integrationSetupChecklistItems,
        authConfigs
      )
    );
  }, [requestedLinkChoiceStepOptions, requestedStepPath, integrationSetupChecklistItems]);

  return (
    <EditorLeavingGuard computeHasUnsavedChanges={computeHasUnsavedChanges}>
      <div>
        <LinkingFlowStepPathSetupHeader
          integrationID={integrationID}
          selectedLinkingFlowStepPath={selectedStepPath}
          authConfigName={
            authConfigs?.find((config) => selectedStepPath?.auth_configuration_id === config.id)
              ?.name
          }
        />
        <div className="space-y-6">
          {!selectedStepPath && (
            <Callout
              title="What is a Merge Link path?"
              description="A Merge Link path represents a way for the end user to authenticate via Merge Link. Each path must be tied to a single authentication configuration."
            />
          )}
          {requestedStepPath && (
            <div className="space-y-6">
              <ConfigureStepPathSection
                setRequestedStepPath={setRequestedStepPath}
                requestedStepPath={requestedStepPath}
                authConfigType={
                  authConfigs?.find(
                    (config) => requestedStepPath.auth_configuration_id === config.id
                  )?.auth_type
                }
              />
              <ConfigureStepsSection
                authConfigs={authConfigs}
                integrationSetupChecklistItems={integrationSetupChecklistItems}
                setRequestedStepPath={setRequestedStepPath}
                requestedStepPath={requestedStepPath}
                selectedStepPath={selectedStepPath}
                requestedLinkChoiceStepOptions={requestedLinkChoiceStepOptions}
                setRequestedLinkChoiceStepOptions={setRequestedLinkChoiceStepOptions}
              />
            </div>
          )}
        </div>
      </div>
    </EditorLeavingGuard>
  );
};

export default LinkingFlowStepPathSetup;

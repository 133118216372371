import { Badge } from "@merge-api/merge-javascript-shared";
import { AlertStatus } from "./utils/constants";
import { getBadgeColor } from "./utils/helpers";

interface StatusCellProps {
  status: AlertStatus;
}

const StatusCell = ({ status }: StatusCellProps) => {
  const badgeColor = getBadgeColor(status);

  return (
    <Badge className="text-left-align font-medium" color={badgeColor}>
      {status}
    </Badge>
  );
};

export default StatusCell;

import {
  Button,
  ButtonVariant,
  MenuItem,
  Menu as SharedDropdown,
  Tooltip,
} from "@merge-api/merge-javascript-shared";
import { usePayloadContext } from "../context/PayloadContext";

const BlueprintLeftPanelConsolePayloadActions = () => {
  const {
    selectedTestPayload,
    isSelectedTestPayloadGenerated,
    canSavePayloadText,
    upsertPayload,
    setIsShowingSavePayloadModal,
    setIsSavingAsNewPayload,
    areInputParametersValid,
  } = usePayloadContext();

  const handleSave = () => {
    if (!!!selectedTestPayload || isSelectedTestPayloadGenerated) {
      setIsSavingAsNewPayload(false);
      setIsShowingSavePayloadModal(true);
    } else {
      upsertPayload(
        selectedTestPayload.name,
        selectedTestPayload.linked_account_id,
        selectedTestPayload.common_model_object_id,
        () => {}
      );
    }
  };

  const handleSaveAs = () => {
    if (!!selectedTestPayload) {
      setIsSavingAsNewPayload(true);
      setIsShowingSavePayloadModal(true);
    }
  };

  const handleSettings = () => {
    setIsSavingAsNewPayload(false);
    setIsShowingSavePayloadModal(true);
  };

  return (
    <div className="flex flex-row items-center justify-end space-x-2">
      {selectedTestPayload && !isSelectedTestPayloadGenerated && (
        <Button fullWidth variant={ButtonVariant.TertiaryWhite} onClick={handleSettings}>
          Settings
        </Button>
      )}
      <Tooltip
        className="w-full"
        title={!!selectedTestPayload && !canSavePayloadText ? "No changes made yet" : ""}
      >
        <SharedDropdown
          isComboButton={true}
          menuPlacement="bottom-end"
          ButtonProps={{
            disabled: (!!selectedTestPayload && !canSavePayloadText) || !areInputParametersValid,
            fullWidth: true,
            variant: ButtonVariant.PrimaryCharcoal,
            children: "Save",
            onClick: handleSave,
          }}
        >
          <MenuItem onClick={handleSave}>Save</MenuItem>
          <MenuItem
            disabled={!!!selectedTestPayload || isSelectedTestPayloadGenerated}
            onClick={handleSaveAs}
          >
            Save as
          </MenuItem>
        </SharedDropdown>
      </Tooltip>
    </div>
  );
};

export default BlueprintLeftPanelConsolePayloadActions;

import { HeaderPretitle } from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import { checkNeedsRemoteData } from "../utils/BlueprintEditorUtils";
import NeedsRemoteDataToggle from "./NeedsRemoteDataToggle";
import TypeaheadFormField from "./TypeaheadFormField";

interface Props {
  stepID: string;
}

const BlueprintEditorRightPanelGetByIdForm = ({ stepID }: Props) => {
  const { selectedStep, setStepNeedsRemoteData } = useBlueprintContext();

  const updateNeedsRemoteData = () => {
    setStepNeedsRemoteData(stepID, !needsRemoteData);
  };
  const needsRemoteData = checkNeedsRemoteData(selectedStep);

  return (
    <>
      <NeedsRemoteDataToggle
        updateNeedsRemoteData={updateNeedsRemoteData}
        needsRemoteData={needsRemoteData}
      />
      <HeaderPretitle className="my-2">Parameters</HeaderPretitle>
      <TypeaheadFormField
        key={"id"}
        title={"ID"}
        subtitle={"The ID of the matching object."}
        valueKey={"id"}
        parameterType={"uuid"}
      />
    </>
  );
};

export default BlueprintEditorRightPanelGetByIdForm;

import { createContext, Dispatch, SetStateAction, useContext } from "react";
import {
  IntegrationValidatorRuleResult,
  OverrideRuleRequestInput,
  ValidatedStagedComponent,
} from "../types";

// Define the context type
export interface StagedComponentContextState {
  component: ValidatedStagedComponent;
  setComponent: Dispatch<SetStateAction<ValidatedStagedComponent>>;
  integrationID: string;
  overrideRule: (rule: IntegrationValidatorRuleResult, input: OverrideRuleRequestInput) => void;
  removeOverride: (rule: IntegrationValidatorRuleResult) => void;
  passedRules: IntegrationValidatorRuleResult[];
  setPassedRules: Dispatch<SetStateAction<IntegrationValidatorRuleResult[]>>;
  inProgressRules: IntegrationValidatorRuleResult[];
  setInProgressRules: Dispatch<SetStateAction<IntegrationValidatorRuleResult[]>>;
  newBlockingRules: IntegrationValidatorRuleResult[];
  setNewBlockingRules: Dispatch<SetStateAction<IntegrationValidatorRuleResult[]>>;
  newWarningRules: IntegrationValidatorRuleResult[];
  setNewWarningRules: Dispatch<SetStateAction<IntegrationValidatorRuleResult[]>>;
  existingBlockingRules: IntegrationValidatorRuleResult[];
  setExistingBlockingRules: Dispatch<SetStateAction<IntegrationValidatorRuleResult[]>>;
  existingWarningRules: IntegrationValidatorRuleResult[];
  setExistingWarningRules: Dispatch<SetStateAction<IntegrationValidatorRuleResult[]>>;
  overriddenRules: IntegrationValidatorRuleResult[];
  setOverriddenRules: Dispatch<SetStateAction<IntegrationValidatorRuleResult[]>>;
  isFetchBlueprint: boolean;
  setIsFetchBlueprint: Dispatch<SetStateAction<boolean>>;
  hasPublishedBlueprint: boolean;
  setHasPublishedBlueprint: Dispatch<SetStateAction<boolean>>;
}

// Create the context
export const StagedComponentContext = createContext<StagedComponentContextState>({
  component: {} as ValidatedStagedComponent,
  setComponent: () => {},
  integrationID: "",
  overrideRule: () => {},
  removeOverride: () => {},
  passedRules: [],
  setPassedRules: () => {},
  inProgressRules: [],
  setInProgressRules: () => {},
  newBlockingRules: [],
  setNewBlockingRules: () => {},
  newWarningRules: [],
  setNewWarningRules: () => {},
  existingBlockingRules: [],
  setExistingBlockingRules: () => {},
  existingWarningRules: [],
  setExistingWarningRules: () => {},
  overriddenRules: [],
  setOverriddenRules: () => {},
  isFetchBlueprint: false,
  setIsFetchBlueprint: () => {},
  hasPublishedBlueprint: false,
  setHasPublishedBlueprint: () => {},
});

// Hook to use the context
export const useStagedComponentContext = () => {
  const context = useContext(StagedComponentContext);
  if (!context) {
    throw new Error("useStagedComponentContext must be used within a StagedComponentProvider");
  }
  return context;
};

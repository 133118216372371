import { MergeFlagFeature, useMergeFlag } from "../../../../components/shared/hooks/useMergeFlag";
import { Tab, Tabs, TabsList } from "@merge-api/merge-javascript-shared";
import { useState } from "react";
import StagedComponentRulesContent from "./StagedComponentRulesContent";
import CommonModelDiffRunTab from "./CommonModelDiffRuns/CommonModelDiffRunTab";
import { useStagedComponentContext } from "../context/StagedComponentContext";

enum StagedComponentTabValue {
  ALERTS = "ALERTS",
  COMMON_MODEL_DIFFS = "COMMON_MODEL_DIFFS",
}

const StagedComponentTabs = () => {
  // context
  const { isFetchBlueprint } = useStagedComponentContext();

  // state
  const [activeTab, setActiveTab] = useState<StagedComponentTabValue>(
    StagedComponentTabValue.ALERTS
  );

  // merge flag to check if common model diffs are enabled
  const { enabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_COMMON_MODEL_DIFFS,
    isEnabledForUser: true,
  });

  // function to handle tab change
  const handleTabChange = (_: any, newValue: string | number | null) => {
    if (typeof newValue === "string") {
      setActiveTab(newValue as StagedComponentTabValue);
    }
  };

  const renderAlertContent = () => <StagedComponentRulesContent enabled={enabled} />;

  // once flag is removed we will just remove the check for enabled
  if (!enabled || !isFetchBlueprint) {
    return renderAlertContent();
  }

  return (
    <div className="w-full">
      <Tabs value={activeTab} variant="underline" onChange={handleTabChange}>
        <TabsList>
          <Tab value={StagedComponentTabValue.ALERTS}>Alerts</Tab>
          <Tab value={StagedComponentTabValue.COMMON_MODEL_DIFFS}>Common Model diffs</Tab>
        </TabsList>
      </Tabs>
      {activeTab === StagedComponentTabValue.ALERTS && renderAlertContent()}
      {activeTab === StagedComponentTabValue.COMMON_MODEL_DIFFS && <CommonModelDiffRunTab />}
    </div>
  );
};

export default StagedComponentTabs;

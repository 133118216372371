import { Text } from "@merge-api/merge-javascript-shared";
import { usePublishModuleContext } from "./context/PublishModuleContext";
import PublishModuleContextProvider from "./context/PublishModuleContextProvider";
import PublishModuleChecksCardV2 from "./IntegrationPublishChecks/PublishModuleChecksCardV2";
import PublishModuleStagedVersionsCardV2 from "./IntegrationStagedVersions/PublishModuleStagedVersionsCardV2";
import PublishModulePublishCard from "./PublishModulePublishCard";
import { MergeFlagFeature, useMergeFlag } from "../../shared/hooks/useMergeFlag";
import PublishModulePublishModal from "./IntegrationStagedVersions/PublishModulePublishModal";
import CanaryTestingPublishModal from "./CanaryTesting/CanaryTestingPublishModal";
import CanaryTestingStagingLockedCard from "./CanaryTesting/CanaryTestingStagingLockedCard";
import PublishModulePublishButton from "./PublishModulePublishButton";
import FullPageSpinner from "../../shared/layout/FullPageSpinner";

const IntegrationPublishModuleSubtabWithValidatorV2 = () => {
  const {
    hasChanges,
    setIsPublishModalOpen,
    isPublishModalOpen,
    onPublish,
    publishModuleInfo,
    integrationID,
    overrideComment,
    overrideTicket,
    canaryTestInfo,
    isCanaryTestingPublishModalOpen,
    setIsCanaryTestingPublishModalOpen,
    isLoadingStagedComponents,
  } = usePublishModuleContext();

  const isIntegrationInCanaryTesting = canaryTestInfo?.is_integration_in_canary_testing;
  const isLoadingCanaryTestInfo = canaryTestInfo === undefined;

  const { enabled: isCanaryTestingFlagEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_ENABLE_CANARY_TESTING,
    isEnabledForUser: true,
  });

  // Show loading spinner while either staged components or canary test info is loading
  if (isLoadingStagedComponents || isLoadingCanaryTestInfo) {
    return <FullPageSpinner />;
  }

  return (
    <div className="space-y-8 mb-12">
      {isCanaryTestingFlagEnabled && isIntegrationInCanaryTesting ? (
        <CanaryTestingStagingLockedCard />
      ) : (
        <div>
          <Text variant="h3" className="mb-6">{`Staged components`}</Text>
          <PublishModuleStagedVersionsCardV2 />
        </div>
      )}
      {(hasChanges || isIntegrationInCanaryTesting) && (
        <div className="space-y-8">
          {((isCanaryTestingFlagEnabled && !isIntegrationInCanaryTesting) ||
            !isCanaryTestingFlagEnabled) && (
            <div className="space-y-4">
              <div className="flex flex-row justify-between items-center">
                <Text variant="h3">Checks</Text>
              </div>
              <PublishModuleChecksCardV2 />
            </div>
          )}
          <div className="flex flex-row justify-between items-center">
            <Text variant="h3">Publish</Text>
            {!isIntegrationInCanaryTesting && <PublishModulePublishButton />}
            <PublishModulePublishModal
              open={isPublishModalOpen}
              onPublish={onPublish}
              onClose={() => setIsPublishModalOpen(false)}
              stagedChanges={publishModuleInfo?.staged_changes}
              integrationID={integrationID}
              overrideComment={overrideComment}
              overrideTicket={overrideTicket}
            />

            <CanaryTestingPublishModal
              open={isCanaryTestingPublishModalOpen}
              onClose={() => setIsCanaryTestingPublishModalOpen(false)}
            />
          </div>
          {isCanaryTestingFlagEnabled && <PublishModulePublishCard />}
        </div>
      )}
    </div>
  );
};

const IntegrationPublishModuleWithContext = ({ integrationID }: { integrationID: string }) => {
  return (
    <PublishModuleContextProvider integrationID={integrationID}>
      <IntegrationPublishModuleSubtabWithValidatorV2 />
    </PublishModuleContextProvider>
  );
};

export default IntegrationPublishModuleWithContext;

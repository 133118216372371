import { Text } from "@merge-api/merge-javascript-shared";
import { getMetricName, normalizePercentage } from "../utils/helpers";
import MergeTable from "../../../../../components/shared/MergeTable";
import {
  ALERT_CATEGORIES,
  AlertDashboardColumn,
  AlertDashboardColumnTooltip,
} from "../utils/constants";
import CurrentDataCell from "../CurrentDataCell";

interface AlertDashboardDrawerSuccessRateProps {
  current: number;
  overall: number;
  deviation: number | null;
  metricName: string;
  category: ALERT_CATEGORIES;
}

interface HeaderCellProps {
  column: AlertDashboardColumn;
}

const HeaderCell = ({ column }: HeaderCellProps) => (
  <th>
    <Text variant="h6">{column}</Text>
    <Text variant="sm" className="text-gray-50">
      {AlertDashboardColumnTooltip[column]}
    </Text>
  </th>
);

const AlertDashboardDrawerSuccessRate = ({
  current,
  overall,
  deviation,
  metricName,
  category,
}: AlertDashboardDrawerSuccessRateProps) => {
  const metricRuleName = getMetricName(metricName) || "Unknown Metric";

  const columnOrder =
    category === ALERT_CATEGORIES.INTEGRATION
      ? [AlertDashboardColumn.OVERALL]
      : [
          AlertDashboardColumn.OVERALL,
          category === ALERT_CATEGORIES.ORGANIZATION
            ? AlertDashboardColumn.CURRENT_ORGANIZATION
            : AlertDashboardColumn.CURRENT_LA,
          AlertDashboardColumn.DEVIATION,
        ];

  const renderContent = (column: AlertDashboardColumn) => {
    switch (column) {
      case AlertDashboardColumn.OVERALL:
        return <Text variant="lg">{normalizePercentage(overall)}%</Text>;
      case AlertDashboardColumn.CURRENT_ORGANIZATION:
      case AlertDashboardColumn.CURRENT_LA:
        return <CurrentDataCell currentData={current} baselineData={overall} />;
      case AlertDashboardColumn.DEVIATION:
        return <Text variant="lg">{normalizePercentage(deviation ?? 0)}%</Text>;
      default:
        return null;
    }
  };

  return (
    <div className="mb-6">
      <Text variant="h4" className="mb-5">
        {metricRuleName}
      </Text>
      <MergeTable
        hover
        hasMarginBottom={false}
        hasMorePaddingOnFirstElement
        header={
          <>
            {columnOrder.map((column) => (
              <HeaderCell key={column} column={column} />
            ))}
          </>
        }
        borderBottom="1px solid rgb(237, 242, 249)"
        content={
          <tr>
            {columnOrder.map((column) => (
              <td key={column}>{renderContent(column)}</td>
            ))}
          </tr>
        }
      />
    </div>
  );
};

export default AlertDashboardDrawerSuccessRate;

import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { RefreshCw, Wand2 } from "lucide-react";
import { usePollAutogeneratedReadTest } from "./hooks/usePollAutogeneratedReadTest";
import { useAutogenerateReadMappingTest } from "./hooks/useAutogenerateReadMappingTest";
import { AsyncAutogeneratedReadMappingTestRun } from "../../../models/MappingTests";
import { useEffect, useState } from "react";
interface AutogenerateFetchMappingTestButtonProps {
  mappingTestSuiteID: string;
  inProgressExecution: boolean;
  setGeneratedReadTestRun: React.Dispatch<
    React.SetStateAction<AsyncAutogeneratedReadMappingTestRun | null>
  >;
  setHasPollingBeenTriggered: React.Dispatch<React.SetStateAction<boolean>>;
  openDialog: () => void;
  generatedReadTestRun: AsyncAutogeneratedReadMappingTestRun | null;
}

const AutogenerateFetchMappingTestButton = ({
  mappingTestSuiteID,
  inProgressExecution,
  setGeneratedReadTestRun,
  setHasPollingBeenTriggered,
  openDialog,
  generatedReadTestRun,
}: AutogenerateFetchMappingTestButtonProps) => {
  // state
  const [hasClickedGenerate, setHasClickedGenerate] = useState<boolean>(false);

  //hooks
  const { generateReadTest } = useAutogenerateReadMappingTest();
  const { pollAutogeneratedReadTest } = usePollAutogeneratedReadTest({
    setGeneratedReadTestRun,
    setHasPollingBeenTriggered,
  });
  // hook to open modal only once we receive test run data
  useEffect(() => {
    if (hasClickedGenerate) {
      setHasClickedGenerate(false);
      openDialog();
    }
  }, [generatedReadTestRun]);

  // functions
  const handleGenerateAndPoll = () => {
    generateReadTest(mappingTestSuiteID, (autogeneratedTestRunID) => {
      pollAutogeneratedReadTest(mappingTestSuiteID, autogeneratedTestRunID);
    });
  };

  // const derived from state
  const showInProgressAnimation = inProgressExecution || hasClickedGenerate;

  return (
    <Button
      disabled={showInProgressAnimation}
      variant={ButtonVariant.PrimaryCharcoal}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handleGenerateAndPoll();
        setHasClickedGenerate(true);
      }}
      leftIcon={
        <>
          {showInProgressAnimation ? (
            <RefreshCw className="text-white rotating" size={12} />
          ) : (
            <Wand2 size={12} className="text-white" />
          )}
        </>
      }
    >
      {inProgressExecution ? "Generating..." : "Generate"}
    </Button>
  );
};

export default AutogenerateFetchMappingTestButton;

import { useHistory } from "react-router-dom";
import { Check, X } from "lucide-react";
import { Table } from "react-bootstrap";
import { Button, ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";
import DiffModal from "../../components/shared/diff-view/DiffModal";
import { useState } from "react";
import { DiffModalTypeEnum, DiffModelTypeEnum } from "../../models/DiffModels";
import { ScraperVersion } from "./types";
import { navigateToScraperEditor } from "../../router/RouterUtils";
import PublishStateBadge from "../shared/PublishStateBadge";
import useScraperContext from "./context/useScraperContext";
import { fetchScraperVersion } from "./utils/ScraperAPIClient";
import EmptyStateWrapper from "../shared/layout/EmptyStateWrapper";
import { formatDate } from "../shared/utils/SharedComponentUtils";

interface Props {
  isVisible: boolean;
  onHide: () => void;
}

const ScraperVersionHistoryModal = ({ onHide, isVisible }: Props) => {
  const history = useHistory();
  const { scraper, integrationID, scraperVersions } = useScraperContext();
  const [scraperToCompare, setScraperToCompare] = useState<ScraperVersion>();
  const [isShowingDiffModal, setIsShowingDiffModal] = useState(false);
  const [isLoadingScraperToCompare, setIsLoadingScraperToCompare] = useState(false);

  const viewScraperVersion = (scraperVersionID: string) => {
    navigateToScraperEditor(history, integrationID, scraper.id, scraperVersionID);
    onHide();
  };

  return (
    <>
      <Dialog
        title="Version History"
        open={isVisible}
        onClose={onHide}
        variant="xl"
        footerButtonsHidden
      >
        <div className="overflow-y-auto -mx-6 -my-5 max-h-[720px]">
          {scraperVersions.length > 0 ? (
            <Table size="sm">
              <thead className="table-borderless">
                <tr>
                  <th>Version ID</th>
                  <th>Has Changes</th>
                  <th>When</th>
                  <th>Status</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>{getTableRows(scraperVersions)}</tbody>
            </Table>
          ) : (
            <EmptyStateWrapper isSpinner />
          )}
        </div>
      </Dialog>
      {isShowingDiffModal && (
        <DiffModal
          isModalOpen={isShowingDiffModal}
          setIsModalOpen={setIsShowingDiffModal}
          diffModalType={DiffModalTypeEnum.COMPARE}
          modelType={DiffModelTypeEnum.SCRAPER}
          isLoadingStates={isLoadingScraperToCompare}
          newState={scraper}
          currentState={scraperToCompare ?? {}}
        />
      )}
    </>
  );

  function getTableRows(scraperVersions: ScraperVersion[]) {
    return scraperVersions.map((scraperVersion) => (
      <tr key={scraperVersion.id}>
        <td>
          <Text>{scraperVersion?.version_id || ""}</Text>
        </td>
        <td>
          {scraperVersion?.has_changes ? (
            <Check style={{ color: "#009688" }} /> // teal-70
          ) : (
            <X style={{ color: "#757575" }} /> // gray-60
          )}
        </td>
        <td>
          <Text>{formatDate(scraperVersion?.created_at || "")}</Text>
        </td>
        <td>
          <PublishStateBadge publishState={scraperVersion.publish_state} />
        </td>
        <td>
          {scraper && scraper.version_id === scraperVersion.version_id ? (
            <Button size="sm" disabled>
              Viewing
            </Button>
          ) : (
            <div className="flex flex-row space-x-2 items-center">
              <Button size="sm" onClick={() => viewScraperVersion(scraperVersion.version_id)}>
                View
              </Button>
              <Button
                size="sm"
                variant={ButtonVariant.SecondaryCharcoal}
                onClick={() => {
                  onHide();
                  setIsShowingDiffModal(true);
                  setIsLoadingScraperToCompare(true);
                  fetchScraperVersion({
                    scraperID: scraper.id,
                    scraperVersionID: scraperVersion.version_id,
                    onSuccess: (response: ScraperVersion) => {
                      setScraperToCompare(response);
                      setIsLoadingScraperToCompare(false);
                    },
                  });
                }}
              >
                Compare
              </Button>
            </div>
          )}
        </td>
      </tr>
    ));
  }
};

export default ScraperVersionHistoryModal;

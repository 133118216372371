import {
  Alert,
  Button,
  ButtonVariant,
  Select,
  Text,
  Tooltip,
} from "@merge-api/merge-javascript-shared";
import Editor from "react-simple-code-editor";
import {
  BlueprintOperationType,
  BlueprintTestPayload,
  BlueprintTriggerType,
  JSONObjectSchema,
} from "../../../../../models/Blueprints";
import { formatDate } from "../../../../shared/utils/SharedComponentUtils";
import { usePayloadContext } from "../context/PayloadContext";
import BlueprintLeftPanelConsolePayloadActions from "./BlueprintLeftPanelConsolePayloadActions";

const { highlight, languages } = require("prismjs");

interface PayloadInputsProps {
  selectedTestLinkedAccount: undefined | string;
  selectedTestCommonModel: undefined | string;
  shouldDisableGeneratePayload: boolean;
  setPayloadToChange: React.Dispatch<React.SetStateAction<BlueprintTestPayload | null>>;
  setIsDiscardChangesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updatedParameterSchema: JSONObjectSchema | undefined;
  updateToLatestInputParameterSchema: () => void;
  changeSelectedPayload: (payload: BlueprintTestPayload | null) => void;
}

const PayloadInputs = ({
  selectedTestCommonModel,
  selectedTestLinkedAccount,
  shouldDisableGeneratePayload,
  setIsDiscardChangesModalOpen,
  setPayloadToChange,
  updatedParameterSchema,
  updateToLatestInputParameterSchema,
  changeSelectedPayload,
}: PayloadInputsProps) => {
  const {
    blueprint,
    blueprintTestPayloads,
    globalVarsAsString,
    setGlobalVarsAsString,
    isLoadingGeneratedPayload,
    generateWritePayload,
    selectedTestPayload,
    isSelectedTestPayloadGenerated,
    hasModifiedPayload,
    areInputParametersValid,
  } = usePayloadContext();
  // Renders the payload option in the Select component
  const renderPayloadOption = (option: BlueprintTestPayload) => {
    const formattedModifiedAt = formatDate(option.modified_at, "MM/DD/YYYY", false);
    const formattedCreatedAt = formatDate(option.created_at, "MM/DD/YYYY", false);
    const hasBeenUpdated = formattedModifiedAt !== formattedCreatedAt;

    return (
      <div className="flex flex-col">
        <Text variant="sm" className="text-gray-60">
          {hasBeenUpdated ? "Updated" : "Created"} {formattedModifiedAt}
        </Text>
        <Text>{option.name}</Text>
        <Text variant="sm" className="text-gray-60">
          {option.user_name}
        </Text>
      </div>
    );
  };

  const isTriggerTypeWebConnector = blueprint.trigger_type === BlueprintTriggerType.WEB_CONNECTOR;

  const isTriggerTypeWebConnectorAndFetch =
    blueprint.trigger_type === BlueprintTriggerType.WEB_CONNECTOR &&
    blueprint.operation_type === BlueprintOperationType.FETCH;

  return (
    <div className="flex flex-col space-y-2">
      {updatedParameterSchema && !isTriggerTypeWebConnector && (
        <Alert>
          <div className="flex w-full flex-row items-center justify-between">
            <Text>Update to latest schema</Text>
            <Button
              size="sm"
              variant={ButtonVariant.TertiaryWhite}
              onClick={updateToLatestInputParameterSchema}
            >
              Update
            </Button>
          </div>
        </Alert>
      )}
      {!isTriggerTypeWebConnectorAndFetch && (
        <Tooltip
          title={
            shouldDisableGeneratePayload
              ? ""
              : "Select a linked account and save your blueprint to generate a test payload."
          }
          placement="right-end"
          className="w-full"
        >
          <Button
            loading={isLoadingGeneratedPayload}
            fullWidth
            variant={ButtonVariant.SecondaryCharcoal}
            disabled={shouldDisableGeneratePayload}
            onClick={() => {
              generateWritePayload(
                selectedTestCommonModel ?? null,
                selectedTestLinkedAccount ?? null
              );
            }}
          >
            Generate payload
          </Button>
        </Tooltip>
      )}
      <Select
        placeholder="Select payload"
        options={blueprintTestPayloads}
        value={selectedTestPayload}
        disableDefaultOnChange={true}
        renderValue={(value) => <Text>{value.name}</Text>}
        onChange={(_, option) => {
          if (hasModifiedPayload || isSelectedTestPayloadGenerated) {
            setPayloadToChange(option);
            setIsDiscardChangesModalOpen(true);
          } else {
            changeSelectedPayload(option);
          }
        }}
        renderOption={renderPayloadOption}
      />
      <Editor
        highlight={(code) => highlight(code, languages.js)}
        value={globalVarsAsString ?? ""}
        onValueChange={(code) => {
          setGlobalVarsAsString(code);
        }}
        padding={10}
        style={{
          backgroundColor: areInputParametersValid ? "white" : "#FFE0E0",
          border: "1px solid #d2ddec",
          borderRadius: 8,
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          minHeight: "100px",
          overflow: "auto",
        }}
      />
      <BlueprintLeftPanelConsolePayloadActions />
    </div>
  );
};

export default PayloadInputs;

// import { Accordion } from "@merge-api/merge-javascript-shared";
import { ChecklistStatusValue } from "../../../../constants";
// import IntegrationChecklistAccordionTitleV2 from "./IntegrationChecklistAccordionTitleV2";
import { AlertTriangle, CircleCheck } from "lucide-react";
import AccordionCard from "../../../integration-builder/shared/AccordionCard";
import { usePublishModuleContext } from "../context/PublishModuleContext";
import IntegrationChecklistResultsContainerV2 from "../IntegrationChecklistResults/IntegrationChecklistResultsContainerV2";
import PublishModuleTestExecutionsContainerV2 from "../MappingTestResults/MappingTestResultsV2";
import MappingTestAccordionTitleV2 from "./MappingTestAccordionTitleV2";
import { Badge, Text } from "@merge-api/merge-javascript-shared";

const PublishModuleChecksCardV2 = () => {
  const { integrationChecklist } = usePublishModuleContext();
  const failureCount = integrationChecklist
    ? Object.values(integrationChecklist).filter(
        (value) => value == ChecklistStatusValue.NOT_IMPLEMENTED
      ).length
    : 0;

  return (
    <div className="space-y-4">
      <AccordionCard
        chevronSize={16}
        chevronOrientation="down"
        onChange={function noRefCheck() {}}
        title={
          <div className="flex flex-row items-center space-x-2">
            {failureCount > 0 ? (
              <AlertTriangle className="text-yellow-60" size={16} />
            ) : (
              <CircleCheck size={16} className="text-teal-70" />
            )}
            {/* The mapping tests accordion title has a button in it adding 4px of height to the main title content */}
            {/* We need to add 2px of padding to the text to make it look the same */}
            <Text variant="h5" className="text-black py-[2px] pl-1">
              Integration Checklist
            </Text>
            {failureCount > 0 && (
              <Badge color="yellow">
                {failureCount.toString() + (failureCount > 1 ? " warnings" : " warning")}
              </Badge>
            )}
          </div>
        }
        titleClassName="p-5"
        defaultExpanded={false}
        variant="shadow"
        className="mb-0"
      >
        <IntegrationChecklistResultsContainerV2 />
      </AccordionCard>
      <AccordionCard
        chevronSize={16}
        chevronOrientation="down"
        onChange={function noRefCheck() {}}
        defaultExpanded={false}
        title={<MappingTestAccordionTitleV2 />}
        titleClassName="p-5"
        variant="shadow"
      >
        <PublishModuleTestExecutionsContainerV2 />
      </AccordionCard>
    </div>
  );
};

export default PublishModuleChecksCardV2;

import { Link, Text } from "@merge-api/merge-javascript-shared";
import { Minus } from "lucide-react";
import { getBlueprintEditorPath } from "../../../../../router/RouterUtils";
import { BlueprintVersionMeta } from "../../types";

interface Props {
  integrationID: string;
  blueprint_details: BlueprintVersionMeta[];
}

const AutomatedModifiedAtFiltersTableBlueprintsCell = ({
  integrationID,
  blueprint_details,
}: Props) => {
  if (blueprint_details.length === 0) {
    return <Minus size={16} className="text-gray-60" />;
  }

  return (
    <ul className="list-disc pl-4 text-blue-40">
      {blueprint_details.map((blueprint_detail) => (
        <li key={blueprint_detail.blueprint_version_id}>
          <div className="flex min-w-0">
            <Text className="truncate cursor-pointer">
              <Link
                className="block truncate"
                href={getBlueprintEditorPath(integrationID, blueprint_detail.blueprint_version_id)}
                target="_blank"
              >
                {blueprint_detail.blueprint_name}
              </Link>
            </Text>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default AutomatedModifiedAtFiltersTableBlueprintsCell;

import { Row, Col } from "react-bootstrap";
import { Badge, Text, Toggle, Tooltip } from "@merge-api/merge-javascript-shared";
import { usePublishModuleContext } from "../context/PublishModuleContext";
import clsx from "clsx";

const PublishModuleCanaryTestingPrePublishInfo = () => {
  const { setIsCanaryTestingToggleEnabled, canaryTestInfo } = usePublishModuleContext();

  const isCanaryTestingFinished = canaryTestInfo?.is_canary_testing_finished ?? false;
  const numAccountsAvailableForTesting = canaryTestInfo?.num_available_las_for_canary_testing ?? 0;
  const accountsAvailableForTesting = numAccountsAvailableForTesting > 0;

  return (
    <div
      className={clsx(
        !isCanaryTestingFinished ? "pb-4" : "",
        "flex flex-row items-center w-full justify-between"
      )}
    >
      <Row>
        <Col className="p-0 ml-2">
          <Row className="pb-2 m-0">
            <Tooltip
              title={!accountsAvailableForTesting ? "No Linked Accounts available for testing" : ""}
            >
              <Toggle
                disabled={!accountsAvailableForTesting}
                className="m-0"
                checked={accountsAvailableForTesting}
                onChange={(value) => setIsCanaryTestingToggleEnabled(value)}
                label={<Text className="p-0 m-0">Enable canary testing</Text>}
              />
            </Tooltip>
          </Row>
          <Row className="pb-2 pl-1 m-0">
            <Badge>
              {numAccountsAvailableForTesting +
                " Linked Account" +
                (numAccountsAvailableForTesting === 1 ? "" : "s") +
                " available for testing"}
            </Badge>
          </Row>
          <Row className="pl-1 m-0">
            <Text className="text-gray-70">
              Publishing with a canary test will release changes to a portion of Linked Accounts
              first before publishing fully (Merge, Free and Launch). This allows us to catch any
              bugs or failures earlier on. Once publishing starts, staging will be paused for this
              integration until testing is complete. You can check the status from this page.
            </Text>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PublishModuleCanaryTestingPrePublishInfo;

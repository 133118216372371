import IntegrationBuilderEmptyState from "../../../shared/IntegrationBuilderEmptyState";
import { AutomatedModifiedAtFilterDetails } from "../../types";
import { Frown } from "lucide-react";
import AutomatedModifiedAtFiltersTableRow from "./AutomatedModifiedAtFiltersTableRow";

interface Props {
  integrationID: string;
  amfDetails: AutomatedModifiedAtFilterDetails[];
  filteredAmfDetails: AutomatedModifiedAtFilterDetails[];
  numTableColumns: number;
}

const AutomatedModifiedAtFiltersTableContent = ({
  integrationID,
  amfDetails,
  filteredAmfDetails,
  numTableColumns,
}: Props) => {
  if (filteredAmfDetails.length === 0) {
    const content =
      amfDetails.length === 0
        ? {
            title: "No filters",
            subtitle:
              "Our backend did not produce automated “modified_at” filters for the current integration version",
          }
        : {
            title: "No results found",
            subtitle: "Clear your filters or adjust them to find results",
            icon: Frown,
          };
    return (
      <IntegrationBuilderEmptyState
        title={content.title}
        subtitle={content.subtitle}
        numberOfColumns={numTableColumns}
        withIcon={true}
        icon={content.icon}
      />
    );
  }

  return (
    <>
      {amfDetails.map((amfDetail) => (
        <AutomatedModifiedAtFiltersTableRow
          key={amfDetail.amf_model.id}
          integrationID={integrationID}
          automatedModifiedAtFilterDetails={amfDetail}
        />
      ))}
    </>
  );
};

export default AutomatedModifiedAtFiltersTableContent;

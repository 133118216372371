import { APIProtocols } from "../../../../models/APIEndpointModels";
import {
  DiffStateFieldTypeEnum,
  DiffState,
  generateDiffStateFieldInputType,
} from "../../../../models/DiffModels";
import { API_ENDPOINT_TYPE_DISPLAY_NAMES_WITH_PAGINATION } from "../../../integration-builder/api-endpoint-setup/constants";
import { generateDiffState, globalNewState } from "./helpers-diff";
import { generateDiffForPaginationConfiguration } from "./helpers-pag-config-diff";
import { generateDiffForRateLimitConfiguration } from "./helpers-rate-limit-config-diff";

interface GenerateDiffForAPIEndpointProps {
  isChangelog?: boolean;
}
// Generate diff for API endpoint
export const generateDiffForAPIEndpoint = ({
  isChangelog = false,
}: GenerateDiffForAPIEndpointProps): DiffState => {
  const diffState = generateDiffState({
    fields: [
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "api_endpoint_configuration_header",
        displayName: "API endpoint configuration",
        childDiffStateInputFields: [
          {
            name: "name",
            displayName: "Name",
            keyPathToName: ["api_endpoint"],
          },
          {
            name: "api_protocol",
            displayName: "API protocol",
            keyPathToName: ["api_endpoint"],
          },
          {
            name: "endpoint_type",
            displayName: "Endpoint type",
            keyPathToName: ["api_endpoint"],
          },
        ],
      },
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "request_configuration_section",
        displayName: "Request configuration",
        childDiffStateInputFields: [
          {
            name: "method",
            displayName: "Method",
            keyPathToName: ["api_endpoint", "request_configuration"],
          },
          {
            name: "path",
            displayName: "URL path",
            keyPathToName: ["api_endpoint", "request_configuration"],
            isRenderChildrenAsNested: true,
            childDiffStateInputFields: [
              {
                name: "base_url_override",
                displayName: "Override base URL",
                keyPathToName: ["api_endpoint", "request_configuration"],
              },
              {
                name: "path_param_schema",
                displayName: "Path parameters",
                keyPathToName: ["api_endpoint", "request_configuration"],
              },
            ],
          },
          {
            name: "query_param_schema",
            displayName: "Query param schema",
            keyPathToName: ["api_endpoint", "request_configuration"],
          },
          {
            name: "header_schema",
            displayName: "Header schema",
            keyPathToName: ["api_endpoint", "request_configuration"],
          },
          {
            name: "content_type",
            displayName: "Content type",
            keyPathToName: ["api_endpoint", "request_configuration", "rest_request_configuration"],
          },
          {
            name: "body_schema",
            displayName: "Body schema",
            keyPathToName: ["api_endpoint", "request_configuration", "rest_request_configuration"],
            isRenderAsPreviewable: true,
            generateCondition: {
              name: "api_protocol",
              keyPathToName: ["api_endpoint"],
              valuesToMatch: [APIProtocols.REST],
            },
          },
          {
            name: "request_body_format",
            displayName: "Request body format",
            keyPathToName: ["api_endpoint", "request_configuration", "soap_request_configuration"],
            isRenderAsPreviewable: true,
            generateCondition: {
              name: "api_protocol",
              keyPathToName: ["api_endpoint"],
              valuesToMatch: [APIProtocols.SOAP],
            },
          },
          {
            name: "request_body_base_config",
            displayName: "Request body base config",
            keyPathToName: [
              "api_endpoint",
              "request_configuration",
              "graphql_request_configuration",
            ],
            isRenderAsPreviewable: true,
            generateCondition: {
              name: "api_protocol",
              keyPathToName: ["api_endpoint"],
              valuesToMatch: [APIProtocols.GRAPHQL],
            },
          },
          {
            name: "request_body_param_schema",
            displayName: "Request body param schema",
            keyPathToName: ["api_endpoint", "request_configuration", "soap_request_configuration"],
            isRenderAsPreviewable: true,
            generateCondition: {
              name: "api_protocol",
              keyPathToName: ["api_endpoint"],
              valuesToMatch: [APIProtocols.SOAP, APIProtocols.GRAPHQL],
            },
          },
          {
            name: "does_allow_timestamp_filtering",
            displayName: "Does this endpoint allow filtering by timestamp?",
            keyPathToName: [
              "api_endpoint",
              "request_configuration",
              "timestamp_filter_configuration",
            ],
            isRenderChildrenAsNested: true,
            childDiffStateInputFields: [
              {
                name: "param_location",
                displayName: "Parameter location",
                keyPathToName: [
                  "api_endpoint",
                  "request_configuration",
                  "timestamp_filter_configuration",
                ],
              },
              {
                name: "param_key",
                displayName: "Parameter key",
                keyPathToName: [
                  "api_endpoint",
                  "request_configuration",
                  "timestamp_filter_configuration",
                ],
              },
              {
                type: DiffStateFieldTypeEnum.SECTION,
                name: "advanced",
                displayName: "Advanced",
                isRenderNumberOfChildrenInDisplayName: true,
                childDiffStateInputFields: [
                  {
                    name: "format_override",
                    displayName: "Format override",
                    keyPathToName: [
                      "api_endpoint",
                      "request_configuration",
                      "timestamp_filter_configuration",
                    ],
                  },
                  {
                    name: "param_prefix",
                    displayName: "Parameter prefix",
                    keyPathToName: [
                      "api_endpoint",
                      "request_configuration",
                      "timestamp_filter_configuration",
                    ],
                  },
                  {
                    name: "param_suffix",
                    displayName: "Parameter suffix",
                    keyPathToName: [
                      "api_endpoint",
                      "request_configuration",
                      "timestamp_filter_configuration",
                    ],
                  },
                  {
                    name: "buffer_hours",
                    displayName: "Buffer hours",
                    keyPathToName: [
                      "api_endpoint",
                      "request_configuration",
                      "timestamp_filter_configuration",
                    ],
                  },
                  {
                    name: "default_override",
                    displayName: "Default override",
                    keyPathToName: [
                      "api_endpoint",
                      "request_configuration",
                      "timestamp_filter_configuration",
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "advanced",
            displayName: "Advanced",
            isRenderNumberOfChildrenInDisplayName: true,
            childDiffStateInputFields: [
              {
                name: "request_type",
                displayName: "Request type override",
                keyPathToName: ["api_endpoint", "request_configuration"],
              },
              {
                name: "file_schema",
                displayName: "File schema",
                keyPathToName: ["api_endpoint", "request_configuration"],
                isRenderAsPreviewable: true,
              },
              {
                name: "url_encoded_query_params_format",
                displayName: "URL encoded query params format",
                keyPathToName: ["api_endpoint", "request_configuration"],
                isRenderAsPreviewable: true,
              },
              {
                name: "url_encoded_query_param_schema",
                displayName: "URL encoded query params schema",
                keyPathToName: ["api_endpoint", "request_configuration"],
                isRenderAsPreviewable: true,
              },
              {
                name: "should_filter_empty_values_from_body_data",
                displayName: "Should filter empty values from body data",
                keyPathToName: ["api_endpoint", "request_configuration"],
              },
              {
                name: "should_not_encode_special_characters",
                displayName: "Should not encode special characters",
                keyPathToName: ["api_endpoint", "request_configuration"],
              },
              {
                name: "should_accept_merge_with_json_request_body",
                displayName: "Should accept merge with json request body",
                keyPathToName: ["api_endpoint", "request_configuration"],
              },
              {
                name: "should_accept_raw_json_request_body",
                displayName: "Should accept raw json request body",
                keyPathToName: ["api_endpoint", "request_configuration"],
              },
              {
                name: "should_override_integration_convert_strings_to_numbers",
                displayName: "Should override integration convert strings to numbers",
                keyPathToName: ["api_endpoint", "request_configuration"],
              },
              {
                name: "should_override_integration_convert_body_data_to_string",
                displayName: "Should override integration convert body data to strings",
                keyPathToName: ["api_endpoint", "request_configuration"],
              },
              {
                name: "does_request_use_body_templating_engine",
                displayName: "(SOAP) Does request use body templating engine",
                keyPathToName: [
                  "api_endpoint",
                  "request_configuration",
                  "soap_request_configuration",
                ],
              },
            ],
          },
        ],
      },
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "response_configuration_section",
        displayName: "Response configuration",
        childDiffStateInputFields: [
          {
            name: "response_type",
            displayName: "Response type",
            keyPathToName: ["api_endpoint", "response_configuration"],
          },
          {
            name: "response_schema",
            displayName: "Response schema",
            keyPathToName: ["api_endpoint", "response_configuration"],
            isRenderAsPreviewable: true,
          },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "advanced",
            displayName: "Advanced",
            isRenderNumberOfChildrenInDisplayName: true,
            childDiffStateInputFields: [
              {
                name: "allowed_error_codes",
                displayName: "Allowed error codes",
                keyPathToName: ["api_endpoint", "response_configuration"],
              },
              {
                name: "additional_response_body_parsing",
                displayName: "Response body additional parsing",
                keyPathToName: ["api_endpoint", "response_configuration"],
              },
              {
                name: "sync_cursor_key_path",
                displayName: "Sync cursor key path",
                keyPathToName: ["api_endpoint", "response_configuration"],
              },
            ],
          },
        ],
      },
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "pagination_configuration_header",
        displayName: "Pagination configuration",
        generateCondition: {
          name: "endpoint_type",
          keyPathToName: ["api_endpoint"],
          valuesToMatch: API_ENDPOINT_TYPE_DISPLAY_NAMES_WITH_PAGINATION,
        },
        childDiffStateInputFields: generateDiffForPaginationConfigurationRelation().concat(
          generateDiffForNewPaginationConfiguration()
        ),
      },
      ...(!isChangelog
        ? [
            {
              type: DiffStateFieldTypeEnum.HEADER,
              name: "rate_limit_configurations_header",
              displayName: "Rate limit configurations",
              childDiffStateFields: generateDiffForRateLimitConfiguration({
                isForAPIEndpoint: true,
              }),
            },
          ]
        : []),
    ],
  });
  return diffState;
};

export const generateDiffForPaginationConfigurationRelation = (): generateDiffStateFieldInputType[] => {
  return [
    {
      name: "pagination_configuration_id",
      displayName: "Pagination configuration for API endpoint",
      keyPathToName: ["api_endpoint", "pagination_configuration_information"],
    },
    {
      name: "pagination_response_body_array_key_path_override",
      displayName: "Response body array key path override",
      keyPathToName: ["api_endpoint", "pagination_configuration_information"],
    },
  ];
};

export const generateDiffForNewPaginationConfiguration = (): generateDiffStateFieldInputType[] => {
  if (
    "pagination_configuration" in globalNewState &&
    globalNewState["pagination_configuration"] !== null &&
    globalNewState["pagination_configuration"]
  ) {
    let diffStateInputFieldsForPagConfig: generateDiffStateFieldInputType[] = [
      {
        type: DiffStateFieldTypeEnum.SECTION,
        name: "new_pag_config_section",
        displayName: "Pagination configuration to add",
        childDiffStateFields: generateDiffForPaginationConfiguration({ isForAPIEndpoint: true }),
      },
    ];
    return diffStateInputFieldsForPagConfig;
  }
  return [];
};

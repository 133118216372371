import { ButtonVariant, Menu, MenuItem } from "@merge-api/merge-javascript-shared";
import { ChevronRight, MoreVertical } from "lucide-react";
import { handleResolveAction, handleSilenceAction } from "./utils/alertActionHelpers";
import { AdminAlert } from "../../../../models/Entities";
import { Dispatch, SetStateAction } from "react";

interface AlertActionCellProps {
  alert: AdminAlert;
  updateAdminAlert: Dispatch<SetStateAction<Record<string, any>>>;
}

const AlertActionCell = ({ alert, updateAdminAlert }: AlertActionCellProps) => {
  const isResolved = !!alert.resolved_at;
  const isSilenced = !!alert.silenced_at;

  return (
    <div className="flex justify-end items-center space-x-5">
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Menu
          ButtonProps={{
            children: <MoreVertical size={16} />, // Always show ChevronRight for non-drawer
            variant: ButtonVariant.IconShadowHidden,
            size: "sm",
          }}
          isComboButton={false}
          menuPlacement="bottom-end"
        >
          <MenuItem onClick={() => handleResolveAction(alert, isResolved, updateAdminAlert)}>
            {isResolved ? "Unresolve" : "Resolve"}
          </MenuItem>
          <MenuItem onClick={() => handleSilenceAction(alert, isSilenced, updateAdminAlert)}>
            {isSilenced ? "Mark as Active" : "Mark as Noise"}
          </MenuItem>
        </Menu>
      </div>
      <ChevronRight size={16} />
    </div>
  );
};

export default AlertActionCell;

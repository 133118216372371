import { useMemo } from "react";
import {
  BlueprintVersion,
  BlueprintVersionPublishState,
  IntegrationEditorAction,
  IntegrationEditorTopControlPanelActionProps,
} from "../../../../models/Blueprints";

interface UseBlueprintActionsProps {
  publishedStateActions: IntegrationEditorTopControlPanelActionProps[];
  blueprintVersion?: BlueprintVersion;
  isArchived?: boolean;
  isIntegrationInCanaryTesting?: boolean;
}

export const useBlueprintActions = ({
  publishedStateActions,
  blueprintVersion,
  isArchived,
  isIntegrationInCanaryTesting,
}: UseBlueprintActionsProps) => {
  return useMemo(() => {
    if (!blueprintVersion || !publishedStateActions)
      return { mainAction: undefined, dropdownActions: [] };

    let mainAction: IntegrationEditorTopControlPanelActionProps | undefined;
    let dropdownActions: IntegrationEditorTopControlPanelActionProps[] = [];

    const saveDraftAction = publishedStateActions.find(
      (action) => action.text === IntegrationEditorAction.SAVE_DRAFT && !action.isDisabled
    );

    const unarchiveAction = publishedStateActions.find(
      (action) => action.text === IntegrationEditorAction.UNARCHIVE
    );

    if (
      [BlueprintVersionPublishState.Draft, BlueprintVersionPublishState.Unpublished].includes(
        blueprintVersion.publish_state
      )
    ) {
      if (isArchived) {
        if (saveDraftAction) {
          // If archived but changes exist, prioritize "Save Draft" over "Unarchive"
          mainAction = saveDraftAction;
          dropdownActions = publishedStateActions.filter((action) =>
            [
              IntegrationEditorAction.UNARCHIVE,
              IntegrationEditorAction.STAGE,
              IntegrationEditorAction.DELETE,
            ].includes(action.text)
          );
        } else if (unarchiveAction) {
          // Otherwise, if archived, "Unarchive" is the primary action
          mainAction = unarchiveAction;
          dropdownActions = publishedStateActions.filter((action) =>
            [
              IntegrationEditorAction.STAGE,
              IntegrationEditorAction.SAVE_DRAFT,
              IntegrationEditorAction.DELETE,
            ].includes(action.text)
          );
        }
      } else if (saveDraftAction) {
        // If not archived and changes exist, "Save Draft" is the primary action
        mainAction = saveDraftAction;
        dropdownActions = publishedStateActions.filter((action) =>
          [IntegrationEditorAction.STAGE, IntegrationEditorAction.ARCHIVE].includes(action.text)
        );
      } else if (isIntegrationInCanaryTesting) {
        // If the integration is in canary testing, no staging is allowed. Defaulting to delete.
        mainAction = publishedStateActions.find(
          (action) => action.text === IntegrationEditorAction.DELETE
        );
        dropdownActions = publishedStateActions.filter((action) =>
          [
            IntegrationEditorAction.STAGE,
            IntegrationEditorAction.SAVE_DRAFT,
            IntegrationEditorAction.ARCHIVE,
          ].includes(action.text)
        );
      } else {
        // Default to "Stage" if no changes exist
        mainAction = publishedStateActions.find(
          (action) => action.text === IntegrationEditorAction.STAGE
        );
        dropdownActions = publishedStateActions.filter((action) =>
          [
            IntegrationEditorAction.SAVE_DRAFT,
            IntegrationEditorAction.ARCHIVE,
            IntegrationEditorAction.DELETE,
          ].includes(action.text)
        );
      }
    } else {
      switch (blueprintVersion.publish_state) {
        case BlueprintVersionPublishState.Staged:
          if (saveDraftAction) {
            // If staged and changes exist, prioritize "Save Draft" over "Publish"
            mainAction = saveDraftAction;
            dropdownActions = publishedStateActions.filter((action) =>
              [
                IntegrationEditorAction.PUBLISH,
                IntegrationEditorAction.UNSTAGE,
                IntegrationEditorAction.DELETE,
              ].includes(action.text)
            );
          } else if (isArchived) {
            if (unarchiveAction) {
              // Otherwise, if archived, "Unarchive" is the primary action
              mainAction = unarchiveAction;
              dropdownActions = publishedStateActions.filter((action) =>
                [
                  IntegrationEditorAction.PUBLISH,
                  IntegrationEditorAction.UNSTAGE,
                  IntegrationEditorAction.DELETE,
                ].includes(action.text)
              );
            }
          } else {
            // Default to "Publish" as the primary action
            mainAction = publishedStateActions.find(
              (action) => action.text === IntegrationEditorAction.PUBLISH
            );
            dropdownActions = publishedStateActions.filter((action) =>
              [
                IntegrationEditorAction.UNSTAGE,
                IntegrationEditorAction.ARCHIVE,
                IntegrationEditorAction.DELETE,
              ].includes(action.text)
            );
          }
          break;
        case BlueprintVersionPublishState.Published:
          if (saveDraftAction) {
            // If changes exist, prioritize "Save Draft" over "Unpublish"
            mainAction = saveDraftAction;
            dropdownActions = publishedStateActions.filter((action) =>
              [
                IntegrationEditorAction.UNPUBLISH,
                IntegrationEditorAction.ARCHIVE,
                IntegrationEditorAction.DELETE,
              ].includes(action.text)
            );
          } else {
            // Default to "Unpublish" as the primary action
            mainAction = publishedStateActions.find(
              (action) => action.text === IntegrationEditorAction.UNPUBLISH
            );
            dropdownActions = publishedStateActions.filter((action) =>
              [
                IntegrationEditorAction.SAVE_DRAFT,
                IntegrationEditorAction.ARCHIVE,
                IntegrationEditorAction.DELETE,
              ].includes(action.text)
            );
          }
          break;
        default:
          break;
      }
    }

    return { mainAction, dropdownActions };
  }, [publishedStateActions, blueprintVersion, isArchived]);
};

import { Text } from "@merge-api/merge-javascript-shared";
import { IntegrationAvatar } from "../../../../../components/shared/MergeAvatars";
import { ALERT_CATEGORIES, AlertStatus, IntegrationTier } from "../utils/constants";
import { AdminAlert } from "../../../../../models/Entities";
import MergeCopyToClipboard from "../../../../../components/shared/MergeCopyToClipboard";
import StatusCell from "../StatusCell";
import SeverityCell from "../SeverityCell";

interface AlertDashboardDrawerTitlePrefixProps {
  category: ALERT_CATEGORIES;
  alert: AdminAlert;
  severity: IntegrationTier;
  status: AlertStatus;
}

interface AlertStatusSeverityInfoProps {
  severity: IntegrationTier;
  status: AlertStatus;
}

const AlertStatusSeverityInfo = ({ severity, status }: AlertStatusSeverityInfoProps) => (
  <div className="flex space-x-2">
    <SeverityCell severity={severity} />
    <StatusCell status={status} />
  </div>
);

const AlertDashboardDrawerTitlePrefix = ({
  category,
  alert,
  severity,
  status,
}: AlertDashboardDrawerTitlePrefixProps) => {
  const renderTitle = () => {
    switch (category) {
      case ALERT_CATEGORIES.LINKED_ACCOUNT:
        return (
          <div>
            <div className="flex items-center gap-2">
              <Text variant="h4">{alert.linked_account_id}</Text>
              <MergeCopyToClipboard textToCopy={alert.linked_account_id || ""} />
              <AlertStatusSeverityInfo severity={severity} status={status} />
            </div>
            <div className="flex items-center gap-3 mt-1">
              <Text variant="md">
                {alert.organization_name} &middot; {alert.integration_name}
              </Text>
              <IntegrationAvatar
                integrationName={alert.integration_name}
                squareImage={alert.integration_square_image_url}
              />
            </div>
          </div>
        );
      case ALERT_CATEGORIES.ORGANIZATION:
        return (
          <div>
            <div className="flex items-center gap-3">
              <Text variant="h4">{alert.organization_name}</Text>
              <AlertStatusSeverityInfo severity={severity} status={status} />
            </div>
            <Text variant="md" className="mt-1">
              {alert.integration_name}
            </Text>
          </div>
        );
      case ALERT_CATEGORIES.INTEGRATION:
        return (
          <div className="flex items-center gap-3">
            <IntegrationAvatar
              integrationName={alert.integration_name}
              squareImage={alert.integration_square_image_url}
            />
            <Text variant="h4">{alert.integration_name}</Text>
            <AlertStatusSeverityInfo severity={severity} status={status} />
          </div>
        );
    }
  };

  return renderTitle();
};

export default AlertDashboardDrawerTitlePrefix;

import { useRef, useState } from "react";
import MergeModal from "../../shared/MergeModal";
import { Form } from "react-bootstrap";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { LinkedAccount } from "../../../models/Entities";
import { fetchWithAuth } from "../../../api-client/api_client";
import SpinnerButton from "../../shared/SpinnerButton";
import useBlueprintContext from "../context/useBlueprintContext";
import { ReportFile } from "../../../models/Blueprints";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import styled from "styled-components";

interface Props {
  isVisible: boolean;
  onHide: () => void;
  testLinkedAccounts: LinkedAccount[];
  reportTemplateID: string;
  alreadySelectedLinkedAccountID: string | undefined;
  setReportFileID: (reportFileID: string) => void;
}

const FileInputWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CustomFileInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
`;

const CustomFileButton = styled.button`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 35px;
  font-size: 12px;
  cursor: pointer;
  border: none;
  gap: 4px;
  border-radius: 6px;
  background: var(--lm-gray-0, #f2f4f7);
`;

const CustomFileText = styled.span`
  margin-left: 10px;
`;

const NameInput = styled.input`
  display: flex;
  height: 40px;
  padding: 10px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 6px;
  background: #fff;
  border: none;
  width: 100%;
  box-shadow: 0px 1.5px 4px -1px rgba(0, 0, 0, 0.16), 0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
`;

const LinkedAccountContainer = styled(Form.Control)`
  border: none;
`;

const ReportFileUploadModal = ({
  onHide,
  isVisible,
  reportTemplateID,
  alreadySelectedLinkedAccountID,
  setReportFileID,
}: Props) => {
  const { setReportFiles, blueprint } = useBlueprintContext();

  const [file, setFile] = useState<File | null>(null);
  const [fileReportName, setFileReportName] = useState<string | null>(null);
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>(
    alreadySelectedLinkedAccountID
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) setFile(files[0]);
  };

  const handleAccountChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAccountId(event.target.value);
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpload = () => {
    if (!file || !selectedAccountId || !fileReportName) return;
    setIsLoading(true);

    const body = {
      file,
      linked_account_id: selectedAccountId,
      report_template_id: reportTemplateID,
      name: fileReportName,
    };

    fetchWithAuth({
      path: `/integrations/reports/sftp/upload-test-file`,
      method: "POST",
      body,
      onResponse: (reportFile: ReportFile) => {
        const currentReportFiles = blueprint.report_files ?? [];
        setReportFiles([...currentReportFiles, reportFile]);
        setReportFileID(reportFile.id);
        setIsLoading(false);
        showSuccessToast(`Successfully uploaded file ${file.name}`);
        onHide();
      },
      onError: () => {
        setIsLoading(false);
        showErrorToast("Something went wrong uploading this file. Check Sentry to understand why");
      },
    });
  };

  return (
    <MergeModal title="Upload test report file" show={isVisible} onHide={onHide}>
      <Form.Label>
        <b>Linked Account</b>
      </Form.Label>
      <LinkedAccountContainer
        value={selectedAccountId || ""}
        onChange={handleAccountChange}
        disabled={true}
        className="mb-6"
      />
      <div className="d-flex flex-column mb-6">
        <Form.Label>
          <b>Select a File to upload</b>
        </Form.Label>
        <FileInputWrapper>
          <CustomFileInput type="file" onChange={handleFileChange} ref={fileInputRef} />
          <CustomFileButton onClick={handleFileButtonClick}>
            <b>Choose File</b>
          </CustomFileButton>
          <CustomFileText>{file ? file.name : "No file selected"}</CustomFileText>
        </FileInputWrapper>
      </div>
      <Form.Label>
        <b className="cursor-pointer">Report name</b>
      </Form.Label>
      <NameInput
        type="text"
        placeholder="Enter file name..."
        value={fileReportName ?? ""}
        onChange={(e: any) => setFileReportName(e.target.value)}
        className="mb-6"
      />
      <div className="d-flex float-right gap-3">
        <Button variant={ButtonVariant.SecondaryCharcoal} onClick={onHide}>
          Cancel
        </Button>
        <SpinnerButton
          isLoading={isLoading}
          text="Save"
          disabled={!file || !selectedAccountId}
          className="btn btn-primary size-sm font-weight-bold h-34px"
          onClick={() => handleUpload()}
        />
      </div>
    </MergeModal>
  );
};

export default ReportFileUploadModal;

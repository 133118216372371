import { Text, Toggle, Typeahead } from "@merge-api/merge-javascript-shared";
import { APICategory } from "../../../../../models/Entities";
import { displayNameForAPICategory } from "../../../../../models/Helpers";
import { ALL_CATEGORIES } from "../../../../integrations/dashboard/hooks/useLoadPaginatedIntegrations";
import clsx from "clsx";

interface Props {
  className?: string;
  setCategory: (value: APICategory | "all") => void;
  category: APICategory | "all" | null;
  areAllFiltersEnabled: boolean;
  setIsDisableDialogOpen: (value: boolean) => void;
  setIsEnableDialogOpen: (value: boolean) => void;
}

const DROPDOWN_OPTIONS: ("all" | APICategory)[] = [ALL_CATEGORIES, ...Object.values(APICategory)];

const AutomatedModifiedAtFiltersTableHeader = ({
  className,
  setCategory,
  category,
  areAllFiltersEnabled,
  setIsDisableDialogOpen,
  setIsEnableDialogOpen,
}: Props) => {
  const getOptionLabel = (option: APICategory | "all") => {
    return option === ALL_CATEGORIES ? "All categories" : displayNameForAPICategory(option);
  };

  return (
    <div className={clsx(className, "justify-between flex flex-row items-end")}>
      <div>
        <Text className="mb-2" variant="h6">
          Category
        </Text>
        <div className="w-[270px]">
          <Typeahead
            placeholder="Select category..."
            className="bg-white"
            options={DROPDOWN_OPTIONS}
            disableClearable={category === "all"}
            onChange={(_: any, value: APICategory | "all" | null) => {
              setCategory(value || "all");
            }}
            getOptionLabel={(option: APICategory | "all") => getOptionLabel(option)}
            renderOption={(option: APICategory | "all") => <Text>{getOptionLabel(option)}</Text>}
            value={category ?? null}
          />
        </div>
      </div>
      <Toggle
        label={<Text variant="h6">All filters enabled</Text>}
        disableDefaultOnChange={true}
        checked={areAllFiltersEnabled}
        onChange={() => {
          if (areAllFiltersEnabled) {
            setIsDisableDialogOpen(true);
          } else {
            setIsEnableDialogOpen(true);
          }
        }}
      />
    </div>
  );
};

export default AutomatedModifiedAtFiltersTableHeader;

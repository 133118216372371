import {
  Badge,
  Button,
  ButtonVariant,
  Dialog,
  Text,
  Tooltip,
} from "@merge-api/merge-javascript-shared";
import { usePublishModuleContext } from "../context/PublishModuleContext";
import { contructDateStringFromDatetime } from "../utils";
import CountdownTimeRemaining from "../../../shared/CountdownTimeRemaining";

const PublishModuleCanaryTestingPostPublishInfo = () => {
  const {
    canaryTestInfo,
    onCanaryTestPublish,
    onCanaryTestAbort,
    onCanaryTestRollback,
    isSaving,
    isCanaryTestingAbortModalOpen,
    setIsCanaryTestingAbortModalOpen,
  } = usePublishModuleContext();

  const isCanaryTestingFinished = canaryTestInfo?.is_canary_testing_finished ?? false;
  const estimatedTimeRemaining = canaryTestInfo?.time_remaining_in_seconds;

  const startTimeDateString = contructDateStringFromDatetime(canaryTestInfo?.start_time);
  const userString = canaryTestInfo?.user_email ? ` by ${canaryTestInfo?.user_email}` : "";

  return (
    <div className="pb-4 flex w-full">
      <div className="flex flex-col flex-grow">
        <div className="flex justify-between w-full pb-2">
          <Text className="pl-1" variant="h5">
            {!isCanaryTestingFinished ? "Canary test in progress..." : "Canary test complete"}
          </Text>
        </div>
        {!isCanaryTestingFinished && estimatedTimeRemaining !== 0 && (
          <div className="pb-2 pl-1">
            <Badge>
              Estimated time to results:&nbsp;
              <CountdownTimeRemaining initialTimeInSeconds={estimatedTimeRemaining} />
            </Badge>
          </div>
        )}
        {!isCanaryTestingFinished && estimatedTimeRemaining === 0 && (
          <div className="pb-2 pl-1">
            <Badge color="yellow">Waiting for periodic task</Badge>
          </div>
        )}
        <div className="pl-1 m-0">
          <Text variant="title-sm" className="text-gray-70">
            {startTimeDateString || userString
              ? `Initiated${userString}${startTimeDateString}`
              : "Staging details unknown"}
          </Text>
        </div>
      </div>
      {!isCanaryTestingFinished ? (
        <div>
          <Button
            onClick={() => setIsCanaryTestingAbortModalOpen(true)}
            size="md"
            variant={ButtonVariant.DangerFilled}
            loading={isSaving}
            disabled={isSaving}
          >
            <Text variant="h6">Abort publish</Text>
          </Button>
        </div>
      ) : (
        <div className="ml-2 flex gap-2">
          <Tooltip title="Publishes to all linked accounts">
            <Button
              variant={ButtonVariant.PrimaryBlue}
              onClick={() => {
                onCanaryTestPublish();
              }}
            >
              <Text variant="h6">Publish</Text>
            </Button>
          </Tooltip>
          <Button
            variant={ButtonVariant.SecondaryBlue}
            onClick={() => {
              onCanaryTestRollback();
            }}
          >
            <Text variant="h6" className="text-blue-40">
              Rollback
            </Text>
          </Button>
        </div>
      )}
      <Dialog
        title="Are you sure?"
        open={isCanaryTestingAbortModalOpen}
        onClose={() => setIsCanaryTestingAbortModalOpen(false)}
        primaryButtonVariant={ButtonVariant.DangerFilled}
        primaryButtonText="Confirm Abort"
        onPrimaryButtonClick={() => {
          onCanaryTestAbort();
          setIsCanaryTestingAbortModalOpen(false);
        }}
      >
        Canary testing has already begun. Are you sure you want to abort publish? All components
        will be re-staged.
      </Dialog>
    </div>
  );
};

export default PublishModuleCanaryTestingPostPublishInfo;

import MergeModal from "../../shared/MergeModal";
import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";

interface Props {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  field: string;
}

export const DeleteFieldModal = ({ show, onHide, onConfirm, field }: Props) => {
  return (
    <MergeModal show={show ?? false} centered onHide={onHide} bodyClassName="overflow-hidden">
      <div className="flex flex-col">
        <Text variant="h3">Make sure no customers are using this field!</Text>
        Deleting a field can break customer's code. Communicate to customer before deleting.
        <div className="mt-6 flex flex-row justify-end gap-3">
          <Button variant={ButtonVariant.SecondaryCharcoal} onClick={onHide}>
            Cancel
          </Button>

          <Button variant={ButtonVariant.DangerFilled} onClick={onConfirm}>
            Delete "{field}"
          </Button>
        </div>
      </div>
    </MergeModal>
  );
};

import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";
import { AlertDashboardColumn, AlertDashboardColumnTooltip } from "./utils/constants";

interface AlertDashboardColumnTitleProps {
  column: AlertDashboardColumn;
}

const AlertDashboardColumnTitle = ({ column }: AlertDashboardColumnTitleProps) => {
  if (column === AlertDashboardColumn.ACTIONS) {
    // No need to display this.
    return <th scope="col"></th>;
  }

  const renderColumnWithTooltip = (tooltip: string) => (
    <Tooltip title={tooltip}>
      <div className="flex items-center">
        <Text variant="h6" className="mr-1 ">
          {column}
        </Text>
        <Info size={16} className="align-middle" />
      </div>
    </Tooltip>
  );

  return (
    <th scope="col">
      {AlertDashboardColumnTooltip[column as keyof typeof AlertDashboardColumnTooltip] ? (
        renderColumnWithTooltip(
          AlertDashboardColumnTooltip[column as keyof typeof AlertDashboardColumnTooltip] as string
        )
      ) : (
        <Text variant="h6">{column}</Text>
      )}
    </th>
  );
};

export default AlertDashboardColumnTitle;

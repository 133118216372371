import { LucideIcon } from "lucide-react";
import { ScraperVersion } from "../components/scraper/types";
import { BlueprintVersionTestCoverage, Integration, User } from "./Entities";
import { MappingTestExecutionResult } from "./MappingTests";
import Readme from "./Readme";
import StepNote from "./StepNote";

// Blueprint Step Fields

export interface BlueprintParameterSchemaValue {
  title?: string | undefined;
  description?: string;
  type: string;
  enum?: Array<string>;
  enumNames?: Array<string>;
  deprecated?: boolean;
  display_name?: string;
  max_length?: number | null | undefined;
  format?: string;
  use_dropdown?: boolean;
  relation?: null | { category: string; model: string; is_many_to_many: boolean };
  items?: BlueprintParameterSchemaValue;
  required?: Array<string>;
  properties?: {
    [parameter: string]: BlueprintParameterSchemaValue;
  };
}

export type BlueprintParameterSchema<
  ParameterKeys extends string | null
> = ParameterKeys extends string ? Record<ParameterKeys, BlueprintParameterSchemaValue> : undefined;

export interface BlueprintReturnSchemaValue {
  id: string;
  type: string;
  title: string;
  description: string;
}

export enum SchemaTypes {
  parameter_schema = "PARAMETER_SCHEMA",
  return_schema = "RETURN_SCHEMA",
}

export type JSONSchemaPrimitiveType = "number" | "string" | "boolean" | "any" | "null";

export type JSONObjectSchema = {
  title?: string;
  type: "object";
  properties: {
    [parameter: string]: JSONSchemaValue;
  };
  description?: string;
  required?: Array<string>;
};

export type JSONArraySchema = {
  title?: string;
  type: "array";
  items: JSONSchemaValue;
  description?: string;
};

export type JSONSchemaNonNestedValue = {
  title?: string;
  type: JSONSchemaPrimitiveType;
  description?: string;
};

export type JSONSchemaNonNestedValueWithTypeArray = {
  title?: string;
  type: JSONSchemaPrimitiveType[];
  description?: string;
};

export type JSONSchemaValue = JSONObjectSchema | JSONArraySchema | JSONSchemaNonNestedValue;

export type JSONSchemaGenericValue =
  | JSONObjectSchema
  | JSONArraySchema
  | JSONSchemaNonNestedValue
  | JSONSchemaNonNestedValueWithTypeArray;

export type JSONSchema = JSONObjectSchema | JSONArraySchema;

export type StepRelations = {
  parent: BlueprintStep | null;
  predecessor: BlueprintStep | null;
  successor: BlueprintStep | null;
  parentPath?: string;
};

export enum BlueprintParameterValueType {
  constant = "CONSTANT",
  returnValue = "RETURN_VALUE",
  inputParameter = "INPUT_PARAMETER",
  procedureArray = "PROCEDURE_ARRAY",
  statementArray = "STATEMENT_ARRAY",
  nestedParameterValues = "NESTED_PARAMETER_VALUES",
  customObject = "CUSTOM_OBJECT",
  customArray = "CUSTOM_ARRAY",
  globalVariable = "GLOBAL_VARIABLE",
  variable = "VARIABLE",
  none = "NONE",
}

export enum BlueprintParameterValueCustomFunctionType {
  CONVERT_TO_ARRAY = "PARAMETER_VALUE_CUSTOM_FUNCTION_CONVERT_TO_ARRAY",
  CONCATENTATE_STRINGS = "PARAMETER_VALUE_CUSTOM_FUNCTION_CONCATENATE_STRINGS",
  RETRIEVE_DICT_VALUE_FROM_KEY = "PARAMETER_VALUE_CUSTOM_FUNCTION_RETRIEVE_DICT_VALUE_FROM_KEY",
  GET_FIRST_VALUE_FROM_OBJECT_LIST = "PARAMETER_VALUE_CUSTOM_FUNCTION_RETRIEVE_FIRST_VALUE_FROM_OBJECT_LIST",
}

export type BlueprintParameterValueConcatenateStringsCustomFunction = BlueprintParameterValueCustomFunctionBase<
  BlueprintParameterValueCustomFunctionType.CONCATENTATE_STRINGS,
  {
    type: "object";
    properties: {
      value1: { type: "any" };
      value2: { type: "any" };
    };
  }
>;

export type BlueprintParameterValueConvertToArrayCustomFunction = BlueprintParameterValueCustomFunctionBase<
  BlueprintParameterValueCustomFunctionType.CONVERT_TO_ARRAY,
  { type: "any" }
>;

export type BlueprintParameterValueRetrieveDictValueFromKeyCustomFunction = BlueprintParameterValueCustomFunctionBase<
  BlueprintParameterValueCustomFunctionType.RETRIEVE_DICT_VALUE_FROM_KEY,
  {
    type: "object";
    properties: {
      lookup_dict: JSONObjectSchema;
      key: JSONSchemaNonNestedValue;
    };
  }
>;

export type BlueprintParameterValueGetFirstValueFromObjectList = BlueprintParameterValueCustomFunctionBase<
  BlueprintParameterValueCustomFunctionType.GET_FIRST_VALUE_FROM_OBJECT_LIST,
  {
    type: "object";
    properties: {
      object_array: JSONArraySchema;
      filter_name: JSONSchemaNonNestedValue;
      filter_value: JSONSchemaNonNestedValue;
      value: JSONSchemaNonNestedValue;
    };
  }
>;

type BlueprintParameterValueCustomFunctionBase<
  T extends BlueprintParameterValueCustomFunctionType,
  P extends JSONSchemaValue
> = {
  custom_function_type: T;
  parameter_type: P;
};

export type BlueprintParameterValueCustomFunction =
  | BlueprintParameterValueConvertToArrayCustomFunction
  | BlueprintParameterValueConcatenateStringsCustomFunction
  | BlueprintParameterValueRetrieveDictValueFromKeyCustomFunction
  | BlueprintParameterValueGetFirstValueFromObjectList;

export interface BlueprintParameterValueBase<T extends BlueprintParameterValueType> {
  value_type: T;
  custom_function?: BlueprintParameterValueCustomFunction;
  is_unique_identifier?: boolean;
}

export interface BlueprintParameterValueReturnValue
  extends BlueprintParameterValueBase<BlueprintParameterValueType.returnValue> {
  step_id: string;
  return_schema_path: Array<string | number>;
  request_return_value_path: Array<string | number>;
  choice_mapping?: { [value: string]: string };
}
export interface BlueprintParameterValueInputParameter
  extends BlueprintParameterValueBase<BlueprintParameterValueType.inputParameter> {
  return_schema_path: Array<string | number>;
  request_return_value_path: Array<string | number>;
}

export interface BlueprintParameterValueNestedParameterValues
  extends BlueprintParameterValueBase<BlueprintParameterValueType.nestedParameterValues> {
  nested_parameter_values: { [key: string]: BlueprintParameterValue };
  choice_mapping?: { [value: string]: string };
}

export interface BlueprintParameterValueConstant
  extends BlueprintParameterValueBase<BlueprintParameterValueType.constant> {
  constant: any;
}

export interface BlueprintParameterValueGlobalVariable
  extends BlueprintParameterValueBase<BlueprintParameterValueType.globalVariable> {
  key?: string;
  required?: boolean;
  return_schema_path?: Array<string | number>;
  request_return_value_path?: Array<string | number>;
}

export interface BlueprintParameterValueVariable
  extends BlueprintParameterValueBase<BlueprintParameterValueType.variable> {
  key: string;
}

export interface BlueprintParameterValueProcedureArray
  extends BlueprintParameterValueBase<BlueprintParameterValueType.procedureArray> {
  procedure_array: Array<BlueprintProcedure>;
}

export interface BlueprintParameterValueStatementArray
  extends BlueprintParameterValueBase<BlueprintParameterValueType.statementArray> {
  statement_array: Array<BlueprintLogicalOperation>;
}

export type BlueprintParameterValueNone = BlueprintParameterValueBase<
  BlueprintParameterValueType.none
>;

export interface BlueprintParameterValueCustomObject
  extends BlueprintParameterValueBase<BlueprintParameterValueType.customObject> {
  object_value: { [key: string]: BlueprintParameterValue };
}

export interface BlueprintParameterValueCustomArray
  extends BlueprintParameterValueBase<BlueprintParameterValueType.customArray> {
  array_values: Array<BlueprintParameterValue>;
}

export type BlueprintParameterValue =
  | BlueprintParameterValueReturnValue
  | BlueprintParameterValueInputParameter
  | BlueprintParameterValueNestedParameterValues
  | BlueprintParameterValueConstant
  | BlueprintParameterValueNone
  | BlueprintParameterValueProcedureArray
  | BlueprintParameterValueStatementArray
  | BlueprintParameterValueGlobalVariable
  | BlueprintParameterValueCustomObject
  | BlueprintParameterValueCustomArray
  | BlueprintParameterValueVariable;

export enum SetVariableValueType {
  EMPTY_ARRAY = "EMPTY_ARRAY",
  EMPTY_OBJECT = "EMPTY_OBJECT",
  OTHER_VALUE = "OTHER_VALUE",
}

export type BlueprintStepTemplate = BlueprintStepTemplateBase<BlueprintStepType, any>;
export interface BlueprintStepTemplateBase<
  T extends BlueprintStepType,
  ParameterKeys extends string | null
> {
  id: string;
  step_type: T;
  name: string;
  image: string;
  integration?: string;
  parameter_schema: BlueprintParameterSchema<ParameterKeys>;
  return_schema: BlueprintParameterSchemaValue;
  metadata: any;
  can_run_concurrently: boolean;
  endpoint?: string;
}

export interface BlueprintGenericStepTemplateMetadata {
  common_model?: {
    id: string;
    name: string;
    category: string;
  };
}

export interface BlueprintGenericStepTemplate {
  id: string;
  step_type: string;
  name: string;
  image: string;
  integration?: string;
  parameter_schema: BlueprintParameterSchema<any>;
  return_schema: BlueprintParameterSchemaValue;
  metadata: BlueprintGenericStepTemplateMetadata;
  can_run_concurrently: boolean;
  endpoint?: string;
}

export type BlueprintStepPath = Array<BlueprintStep>;

export type BlueprintStepPaths<T extends string> = Record<T, BlueprintStepPath>;

// Blueprint Steps
// enum values must match the values defined in merge-backend/merge/blueprints/constants.py
export enum BlueprintStepType {
  AddAvailableCustomField = "ADD_AVAILABLE_CUSTOM_FIELD",
  AddLinkedAccountAdditionalAuth = "ADD_LINKED_ACCOUNT_ADDITIONAL_AUTH",
  AddParamToEndpoint = "ADD_PARAM_TO_ENDPOINT",
  AddValidationProblem = "ADD_VALIDATION_PROBLEM",
  APIRequest = "API_REQUEST",
  APIRequestLoop = "API_REQUEST_LOOP",
  TraverseTree = "TREE_TRAVERSAL",
  APIRequestProxy = "API_REQUEST_PROXY",
  APIRequestLive = "API_LIVE_REQUEST",
  ArrayLoop = "ARRAY_LOOP",
  Assert = "ASSERT",
  BatchAPIRespnse = "BATCH_API_RESPONSE",
  CallFunctionalBP = "CALL_FUNCTIONAL_BP",
  CommonModelLoop = "COMMON_MODEL_LOOP",
  ConcurrentRequestLoop = "STEP_TYPE_CONCURRENT_API_REQUEST_LOOP",
  CreateOAuth1Signature = "STEP_TYPE_CREATE_OAUTH1_SIGNATURE",
  CreateOrUpdate = "CREATE_OR_UPDATE",
  CustomFunction = "CUSTOM_FUNCTION",
  DataTransform = "DATA_TRANSFORM",
  AddToArray = "ADD_TO_ARRAY",
  AddToDictionary = "ADD_TO_DICTIONARY",
  DateRangeLoop = "DATE_RANGE_LOOP",
  EndBlueprint = "END_BLUEPRINT",
  FetchCustomFieldMapping = "STEP_TYPE_FETCH_CUSTOM_FIELD_MAPPING",
  FileToUrl = "FILE_TO_URL",
  FileUrlToFile = "FILE_URL_TO_FILE",
  GetById = "GET_BY_ID",
  GetFirstInList = "GET_FIRST_IN_LIST",
  GetLinkedAccountFields = "GET_LINKED_ACCOUNT_FIELDS",
  GetModifiedSinceTimestampValue = "STEP_TYPE_GET_MODIFIED_SINCE_TIMESTAMP_VALUE",
  GetOrCreateByRemoteId = "GET_OR_CREATE_WITH_REMOTE_ID",
  GetRelationById = "GET_RELATION_BY_ID",
  IfElse = "IF_ELSE",
  ManyToManyOverride = "OVERRIDE_MANY_TO_MANY",
  MergeObjects = "STEP_TYPE_MERGE_OBJECTS",
  MetaAccessParentBPParamNames = "META_ACCESS_PARENT_BP_PARAM_NAMES",
  MetaAddEnumChoiceToField = "META_ADD_ENUM_CHOICE_TO_FIELD",
  MetaAddLinkedAccountParam = "META_ADD_LINKED_ACCOUNT_PARAM",
  ParseFromRemoteData = "PARSE_FROM_REMOTE_DATA",
  RaiseException = "RAISE_EXCEPTION",
  ReturnValuesToParentBP = "RETURN_VALUES_TO_PARENT_BP",
  RunBlueprint = "RUN_BLUEPRINT",
  SetRemoteDeleted = "STEP_TYPE_SET_REMOTE_DELETED",
  SetVariable = "SET_VARIABLE",
  Switch = "SWITCH",
  UpdateByModelID = "STEP_TYPE_UPDATE_BY_MODEL_ID",
  InitializeWrittenCommonModel = "STEP_TYPE_INITIALIZE_WRITTEN_COMMON_MODEL",
  UpdateLinkedAccount = "UPDATE_LINKED_ACCOUNT",
  WhileLoop = "WHILE_LOOP",
  WriteFile = "STEP_TYPE_WRITE_FILE",
  CreateQBXMLQuery = "STEP_TYPE_CREATE_QBXML_QUERY",
}

export interface BlueprintStepBase<
  StepType extends BlueprintStepType | null,
  ParameterKeys extends string,
  PathKeys extends string | null
> {
  id: string;
  hasCollapsedSubsteps?: boolean;
  isCollapsed?: boolean;
  template: StepType extends BlueprintStepType
    ? BlueprintStepTemplateBase<StepType, ParameterKeys>
    : BlueprintGenericStepTemplate;
  parameter_values: Record<ParameterKeys, BlueprintParameterValue>;
  paths?: PathKeys extends string ? BlueprintStepPaths<PathKeys> : undefined;
  mock_response_body?: { [field: string]: string };
  run_concurrently?: boolean;
  use_pagination_timestamp?: boolean;
  ignore_auth_config?: boolean;
  needs_remote_data?: boolean;
  pagination_complete_on_end?: boolean;
  allow_incompatible_paths?: boolean;
}

export type BlueprintAPIRequestStep = BlueprintStepBase<BlueprintStepType.APIRequest, string, null>;

export type BlueprintCreateOrUpdateStep = BlueprintStepBase<
  BlueprintStepType.CreateOrUpdate,
  string,
  null
>;

export type BlueprintSetRemoteDeletedStep = BlueprintStepBase<
  BlueprintStepType.SetRemoteDeleted,
  string,
  null
>;

export type BlueprintGetOrCreateByRemoteIdStep = BlueprintStepBase<
  BlueprintStepType.GetOrCreateByRemoteId,
  string,
  null
>;

export type BlueprintGetById = BlueprintStepBase<BlueprintStepType.GetById, string, null>;

export type BlueprintGetRelationById = BlueprintStepBase<
  BlueprintStepType.GetRelationById,
  "id" | "relation",
  null
>;

export type BlueprintCommonModelLoopStep = BlueprintStepBase<
  BlueprintStepType.CommonModelLoop,
  string,
  "true"
>;

export type BlueprintAPIRequestLoopStep = BlueprintStepBase<
  BlueprintStepType.APIRequestLoop,
  "starting_nodes",
  "true"
>;

export type BlueprintArrayLoopStep = BlueprintStepBase<
  BlueprintStepType.ArrayLoop,
  "array",
  "true"
>;

export type BlueprintWhileLoopStep = BlueprintStepBase<
  BlueprintStepType.WhileLoop,
  "condition" | "max_iterations",
  "true"
>;

export type BlueprintDateRangeLoopStep = BlueprintStepBase<
  BlueprintStepType.DateRangeLoop,
  "start_date" | "end_date" | "increment",
  "true"
>;

export type BlueprintTraverseTreeStep = BlueprintStepBase<
  BlueprintStepType.TraverseTree,
  string,
  "true"
>;

export type BlueprintIfElseStep = BlueprintStepBase<
  BlueprintStepType.IfElse,
  "statements" | "logical_operator" | "value1" | "comparator" | "value2",
  "true" | "false"
>;

export type BlueprintAddParamToEndpointStep = BlueprintStepBase<
  BlueprintStepType.AddParamToEndpoint,
  "key_type" | "key_name" | "key_value",
  null
>;

export type BlueprintSwitchStep = BlueprintStepBase<
  BlueprintStepType.Switch,
  "value" | "options",
  string
>;

export type BlueprintCustomFunctionStep = BlueprintStepBase<
  BlueprintStepType.CustomFunction,
  "code" | "arguments",
  null
>;

export type BlueprintRaiseExceptionStep = BlueprintStepBase<
  BlueprintStepType.RaiseException,
  "parameters" | "exception_text",
  null
>;

export type BlueprintEndBlueprintStep = BlueprintStepBase<
  BlueprintStepType.EndBlueprint,
  string,
  null
>;

export type BlueprintGetFirstInList = BlueprintStepBase<
  BlueprintStepType.GetFirstInList,
  "array" | "target_key_path" | "target_value" | "return_key",
  null
>;

export type BlueprintDataTransformStep = BlueprintStepBase<
  BlueprintStepType.DataTransform,
  "procedures",
  null
>;

export type BlueprintAddToArrayStep = BlueprintStepBase<
  BlueprintStepType.AddToArray,
  "array" | "values",
  null
>;

export type BlueprintAddToDictionaryStep = BlueprintStepBase<
  BlueprintStepType.AddToDictionary,
  "dictionary" | "items",
  null
>;

export type BlueprintSetVariableStep = BlueprintStepBase<
  BlueprintStepType.SetVariable,
  "key" | "value_type" | "value",
  null
>;

export type BlueprintUpdateLinkedAccountStep = BlueprintStepBase<
  BlueprintStepType.UpdateLinkedAccount,
  | "basic_auth_key"
  | "basic_auth_password"
  | "api_url_subdomain"
  | "scraper_auth_storage_state"
  | "oauth_client_secret"
  | "oauth_client_id"
  | "additional_auth_fields"
  | "service_account_fields"
  | "security_questions",
  null
>;

export type BlueprintAssertStep = BlueprintStepBase<
  BlueprintStepType.Assert,
  "comparator" | "parameters",
  null
>;

export type BlueprintRunBlueprintStep = BlueprintStepBase<
  BlueprintStepType.RunBlueprint,
  string,
  null
>;

export type BlueprintFileToUrlStep = BlueprintStepBase<
  BlueprintStepType.FileToUrl,
  "file" | "filename" | "use_authentication",
  string
>;

export type BlueprintFileUrlToFileStep = BlueprintStepBase<
  BlueprintStepType.FileUrlToFile,
  string | "url",
  any
>;

export type BlueprintConcurrentRequestStep = BlueprintStepBase<
  BlueprintStepType.ConcurrentRequestLoop,
  string,
  "page"
>;

export type BlueprintAddAvailableCustomFieldStep = BlueprintStepBase<
  BlueprintStepType.AddAvailableCustomField,
  | "custom_field_traversal_path"
  | "custom_field_mapping_type"
  | "object_mapping_key_traversal_path"
  | "object_mapping_value_traversal_path"
  | "object_mapping_key"
  | "name",
  null
>;

export type BlueprintMetaAccessParentBPParamNamesStep = BlueprintStepBase<
  BlueprintStepType.MetaAccessParentBPParamNames,
  "",
  null
>;

export type BlueprintMetaAddEnumChoiceToFieldStep = BlueprintStepBase<
  BlueprintStepType.MetaAddEnumChoiceToField,
  "field" | "enum_value" | "enum_description",
  null
>;

export type BlueprintMetaAddLinkedAccountParamStep = BlueprintStepBase<
  BlueprintStepType.MetaAddLinkedAccountParam,
  "field_key" | "field_type" | "field_description" | "conditional_key" | "conditional_value",
  null
>;

export type BlueprintGetLinkedAccountFields = BlueprintStepBase<
  BlueprintStepType.GetLinkedAccountFields,
  string,
  null
>;

export type BlueprintAddValidationProblemStep = BlueprintStepBase<
  BlueprintStepType.AddValidationProblem,
  "problem_type" | "severity" | "detail" | "field_source",
  null
>;

export type BlueprintAddLinkedAccountAdditionalAuthStep = BlueprintStepBase<
  BlueprintStepType.AddLinkedAccountAdditionalAuth,
  "field_name" | "field_value",
  null
>;

export type BlueprintGetModifiedSinceTimestampValue = BlueprintStepBase<
  BlueprintStepType.GetModifiedSinceTimestampValue,
  string,
  "modified_since"
>;

export type BlueprintCreateOAuth1SignatureStep = BlueprintStepBase<
  BlueprintStepType.CreateOAuth1Signature,
  "account_id" | "consumer_key" | "consumer_secret" | "token_id" | "token_secret",
  "timestamp" | "nonce" | "signature"
>;

export type BlueprintBatchAPIRequestStep = BlueprintStepBase<
  BlueprintStepType.BatchAPIRespnse,
  string,
  "true"
>;

export type BlueprintMergeObjectsStep = BlueprintStepBase<
  BlueprintStepType.MergeObjects,
  "base_object" | "overriding_object" | "keys",
  null
>;

export type BlueprintStep = BlueprintStepBase<BlueprintStepType | null, any, any>;

export const BlueprintLoopSteps = [
  BlueprintStepType.ArrayLoop,
  BlueprintStepType.DateRangeLoop,
  BlueprintStepType.CommonModelLoop,
  BlueprintStepType.WhileLoop,
  BlueprintStepType.TraverseTree,
  BlueprintStepType.APIRequestLoop,
];

export const BlueprintVariableSteps = [
  BlueprintStepType.DataTransform,
  BlueprintStepType.AddToArray,
  BlueprintStepType.AddToDictionary,
  BlueprintStepType.SetVariable,
];

// Blueprints and Blueprint Versions

export enum BlueprintVersionPublishState {
  Published = "PUBLISHED",
  Unpublished = "UNPUBLISHED",
  Draft = "DRAFT",
  Staged = "STAGED",
}

export const MapDisplayNameOfBlueprintVersionPublishState: Record<
  BlueprintVersionPublishState,
  string
> = {
  [BlueprintVersionPublishState.Published]: "Published",
  [BlueprintVersionPublishState.Unpublished]: "Unpublished",
  [BlueprintVersionPublishState.Draft]: "Draft",
  [BlueprintVersionPublishState.Staged]: "Staged",
};

export enum BlueprintStatus {
  Archived = "ARCHIVED",
  Active = "ACTIVE",
}

export interface PublishNotes {
  comment: string;
  asana_ticket: string;
}

export interface BlueprintPublishNote {
  id: string;
  ticket: string;
  description: string;
  created_at: string;
  created_date: string;
  blueprint_version_publish_state: BlueprintVersionPublishState;
  blueprint_version: BlueprintVersion;
}

export interface BlueprintVersion {
  id: string;
  created_at: string;
  publish_state: BlueprintVersionPublishState;
  comment: string;
  user?: User;
  mapped_common_models?: string[];
  name?: string;
  test_coverage?: BlueprintVersionTestCoverage;
}

export enum BlueprintOperationType {
  FETCH = "FETCH",
  CREATE = "CREATE",
  EDIT = "EDIT",
  UPSERT = "UPSERT",
  DELETE = "DELETE",
  VALIDATE = "VALIDATE",
  GET_AVAILABLE_CUSTOM_FIELDS = "GET_AVAILABLE_CUSTOM_FIELDS",
  FETCH_FILTER_OPTIONS = "FETCH_FILTER_OPTIONS",
  ENDPOINT_MODIFICATION = "ENDPOINT_MODIFICATION",
  META = "META",
  FUNCTIONAL = "FUNCTIONAL",
  WEBHOOK_SETUP = "WEBHOOK_SETUP",
  CUSTOM_OBJECT_CLASS_GENERATION = "CUSTOM_OBJECT_CLASS_GENERATION",
  PROXY = "PROXY",
  LIVE_REQUEST = "LIVE_REQUEST",
  ACCOUNT_DELETION = "ACCOUNT_DELETION",
  LIVE_SEARCH = "LIVE_SEARCH",
  LIVE_RESYNC = "LIVE_RESYNC",
}

export type BlueprintExtendedOperationType =
  | BlueprintOperationType
  | "FETCH_WEBHOOK"
  | "FETCH_REPORT_RECEIVED";

export const BlueprintExtendedOperationType = {
  ...BlueprintOperationType,
  FETCH_WEBHOOK: "FETCH_WEBHOOK" as const,
  FETCH_REPORT_RECEIVED: "FETCH_REPORT_RECEIVED" as const,
};

export const DISABLE_FILTER_BY_DATE_OPERATION_TYPES = [
  BlueprintOperationType.WEBHOOK_SETUP,
  BlueprintOperationType.FETCH_FILTER_OPTIONS,
  BlueprintOperationType.ACCOUNT_DELETION,
];

export enum BlueprintTriggerType {
  PERIODIC_TASK = "PERIODIC_TASK",
  API_REQUEST = "API_REQUEST",
  SCRAPER = "SCRAPER",
  INITIAL_LINK = "INITIAL_LINK",
  VALIDATE_AUTH = "VALIDATE_AUTH",
  FORCE_SYNC = "FORCE_SYNC",
  WEBHOOK = "WEBHOOK",
  DELETION_DETECTION = "DELETION_DETECTION",
  REPORT_RECEIVED = "REPORT_RECEIVED",
  WEB_CONNECTOR = "WEB_CONNECTOR",
}

export interface StaleParameterMappingInfo {
  key_path: string[];
  parameter_value_path: string[];
}

export interface StaleParametersInfo {
  [scopedStepId: string]: StaleParameterMappingInfo[];
}

export interface BlueprintVersionStaleParametersInfo {
  id: string;
  blueprint_version: string;
  stale_parameters_info: StaleParametersInfo;
  status: string;
  modified_at: string;
}

export interface ReportFile {
  name: string | null;
  filename: string;
  linked_account_id: string;
  report_template_id: string;
  id: string;
}
export interface Blueprint {
  scraper?: ScraperVersion;
  id: string;
  integration: Integration;
  name: string;
  human_name: string | null;
  category: string | null;
  operation_type: BlueprintOperationType;
  steps: Array<BlueprintStep>;
  owner: User;
  version: BlueprintVersion;
  global_var_json_schema?: JSONObjectSchema;
  parameter_schema?: JSONObjectSchema;
  qbxml_query_request_format?: string;
  return_schema?: JSONObjectSchema;
  updated_parameter_schema_for_auto_update?: JSONObjectSchema;
  written_common_model?: string;
  mapped_models: Array<string>;
  endpoints: Array<{ method: string; path: string }>;
  step_notes: Array<StepNote>;
  status: string;
  trigger_type: BlueprintTriggerType | undefined;
  webhook_receiver_event_type_id: string | undefined;
  report_files: Array<ReportFile> | null | undefined;
  report_template_id: string | null;
  is_long_running: boolean;
}

// BPR

export const LEFT_ALIGN_COOKIE_KEY = "leftAlignSteps";

export enum BlueprintRunnerStepLogEntryType {
  Error = "ERROR",
  Warning = "WARNING",
  Info = "INFO",
}

export type BlueprintRunnerStepLogEntries = {
  time: string;
  message: string;
  step_id: null | string;
  entry_type: BlueprintRunnerStepLogEntryType;
};

export type BlueprintRunnerStepLogs = Array<BlueprintRunnerStepLogEntries>;

export type BlueprintIndividualParameterCoverage = {
  nested_parameter_coverage: { [parameterId: string]: BlueprintIndividualParameterCoverage };
  is_parameter_used_in_blueprint: boolean;
  was_non_null_value_used: boolean;
};

export type BlueprintIndividualStepCoverage = {
  functional_bp_coverage: BlueprintCoverageDetails;
  parameter_value_coverage: { [parameterId: string]: BlueprintIndividualParameterCoverage };
  was_step_called: boolean;
};

export type BlueprintCoverageDetails = {
  input_parameter_coverage: { [parameterId: string]: BlueprintIndividualParameterCoverage };
  step_coverage: { [stepId: string]: BlueprintIndividualStepCoverage };
};

export type BlueprintRunnerAutogeneratedMappingTestInformation = {
  mapping_test_id: string;
  mapping_test_suite_id: null | string;
  endpoint_test_collection_id: null | string;
  exception_message: null | string;
  was_successful: boolean;
  mapping_misses: null | Object;
  result: null | MappingTestExecutionResult;
};

export enum StepCoverageLevel {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  MISSING = "MISSING",
}

export type StepCoverageDetails = {
  coverageLevel: StepCoverageLevel | null;
  coverageMessage: string | null;
};

export enum BPRStepIOLogTypeEnum {
  DEFAULT = "DEFAULT",
  API_REQUEST = "API_REQUEST",
  LOOP_ITERATION = "LOOP_ITERATION",
}

export enum BPRStepIOLogVariableTypeEnum {
  DICTIONARY = "DICTIONARY",
  ARRAY = "ARRAY",
  CONSTANT = "CONSTANT",
}

export enum BPRStepIOLogVariableEventEnum {
  SET = "SET",
  ADDED = "ADDED",
  DELETED = "DELETED",
  UPDATED = "UPDATED",
}

export const mapDisplayableStepIOLogType: Record<BPRStepIOLogTypeEnum, string> = {
  [BPRStepIOLogTypeEnum.DEFAULT]: "",
  [BPRStepIOLogTypeEnum.API_REQUEST]: "API call",
  [BPRStepIOLogTypeEnum.LOOP_ITERATION]: "Loop iteration",
};

export type BPRStepIOVariableDiffLog = {
  variable_type: BPRStepIOLogVariableTypeEnum;
  variable_key: string;
  change_event: BPRStepIOLogVariableEventEnum;
  change_data: any;
};

export type BPRStepIOLog = {
  step_id: string;
  inputs: null | { [key: string]: any };
  outputs: null | { [key: string]: any };
  variable_diff_logs: null | BPRStepIOVariableDiffLog[];
  step_io_logs: null | BPRStepIOLog[]; // TODO: Implement once backend logic for functional bp step IO is built
  log_type: BPRStepIOLogTypeEnum;
};

export type BlueprintRunnerDebuggingInfo = {
  step_io_logging_failed: boolean;
  step_io_logs: BPRStepIOLog[];
};

export enum WebConnectorSessionStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  ABORTED = "ABORTED",
}

export type WebConnectorSessionTestRunRequests = {
  request?: string;
  hresult?: string;
  message?: string;
  common_model_object_id?: string;
  blueprint_execution_id?: string;
};

export type WebConnectorSession = {
  id: string;
  status?: WebConnectorSessionStatus;
  test_run_requests?: WebConnectorSessionTestRunRequests[];
};

export type BlueprintRunnerExitData = {
  step_logs: BlueprintRunnerStepLogs;
  coverage: BlueprintCoverageDetails;
  model_logs: {
    [commonModelID: string]: { [fieldName: string]: any }[];
  };
  final_model_changes: {
    [commonModelID: string]: { [uniqueID: string]: { [fieldName: string]: any } };
  };
  final_model_updates: {
    [commonModelID: string]: { [uniqueID: string]: { [fieldName: string]: any } };
  };
  blueprint_meta: {
    name: string;
    id: string;
    exception_message: string;
    blueprint_run_exit_code: number;
  };
  additional_data: {
    autogenerated_mapping_test_information?: BlueprintRunnerAutogeneratedMappingTestInformation;
  };
  debugging_info?: BlueprintRunnerDebuggingInfo;
};

export interface BlueprintRunnerExecutionResponse {
  linked_account: string;
  start_time: string;
  end_time: string;
  exit_code: 200 | 400;
  exit_data?: BlueprintRunnerExitData;
  blueprint_version: null | string;
  exception_message?: string;
}

// Ghost Steps

export enum AddStepRelation {
  CHILD = "CHILD",
  EMPTY_BLUEPRINT = "NEW_BLUEPRINT",
  SIBLING_BEFORE = "SIBLING_BEFORE",
  SIBLING_AFTER = "SIBLING_AFTER",
}

export interface BlueprintGhostStepType {
  newStepRelation: AddStepRelation;
  relatedStepID: string;
  pathKey?: string;
  // for type discrimination
  template: "ghost";
  id: string;
  paths?: never;
}

export type BlueprintStepOrGhostStep = BlueprintStep | BlueprintGhostStepType;
export type BlueprintStepOrGhostStepOrTriggerOrScraper =
  | BlueprintStep
  | BlueprintGhostStepType
  | BlueprintTrigger
  | ScraperVersion;
export type BlueprintStepsWithGhostSteps = Array<BlueprintStepOrGhostStep>;

// Triggers

export enum BlueprintTriggerIntervalUnit {
  DAYS = "days",
  HOURS = "hours",
  MINUTES = "minutes",
  SECONDS = "seconds",
}

export enum BlueprintTriggerSpeed {
  BLUEPRINT_SPEED_SLOW = "SLOW",
  BLUEPRINT_SPEED_INTERMEDIATE = "INTERMEDIATE",
  BLUEPRINT_SPEED_FAST = "FAST",
}

export interface BlueprintTriggerSchedule {
  speed: BlueprintTriggerSpeed;
  schedule_interval_value: number | null;
  schedule_interval_unit: BlueprintTriggerIntervalUnit;
}

export type BlueprintTrigger = {
  id?: never;
  slow_trigger_schedule: BlueprintTriggerSchedule;
  intermediate_trigger_schedule: BlueprintTriggerSchedule;
  fast_trigger_schedule: BlueprintTriggerSchedule;
  template?: never;
};

export interface BlueprintWithTrigger extends Blueprint {
  schedule: BlueprintTrigger;
}

// Data Transform

export enum BlueprintProcedureCategoryType {
  MODIFY = "MODIFY",
}

export enum BlueprintProcedureType {
  ADD_KEY_VALUE_PAIR_TO_OBJECT = "ADD_KEY_VALUE_PAIR_TO_OBJECT",
  INSERT_INTO_ARRAY = "INSERT_INTO_ARRAY",
}

interface ProcedureBase<
  T extends BlueprintProcedureCategoryType,
  N extends BlueprintProcedureType,
  A extends any,
  P extends JSONObjectSchema,
  R extends JSONObjectSchema
> {
  id: string;
  procedure_category_type: T;
  procedure_type: N;
  parameter_values: { [key: string]: null | BlueprintParameterValue };
  arguments?: A;
  parameter_schema: P;
  return_schema?: R;
}

export type BlueprintProcedure = ProcedureBase<
  BlueprintProcedureCategoryType,
  BlueprintProcedureType,
  any,
  JSONObjectSchema,
  JSONObjectSchema
>;

export type BlueprintLogicalOperation = {
  id: string;
  val1: BlueprintParameterValue | null;
  val2: BlueprintParameterValue | null;
  comparator: BlueprintParameterValue | null;
};

// DIAGNOSTICS

export type BlueprintExecutionStatusData = Array<{
  response_code: string;
  status_count: number;
}>;

export type BlueprintExecutionEnumData = {
  [commonModel: string]: {
    [commonModelField: string]: {
      [commonModelFieldEnumChoice: string]: number;
    };
  };
};

export type BlueprintExecutionDecisionData = {
  [stepID: string]: {
    [conditionName: string]: number;
  };
};

export type BlueprintExecutionDetails = {
  last_start_time: string;
  run_length: number;
  total_requests: number;
  requests_by_status: BlueprintExecutionStatusData;
  model_writes: number;
  model_reads: number;
  redundant_model_writes: number;
  last_run_enums: BlueprintExecutionEnumData;
  last_run_decisions: BlueprintExecutionDecisionData;
};

export type BlueprintRunsData = Array<{ is_test_run: boolean; count: number }>;

// ASSERTS

export enum BlueprintLogicalOperator {
  ANY = "ANY",
  ALL = "ALL",
  NONE = "NONE",
}

export enum BlueprintComparator {
  EQUAL = "EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
  IS_NULL_OR_EMPTY = "IS_NULL_OR_EMPTY",
  CONTAINS = "CONTAINS",
  NOT_CONTAINS = "NOT_CONTAINS",
}

type BlueprintAssertBase<T extends BlueprintComparator, P extends JSONSchemaValue> = {
  custom_function_type: T;
  parameter_type: P;
};

export type BlueprintAssertEquality = BlueprintAssertBase<
  BlueprintComparator.EQUAL,
  {
    type: "object";
    properties: {
      value1: { type: "any"; required: false };
      value2: { type: "any"; required: false };
    };
  }
>;

export type BlueprintAssertInequality = BlueprintAssertBase<
  BlueprintComparator.NOT_EQUAL,
  {
    type: "object";
    properties: {
      value1: { type: "any"; required: false };
      value2: { type: "any"; required: false };
    };
  }
>;

export type BlueprintAssert = BlueprintAssertEquality | BlueprintAssertInequality;

export enum CustomFieldMappingType {
  SINGLE_VALUE = "SINGLE_VALUE",
  OBJECT = "OBJECT",
}

export type ValidationProblem = {
  title: string;
  detail: string;
  problem_type: string;
  status_code: number;
};

export type BlueprintTestRunErrorResponse = {
  errors: Array<ValidationProblem>;
  warnings: Array<ValidationProblem>;
};

export type BlueprintTestPayload = {
  id: string;
  name: string;
  text: string;
  linked_account_id: string;
  user_name: string;
  common_model_object_id: string;
  created_at: string;
  modified_at: string;
};

export enum AsyncTaskExecutionStatus {
  QUEUED = "QUEUED",
  PENDING = "PENDING",
  STARTED = "STARTED",
  ABORTED = "ABORTED",
  ABORTED_ON_DEPLOY = "ABORTED_ON_DEPLOY",
  ABORTED_ALREADY_RUNNING = "ABORTED_ALREADY_RUNNING",
  ABORTED_UNPUBLISHED = "ABORTED_UNPUBLISHED",
  ABORTED_INCORRECT_AUTH = "ABORTED_INCORRECT_AUTH",
  ABORTED_FAILED_SCRAPER_AUTH = "ABORTED_FAILED_SCRAPER_AUTH",
  ABORTED_DISABLED_COMMON_MODEL = "ABORTED_DISABLED_COMMON_MODEL",
  ABORTED_DISABLED_WRONG_CATEGORY = "ABORTED_DISABLED_WRONG_CATEGORY",
  ABORTED_DISABLED_FEATURE = "ABORTED_DISABLED_FEATURE",
  ABORTED_WITH_RETRY = "ABORTED_WITH_RETRY",
  COMPLETED = "COMPLETED",
  COMPLETED_ON_RETRY = "COMPLETED_ON_RETRY",
  ABORTED_ORPHAN_BLUEPRINT = "ORPHAN_BLUEPRINT",
  ABORTED_DELETION_DUPLICATE = "DELETION_DUPLICATE",
  ABORTED_MANUALLY_KILLED_BY_ADMIN = "MANUALLY_KILLED_BY_ADMIN",
  ABORTED_LOST = "ABORTED_LOST",
}

export const ASYNC_TASK_EXECUTION_QUEUED_STATUSES = [
  AsyncTaskExecutionStatus.QUEUED,
  AsyncTaskExecutionStatus.PENDING,
];

export const ASYNC_TASK_EXECUTION_RUNNING_STATUSES = [
  AsyncTaskExecutionStatus.STARTED,
  AsyncTaskExecutionStatus.ABORTED_WITH_RETRY,
];

export interface BlueprintExecutionPreview extends BlueprintRunnerExecutionResponse {}

export interface AsyncTaskExecutionPreview {
  id: string;
  linked_account_id: string;
  status: AsyncTaskExecutionStatus;
  trigger_type: string;
  created_at: string;
  modified_at: string;
  task_id?: string;
  task_name?: string;
  celery_worker?: string;
  queue_name?: string;
  blueprint_id?: string;
  blueprint_execution?: BlueprintExecutionPreview;
  common_model_instance_id?: string;
  common_model_name?: string;
  processed_at?: string;
}

export interface AsyncTaskExecution extends AsyncTaskExecutionPreview {}

export interface BlueprintReadme extends Readme {}

export type BlueprintSelectedStepLog = {
  /** Tells canvas which step to auto-scroll to **/
  step_id: string;
  /** Tells left-panel which step log to auto-scroll to & highlight **/
  index_in_raw_logs: number;
};

/* ---- BEGIN - STEP LOG TREE IN CANVAS ---- */
export type BlueprintCanvasBaseStepLogsTree = {
  /** Dictionary of step ID to log iteration info **/
  [key: string]: StepLogsTreeStepLog;
};

export type StepLogsTreeStepLog = {
  /** The primary step log, particularly for non-loop steps */
  primary_log: StepLogsTreeStepLogPrimaryLog;
  /** Logs for API calls (ie.: Paginated API calls) */
  api_requests?: StepLogsTreeStepLogAPICall[];
  /** Logs for step that are iterations (ie.: Loop iterations) **/
  loop_iterations: StepLogsTreeStepLogLoopIteration[];
};

export type StepLogsTreeStepLogPrimaryLog = {
  index_in_raw_logs: number;
};

export type StepLogsTreeStepLogAPICall = {
  index_in_raw_logs: number;
  loop_iterations: StepLogsTreeStepLogLoopIteration[];
};

export type StepLogsTreeStepLogLoopIteration = {
  index_in_raw_logs: number;
  children_loop_steps: BlueprintCanvasBaseStepLogsTree;
};
/* ---- END - STEP LOG TREE IN CANVAS ---- */

/* ---- BEGIN - STEP LOG LIST IN CANVAS ---- */
export type BlueprintCanvasBaseStepLogList = BlueprintCanvasBaseStepLogListItem[];

export type BlueprintCanvasBaseStepLogListItem = {
  step_id: string;
  step_io_log: BPRStepIOLog;
  iteration_info: StepLogIterationInfo;
  selected_log_info: StepLogSelectedLogInfo;
};

export type StepLogIterationInfo = {
  index_in_loop_iterations: number | undefined;
  total_iterations: number | undefined;
  index_in_api_requests: number | undefined;
  total_api_requests: number | undefined;
  parent_iteration_info: ParentStepLogIterationInfo[];
};

export type ParentStepLogIterationInfo = {
  step_id: string;
  index_in_loop_iterations: number | undefined;
  index_in_api_requests: number | undefined;
};

export type StepLogSelectedLogInfo = {
  index_of_previous_log: number | undefined;
  index_of_parent_log: number | undefined;
  index_of_last_children_step_log: number | undefined;
  // Used for loop initialization, since loop initialization is different from loop iterations
  index_of_loop_initialization_log: number | undefined;
  index_of_loop_latest_iteration_log: number | undefined;
};

/* ---- END - STEP LOG LIST IN CANVAS ---- */

/* ---- BEGIN - TYPES FOR SELECTED STEP IO LOG TO SHOW IN CANVAS ---- */
export type BlueprintCanvasViewableStepLogsMap = {
  /** Dictionary of step ID to log info **/
  [key: string]: BlueprintCanvasViewableStepLog;
};

export interface BlueprintCanvasViewableStepLogBase {
  step_io_log: BPRStepIOLog;
  index_in_raw_logs: number;
  iteration_info: StepLogIterationInfo;
}

export interface BlueprintCanvasViewableStepLog extends BlueprintCanvasViewableStepLogBase {
  loop_initialization_viewable_step_log: BlueprintCanvasViewableStepLogBase | undefined;
}

/* ---- END - TYPES FOR SELECTED STEP IO LOG TO SHOW IN CANVAS ---- */

/** Enum for viewing logs in Blueprint Editor */
export enum StepLoggingViewEnum {
  DEFAULT = "Step logs",
  STEP_COVERAGE = "Step logs, with coverage",
  STEP_IO = "Step I/O",
  STEP_IO_IN_CANVAS = "Step I/O in canvas",
}

// To support tracing steps in BPE
export interface StepParameterTracingTarget {
  step_id: string;
  parameter_value_key: string;
}

// TOP PANEL TYPES

export type IntegrationEditorTopControlPanelActionProps = {
  Icon: LucideIcon;
  hoverText?: string;
  isDisabled?: boolean;
  isHidden?: boolean;
  onClick: () => void;
  text: IntegrationEditorAction;
  disabledTooltipText?: string;
};

export enum IntegrationEditorAction {
  COPY_BLUEPRINT_JSON = "Copy Blueprint JSON",
  UNDO = "Undo",
  REDO = "Redo",
  VERSION_HISTORY = "Version History",

  SAVE_DRAFT = "Save Draft",
  STAGE = "Stage",
  UNSTAGE = "Unstage",
  PUBLISH = "Publish",
  UNPUBLISH = "Unpublish",
  ARCHIVE = "Archive",
  UNARCHIVE = "Unarchive",
  DELETE = "Delete",
}

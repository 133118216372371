import { Card, Text } from "@merge-api/merge-javascript-shared";

interface AlertDashboardDrawerExecutionDetailsProps {
  totalSuccessful: number;
  totalFailed: number;
  totalRestarted: number;
}

const AlertDashboardDrawerExecutionDetails = ({
  totalSuccessful,
  totalFailed,
  totalRestarted,
}: AlertDashboardDrawerExecutionDetailsProps) => {
  const totalCountedExecutions = totalSuccessful + totalFailed + totalRestarted;

  return (
    <div className="mb-6">
      <Text variant="h4" className="mb-5">
        Blueprint Executions
      </Text>
      <Card className="p-4">
        <div className="flex justify-between">
          <Text variant="h6">Total number of blueprint executions</Text>
          <Text variant="h6">{totalCountedExecutions}</Text>
        </div>
        <div className="mt-3 bg-gray-20 h-[0.5px]" />
        <div className="flex justify-between mt-4">
          <Text variant="md">Successful blueprint executions</Text>
          <Text variant="md">{totalSuccessful}</Text>
        </div>
        <div className="flex justify-between mt-3">
          <Text variant="md">Failed blueprint executions</Text>
          <Text variant="md">{totalFailed}</Text>
        </div>
        <div className="flex justify-between mt-3">
          <Text variant="md">Restarted blueprint executions</Text>
          <Text variant="md">{totalRestarted}</Text>
        </div>
      </Card>
    </div>
  );
};

export default AlertDashboardDrawerExecutionDetails;

import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { AbstractCondition } from "../../../../integration-builder/selective-sync-filters/types";
import { Plus } from "lucide-react";
import BlueprintLeftPanelSelectiveSyncFilterCard from "./BlueprintLeftPanelSelectiveSyncFilterCard";
import useFetchUserFacingFilterTypeOptionsForBlueprintVersion from "./hooks/useFetchUserFacingFilterTypeOptionsForBlueprintVersion";
import {
  ADD_CONDITION,
  AbstractConditionAction,
} from "../../../reducers/AbstractConditionsForSelectiveSyncReducer";

interface Props {
  integrationID: string;
  blueprintVersionID: string;
  abstractConditionsForSelectiveSync: AbstractCondition[];
  dispatchAbstractConditionsForSelectiveSync: React.Dispatch<AbstractConditionAction>;
}

/**
 * Renders the Selective Sync filter settings in Blueprint Editor left panel console
 */
const BlueprintLeftPanelSelectiveSync = ({
  integrationID,
  blueprintVersionID,
  abstractConditionsForSelectiveSync,
  dispatchAbstractConditionsForSelectiveSync,
}: Props) => {
  const { filterTypeOptions } = useFetchUserFacingFilterTypeOptionsForBlueprintVersion({
    integrationID,
    blueprintVersionID,
  });

  return (
    <div className="flex flex-col space-y-2 w-full">
      <div className="flex flex-col">
        <Text variant="h6">Selective sync filters</Text>
        <Text variant="sm" className="text-gray-70">
          Specify the selective sync conditions to test against this Blueprint. This will apply
          staged Selective Sync filters and API Endpoint Parameters. If staged does not exist,
          published filters will be used.
        </Text>
        <Text variant="sm" className="text-gray-70">
          <b>Note: Remember to stage your S2 filter changes to properly test them here!</b>
        </Text>
      </div>
      {(abstractConditionsForSelectiveSync ?? []).map((_, index) => {
        return (
          <BlueprintLeftPanelSelectiveSyncFilterCard
            index={index}
            abstractConditionsForSelectiveSync={abstractConditionsForSelectiveSync}
            dispatchAbstractConditionsForSelectiveSync={dispatchAbstractConditionsForSelectiveSync}
            filterTypeOptions={filterTypeOptions}
          />
        );
      })}
      <Button
        size="sm"
        variant={ButtonVariant.TertiaryWhite}
        leftIcon={<Plus size={12} />}
        onClick={() =>
          dispatchAbstractConditionsForSelectiveSync({
            type: ADD_CONDITION,
            payload: { integrationID: integrationID },
          })
        }
      >
        Filter
      </Button>
    </div>
  );
};

export default BlueprintLeftPanelSelectiveSync;

import { transformRateLimitConfigurationDataForDiffModal } from "../../../integration-builder/rate-limits/utils/helpers";
import {
  LinkChoiceStepOption,
  LinkingFlowStepPathIntegrationBuilder,
  RateLimitConfigurationIntegrationBuilder,
} from "../../../integration-builder/utils/Entities";
import { convertToDisplayableDiffState } from "../../../shared/diff-view/utils/helpers";
import { ChangelogEvent, ChangelogEventType, ChangelogModelType, NO_CANARY_KEY } from "./types";

export function handleModelTypeFieldConversionForDiffModal(
  modelType: ChangelogModelType,
  fields: Record<any, any>
): Record<any, any> {
  switch (modelType) {
    case ChangelogModelType.MERGE_LINK_STEPS:
      return convertToDisplayableDiffState(
        fields as LinkingFlowStepPathIntegrationBuilder,
        [],
        undefined,
        undefined
      );
    case ChangelogModelType.LINK_CHOICE_STEP_OPTION:
      return convertToDisplayableDiffState(
        undefined,
        [fields] as LinkChoiceStepOption[],
        undefined,
        undefined
      );
    case ChangelogModelType.RATE_LIMIT_CONFIGURATION:
      return transformRateLimitConfigurationDataForDiffModal(
        fields as RateLimitConfigurationIntegrationBuilder
      );
    case ChangelogModelType.API_ENDPOINT:
      return { api_endpoint: fields, rate_limit_configurations: [] };

    default:
      return fields;
  }
}

export function groupChangelogEventsByCanaryId(
  events: ChangelogEvent[]
): (ChangelogEvent | ChangelogEvent[])[] {
  // Create a map to store groups of events by canary test id
  const groupMap = new Map<string, ChangelogEvent[]>();

  // Iterate through events in order
  for (const event of events) {
    const canaryId = event.canary_test_id || NO_CANARY_KEY;

    if (!groupMap.has(canaryId)) {
      groupMap.set(canaryId, []);
    }

    groupMap.get(canaryId)!.push(event);
  }

  // Second pass: create array of individual and grouped events in original order
  const orderedEvents = events.reduce((acc: (ChangelogEvent | ChangelogEvent[])[], event) => {
    if (!event.canary_test_id) {
      acc.push(event);
    } else {
      // Only include the group once, when we encounter its first event
      const groupEvents = groupMap.get(event.canary_test_id)!;
      if (
        event.id === groupEvents[0].id &&
        event.event_type !== ChangelogEventType.IN_CANARY_TEST
      ) {
        acc.push(groupEvents);
      }
    }
    return acc;
  }, []);

  return orderedEvents;
}

import { Card } from "react-bootstrap";
import GradientIcon from "../../../shared/icons/GradientIcon";
import { Text } from "@merge-api/merge-javascript-shared";
import { Lock } from "lucide-react";

const CanaryTestingStagingLockedCard = () => {
  return (
    <Card className="p-5">
      <div className="py-8 px-6 flex flex-col justify-center items-center">
        <GradientIcon Icon={Lock} size={32} endColor="#2DB9EE" startColor="#0042E5" />
        <Text variant="h5" className="pt-4 text-gray-70">
          Staging locked
        </Text>
        <Text variant="title-sm" className="pt-1 text-gray-70">
          Publish in progress
        </Text>
      </div>
    </Card>
  );
};

export default CanaryTestingStagingLockedCard;
